import { useEffect, useMemo, useState } from 'react';
import TreeView from 'react-accessible-treeview';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Filter } from 'react-bootstrap-icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useGetApiCategoryTreeQuery } from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import AccordionItem from '../accordion/AccordionItem';
import CustomAccordion from '../accordion/CustomAccordion';
import { getTreeCategories } from '../content-tree/functions';
import { ContentTreeItems, ICategoryTreeItem } from '../content-tree/types';
import Loader from '../loader/Loader';
import SearchFilterCategoryTreeItem from './SearchFilterCategoryTreeItem';
import {
  selectSearchIncludeVersions,
  selectSearchOption,
  selectSelectedCategoryIdsForSearch,
  setSearchIncludeVersions,
  setSearchOption,
  setSelectedCategoryIdsForSearch,
} from '../../redux/store/content/slice';
import { SearchOption } from '../../redux/store/content/types';
import OnlineHelpButton from '../buttons/OnlineHelpButton';

function SearchFilter(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedCategoryIds = useAppSelector(
    selectSelectedCategoryIdsForSearch,
  );
  const searchOption = useAppSelector(selectSearchOption);
  const includeVersions = useAppSelector(selectSearchIncludeVersions);
  const [accordionActiveKey, setAccordionActiveKey] = useState('');
  const {
    data: categoryData,
    isError,
    isFetching,
    error,
  } = useGetApiCategoryTreeQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetContentTreeError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const treeData = useMemo<ContentTreeItems>(() => {
    if (categoryData?.resultObject) {
      return getTreeCategories(categoryData.resultObject);
    }
    return [];
  }, [categoryData]);

  return (
    <fieldset>
      <div className='d-flex'>
        <legend className='fs-5 flex-grow'>
          <Filter className='fs-3' aria-hidden />
          {translation('filterSettings')}
        </legend>
        <OnlineHelpButton helpId='help_4' />
      </div>

      <Container fluid className='search-filter-container'>
        <Row>
          <Col xs={12} md={6} className='pt-3 d-flex'>
            <Form.Check
              id='AllWordsRadio'
              className='border-3 border-end border-dark me-2 pe-2'
              value='AllWords'
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(setSearchOption(SearchOption.AllWords));
                }
              }}
              checked={searchOption === SearchOption.AllWords}
              type='radio'
              name='SearchOption'
              label={translation('allWords')}
            />
            <Form.Check
              id='ExactExpressionRadio'
              value='ExactExpression'
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(setSearchOption(SearchOption.ExactExpression));
                }
              }}
              checked={searchOption === SearchOption.ExactExpression}
              type='radio'
              name='SearchOption'
              label={translation('exactExpression')}
            />
          </Col>
          <Col xs={12} md={6} className='pt-3'>
            <Form.Check
              id='IncludeVersionsRadio'
              onChange={(e) => {
                dispatch(setSearchIncludeVersions(e.target.checked));
              }}
              checked={includeVersions}
              type='checkbox'
              label={translation('includeVersions')}
            />
          </Col>
        </Row>
        <Row className='mt-4 search-filter-container'>
          <CustomAccordion activeKey={accordionActiveKey}>
            <AccordionItem
              actions={
                selectedCategoryIds.length > 0
                  ? [
                      {
                        name: translation('clearFilter'),
                        iconClassName: 'icon-trash',
                        iconColorClass: 'text-primary',
                        onClick: () => {
                          dispatch(setSelectedCategoryIdsForSearch([]));
                        },
                      },
                    ]
                  : undefined
              }
              key='SelectedCategories'
              title={
                <>
                  <span>{translation('limitSearchToCategories')}</span>
                  {selectedCategoryIds.length > 0 && (
                    <span className='ms-2 fw-bold'>{`(${translation(
                      'active',
                    )})`}</span>
                  )}
                </>
              }
              activeKey={accordionActiveKey}
              setActiveKey={setAccordionActiveKey}
              eventKey='SelectedCategories'>
              {isFetching && <Loader />}
              {!isFetching && categoryData && (
                <div className='ps-1'>
                  <TreeView
                    id='SearchTree'
                    selectedIds={selectedCategoryIds}
                    multiSelect
                    data={treeData}
                    togglableSelect
                    propagateSelect
                    propagateSelectUpwards
                    onSelect={(e) => {
                      dispatch(
                        setSelectedCategoryIdsForSearch(
                          Array.from(e.treeState.selectedIds as Set<string>),
                        ),
                      );
                    }}
                    nodeRenderer={({
                      element,
                      getNodeProps,
                      level,
                      isBranch,
                      isExpanded,
                      handleSelect,
                      handleExpand,
                      isSelected,
                    }) =>
                      SearchFilterCategoryTreeItem({
                        level,
                        isBranch,
                        isExpanded,
                        getNodeProps,
                        handleExpand,
                        handleSelect,
                        isSelected,
                        element: element as ICategoryTreeItem,
                      })
                    }
                  />
                </div>
              )}
            </AccordionItem>
          </CustomAccordion>
        </Row>
      </Container>
    </fieldset>
  );
}

export default SearchFilter;
