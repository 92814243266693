import { ArticleReference, Note } from '../../../redux/store/api/api';
import { CALCULATION_CONTENT_CONTAINER_ID } from '../../../shared/constants';
import convertToRange from './convertToRange';
import surroundTextNodesInRange from './surroundTextNodesInRange';

interface IContentProps {
  content: string;
  elementsNotFound: string[];
}
interface IMakeContentProps {
  content: string;
  elements: Note[] | ArticleReference[];
  tagName: 'mark' | 'a';
  withoutIds?: boolean;
  isSuggestionContent?: boolean;
}

const makeContentWithMarks = ({
  content,
  elements,
  tagName,
  withoutIds,
  isSuggestionContent = false,
}: IMakeContentProps): IContentProps => {
  if (elements.length === 0) {
    return { content, elementsNotFound: [] };
  }

  // Parse content string to html document
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(
    `<div id='${CALCULATION_CONTENT_CONTAINER_ID}'>${content}</div>`,
    'text/html',
  );

  // Convert objects from database to range objects
  let elementsNotFound: string[] = [];

  elements.forEach((element) => {
    const note = tagName === 'mark' ? (element as Note) : null;
    const reference = tagName === 'a' ? (element as ArticleReference) : null;
    const mark = note?.mark;
    const sourceContentArea = reference?.sourceContentArea;
    const el = mark || sourceContentArea;
    let range;

    if (el) {
      range = convertToRange(htmlDoc, CALCULATION_CONTENT_CONTAINER_ID, el);
    }

    if (range === undefined) {
      elementsNotFound = [...elementsNotFound, el?.id || ''];
    } else {
      // Enclose every text node in range with a <mark>/<a>-tag
      surroundTextNodesInRange({
        range,
        element,
        tagName,
        withoutIds,
        isSuggestionContent,
      });
    }
  });

  // Parse altered html content back to string
  const serializer = new XMLSerializer();
  const htmlFragment = new DocumentFragment();
  const rootEl = htmlDoc.getElementById(CALCULATION_CONTENT_CONTAINER_ID);
  let newContent = '';

  if (rootEl) {
    htmlFragment.append(rootEl);
  }

  if (htmlFragment) {
    newContent = serializer
      .serializeToString(htmlFragment)
      .replaceAll(/(?:^<div(.*?)>)|(?:<\/div>$)/g, '');
  }

  return { content: newContent, elementsNotFound };
};
export default makeContentWithMarks;
