import { ReferenceBehaviour } from '../../shared/enums';

export type ReferenceBehaviourString = keyof typeof ReferenceBehaviour;

export enum ReferenceType {
  ExternalReference = 'ExternalReference',
  InternalReferenceMultiple = 'InternalReferenceMultiple',
  InternalReferenceInArticle = 'InternalReferenceInArticle',
  InternalReferenceWithTextPassage = 'InternalReferenceWithTextPassage',
}
