import { UserGroupTree } from '../../redux/store/api/api';
import { IUserGroupTreeItem, IUserTreeItem, UserGroupTreeItems } from './types';

export function getUserGroupTreeItems(
  treeData: UserGroupTree,
): UserGroupTreeItems {
  let treeItems: IUserGroupTreeItem[] = [];

  treeData.userGroups?.forEach((userGroup) => {
    treeItems.push({
      id: userGroup.id,
      parent: userGroup.parentId || '0',
      name: userGroup.name,
      userGroupMemberCount: userGroup.userGroupMemberCount,
      userGroupTreeMemberCount: userGroup.userGroupTreeMemberCount,
      userGroupMemberMaximum: userGroup.userGroupMemberMaximum,
      userGroupTreeMemberMaximum: userGroup.userGroupTreeMemberMaximum,
      isRecursivePermitted: userGroup.isRecursivePermitted,
      permittedActions: userGroup.permittedActions,
      systemRoleAssigned: userGroup.systemRolesAssigned,
      categoryPermissionsAssigned: userGroup.categoryPermissionsAssigned,
      userGroupPermissionsAssigned: userGroup.userGroupPermissionsAssigned,
      children: userGroup.userGroupIds || [],
      userIds: userGroup.userIds,
      userGroupIds: userGroup.userGroupIds,
    } as IUserGroupTreeItem);
  });

  const rootUserGroupIds: string[] = treeItems
    .filter(
      (i) => i.parent === '0' || !treeItems.find((t) => t.id === i.parent),
    )
    .map((i) => i.id);

  // parent element for all tree items on start of array
  treeItems = (
    [
      {
        id: '0',
        parent: null,
        name: 'name',
        children: rootUserGroupIds,
      },
    ] as IUserGroupTreeItem[]
  ).concat(treeItems);

  return treeItems;
}

export function getCompleteUserGroupTreeData(
  treeData: UserGroupTree,
): UserGroupTreeItems {
  let treeItems: UserGroupTreeItems = [];

  treeData.userGroups?.forEach((userGroup) => {
    treeItems.push({
      id: userGroup.id,
      parent: userGroup.parentId || '0',
      name: userGroup.name,
      userGroupMemberCount: userGroup.userGroupMemberCount,
      userGroupTreeMemberCount: userGroup.userGroupTreeMemberCount,
      userGroupMemberMaximum: userGroup.userGroupMemberMaximum,
      userGroupTreeMemberMaximum: userGroup.userGroupTreeMemberMaximum,
      isRecursivePermitted: userGroup.isRecursivePermitted,
      permittedActions: userGroup.permittedActions,
      systemRoleAssigned: userGroup.systemRolesAssigned,
      categoryPermissionsAssigned: userGroup.categoryPermissionsAssigned,
      userGroupPermissionsAssigned: userGroup.userGroupPermissionsAssigned,
      children: (userGroup.userIds || []).concat(userGroup.userGroupIds || []),
      userIds: userGroup.userIds,
      userGroupIds: userGroup.userGroupIds,
    } as IUserGroupTreeItem);
  });

  treeData.users?.forEach((user) =>
    treeItems.push({
      id: user.id,
      parent: user.userGroupId,
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      emailConfirmed: user.emailConfirmed,
      emailSentDate: user.emailSentDate,
      timedOut: user.timedOut,
      disabled: user.disabled,
      loggedIn: user.loggedIn,
      isSuperAdmin: user.isSuperAdmin,
      isLockedOut: user.isLockedOut,
      children: [],
    } as IUserTreeItem),
  );

  const rootGroupIds: string[] = treeItems
    .filter(
      (i) => i.parent === '0' || !treeItems.find((t) => t.id === i.parent),
    )
    .map((rootGroup) => rootGroup.id);

  // parent element for all tree items on start of array
  treeItems = (
    [
      {
        id: '0',
        parent: null,
        name: '',
        userGroupMemberCount: 0,
        userGroupTreeMemberCount: 0,
        userGroupMemberMaximum: 0,
        userGroupTreeMemberMaximum: 0,
        children: rootGroupIds,
        systemRoleAssigned: false,
        categoryPermissionsAssigned: false,
        userGroupPermissionsAssigned: false,
      },
    ] as (IUserGroupTreeItem | IUserTreeItem)[]
  ).concat(treeItems);

  return treeItems;
}
