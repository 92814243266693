import { decode } from 'html-entities';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  Article,
  Category,
  useDeleteApiRecycleBinDeleteMutation,
  useGetApiRecycleBinQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import {
  removeExpandedRecycleBinCategory,
  selectContextArticle,
  selectContextCategory,
  setContextArticle,
  setContextCategory,
} from '../../../redux/store/content/slice';

interface IDeleteDialogProps {
  itemsToDelete: (Article | Category)[];
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
  setSelectedItems: (elements: (Article | Category)[]) => void;
}
function DeleteDialog({
  itemsToDelete,
  dialogShow,
  setDialogShow,
  setSelectedItems,
}: IDeleteDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const contextArticle = useAppSelector(selectContextArticle);
  const contextCategory = useAppSelector(selectContextCategory);
  const [deleteItems, { isError, isLoading, error }] =
    useDeleteApiRecycleBinDeleteMutation();
  const { refetch: refetchGetRecycleBin } = useGetApiRecycleBinQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'DeleteError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleDeleteArticle = (): void => {
    const articlesToDelete: Article[] = contextArticle.id
      ? [contextArticle]
      : (itemsToDelete.filter((i) => 'categoryId' in i) as Article[]);
    const categoriesToDelete: Category[] = contextCategory.id
      ? [contextCategory]
      : (itemsToDelete.filter((i) => 'categoryTypeId' in i) as Category[]);

    const articleIds = articlesToDelete.map((a) => a.id || '');
    const categoryIds = categoriesToDelete.map((c) => c.id || '');

    deleteItems({
      categoryTreeBatchOperationItems: { articleIds, categoryIds },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(removeExpandedRecycleBinCategory(categoryIds));
        refetchGetRecycleBin();
        setDialogShow(false);
        setSelectedItems([]);
        dispatch(setContextArticle({}));
        dispatch(setContextCategory({}));
      });
  };

  return (
    <CustomDialog
      titleId='DeleteArticleDialog'
      dialogTitle={translation(
        itemsToDelete.length > 1 ? 'deleteElements' : 'deleteElement',
      )}
      show={dialogShow}
      closeFunction={() => {
        setDialogShow(false);
        dispatch(setContextArticle({}));
        dispatch(setContextCategory({}));
      }}
      actionFunction={handleDeleteArticle}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>
            {translation(
              itemsToDelete.length > 1
                ? 'deleteElementsPermanently'
                : 'deleteElementPermanently',
            )}
          </p>{' '}
          {itemsToDelete.length === 1 && (
            <p className='fw-bold'>
              {'categoryId' in itemsToDelete[0]
                ? decode(itemsToDelete[0].recentVersionTitle)
                : decode((itemsToDelete[0] as Category).name)}
            </p>
          )}
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteDialog;
