// session storage keys
export const EDIT_MODE_KEY = 'editMode';
export const MARK_VISIBILITY_GROUP_KEY = 'activeMarkVisibilityGroup';
export const EXPANDED_CATEGORY_IDS = 'expandedCategories';

// html element ids
export const VERSION_CONTENT_CONTAINER_ID = 'VersionContentContainer';
export const CALCULATION_CONTENT_CONTAINER_ID = 'CalculationContent';
export const SUGGESTION_CONTENT_CONTAINER_ID = 'SuggestionContainer';
export const TEMP_SUGGESTION_EL_ID = 'TempSuggestionEl';

// html element class names
export const SEARCH_RESULT_SPAN = 'search-result-span';

// breakpoints
export const xsBreakpoint = 0;
export const smBreakpoint = 576;
export const mdBreakpoint = 768;
export const lgBreakpoint = 992;
export const xlBreakpoint = 1200;
export const xxlBreakpoint = 1400;

// url parameter
export const versionIdUrlParam = 'versionId';
export const markIdUrlParam = 'markId';
export const articleIdUrlParam = 'articleId';
export const categoryIdUrlParam = 'categoryId';
export const articleIdsWithFoundTermsUrlParam = 'articleIdsWithFoundTerms';
export const textAreaIdUrlParam = 'areaId';
export const pdfArticleUrlParam = 'pdfArticle';
export const searchKeywordsUrlParam = 'searchKeywords';
export const searchResultSortUrlParam = 's';
export const searchKeywordUrlParam = 'k';
export const searchOptionUrlParam = 'o';
export const includeVersionsUrlParam = 'v';
export const selectedCategoriesUrlParam = 'c';
export const searchResultTypeUrlParam = 't';

// settings
export const settingTypeClientSessionTimeoutInterval =
  'settingTypeClientSessionTimeoutInterval';
export const settingTypeSessionEditModeTimeoutInterval =
  'settingTypeSessionEditModeTimeoutInterval';
export const settingTypeTinyConfigFile = 'settingTypeTinyConfigFile';
export const settingTypeAllowedFileTypes = 'settingTypeAllowedFileTypes';
export const settingTypeInformationText = 'settingTypeInformationText';
export const settingTypeImprintText = 'settingTypeImprintText';
export const settingTypePrivacyText = 'settingTypePrivacyText';
export const settingTypeSupportText = 'settingTypeSupportText';
export const settingTypeContentTreeValidCheckInterval =
  'settingTypeContentTreeValidCheckInterval';
export const settingTypeCaching = 'settingTypeCaching';
export const settingTypeLogo = 'settingTypeLogo';
export const settingTypeLogoSmall = 'settingTypeLogoSmall';
export const settingTypeMaxCategoryNameLength =
  'settingTypeMaxCategoryNameLength';
export const settingTypeMaxContentNameLength =
  'settingTypeMaxContentNameLength';
export const settingTypeMaxAbbreviationLength =
  'settingTypeMaxAbbreviationLength';
export const settingTypeMaxNoteContentLength =
  'settingTypeMaxNoteContentLength';
export const settingTypeMaxVersionCount = 'settingTypeMaxVersionCount';
export const settingTypeMaxFileSize = 'settingTypeMaxFileSize';
export const settingTypeMaxFileAmount = 'settingTypeMaxFileAmount';
export const settingTypeApplicationName = 'settingTypeApplicationName';
export const settingTypeManualContent = 'settingTypeManualContent';

// shared constants
export const permissionCacheDelayInSeconds = 1800;
export const countOfTimeDigitsInDate = 10;
export const paddingOffsetForTreeElements = 24;
export const fileExtensions = [
  '.csv',
  '.doc',
  '.docx',
  '.docm',
  '.dotx',
  '.dotm',
  '.dot',
  '.gif',
  '.jpeg',
  '.jpg',
  '.odp',
  '.ods',
  '.odt',
  '.pdf',
  '.png',
  '.ppt',
  '.pptm',
  '.pptx',
  '.pot',
  '.potm',
  '.potx',
  '.pps',
  '.ppsm',
  '.ppsx',
  '.rtf',
  '.svg',
  '.txt',
  '.xml',
  '.xls',
  '.xlsx',
  '.xlsm',
  '.xlsb',
  '.xltx',
  '.xlt',
  '.xltm',
  '.xps',
  '.mp3',
  '.wav',
  '.aac',
  '.ogg',
  '.mp4',
  '.avi',
  '.mov',
  '.wmv',
];

// regex for line breaks
export const lineBreakRegex = /(\r\n|\n|\r)/gm;
