import { VERSION_CONTENT_CONTAINER_ID } from '../../../shared/constants';
import { getNodeIgnoreFiberNodes } from './nodes';

const getDownToStartTextNode = (node: Node): Node => {
  if (node?.nodeType === Node.TEXT_NODE || !node?.firstChild) {
    return node;
  }

  return getDownToStartTextNode(node.firstChild);
};

const getDownToEndTextNode = (node: Node): Node => {
  if (node?.nodeType === Node.TEXT_NODE || !node?.lastChild) {
    return node;
  }

  return getDownToEndTextNode(node.lastChild);
};

const findEndContainer = (node: Node): Node | null => {
  const versionContentContainer = document.getElementById(
    VERSION_CONTENT_CONTAINER_ID,
  );
  const n = node?.parentNode;

  if (
    !n ||
    n === versionContentContainer ||
    (!n.previousSibling && !n.parentNode)
  ) {
    return null;
  }

  if (n?.previousSibling) {
    return getNodeIgnoreFiberNodes(n, 'previousSibling');
  }

  return findEndContainer(node?.parentNode);
};

const preprocessRange = (range: Range): Range | undefined => {
  let sContainer = range?.startContainer;
  let sOffset = range?.startOffset;
  let eContainer: Node | null = range?.endContainer;
  let eOffset = range?.endOffset;

  if (sContainer?.nodeType !== Node.TEXT_NODE) {
    const { childNodes } = sContainer;
    sContainer = childNodes[sOffset];
    sOffset = 0;
    sContainer = getDownToStartTextNode(sContainer);
  }

  if (eContainer?.nodeType !== Node.TEXT_NODE) {
    const { childNodes } = eContainer;
    eContainer =
      eOffset === 0 ? findEndContainer(eContainer) : childNodes[eOffset - 1];

    if (!eContainer) {
      return undefined;
    }

    eContainer = getDownToEndTextNode(eContainer);
    eOffset = eContainer?.textContent?.length || 0;
  }

  try {
    range.setStart(sContainer, sOffset);
  } catch {
    return undefined;
  }
  try {
    range.setEnd(eContainer, eOffset);
  } catch {
    return undefined;
  }

  return range;
};

export default preprocessRange;
