import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import Loader from '../loader/Loader';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  selectArticleReferencesWithLink,
  selectNoteData,
  selectVersionHtmlContent,
  setActiveNote,
  setLinksNeedingAction,
  setMarksNeedingAction,
  setShowNotePopoverOpened,
  setVersionHtmlContent,
} from '../../redux/store/content/slice';
import {
  ArticleReference,
  Mark,
  Note,
  useGetApiArticlesReferencesByIdQuery,
  useGetApiNotesByVersionIdQuery,
  useGetApiVersionsArticleByIdQuery,
  usePutApiMarksMutation,
  usePutApiSourceContentAreasUpdateModifiedDatesMutation,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import makeContent from './functions/makeContent';
import { VisibilityGroupString } from '../notes/types';
import {
  articleIdUrlParam,
  EDIT_MODE_KEY,
  MARK_VISIBILITY_GROUP_KEY,
  markIdUrlParam,
  VERSION_CONTENT_CONTAINER_ID,
  searchKeywordUrlParam,
  SEARCH_RESULT_SPAN,
} from '../../shared/constants';
import {
  doubleDecodeHtmlEntities,
  getMultipleWordSearchRegex,
  preventApplicationReloadAndOpenInternalRoute,
} from '../../shared/utils';
import VersionAlerts from './VersionAlerts';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { RightKey } from '../../shared/enums';
import {
  getSortedNotesPropertyByVisibilityGroup,
  scrollMarkIntoView,
} from '../notes/functions';
import { contentReferenceClass } from './functions/surroundTextNodesInRange';
import { getMarkFromTrustedSuggestion } from './functions/findSuggestion';

function VersionContent() {
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const decodedVersionHtmlContent = doubleDecodeHtmlEntities(
    version.htmlContent || '',
  );
  const htmlContent = useAppSelector(selectVersionHtmlContent);
  const noteData = useAppSelector(selectNoteData);
  const referenceData = useAppSelector(selectArticleReferencesWithLink);
  const [activeMarkVisibilityGroup, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );

  const [noteDataProp, setNoteDataProp] = useState(
    getSortedNotesPropertyByVisibilityGroup(activeMarkVisibilityGroup),
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchRegex, setSearchRegex] = useState<RegExp | null>(null);
  const articleId: string = searchParams.get(articleIdUrlParam) || '';
  const markIdFromBookmark: string | null = searchParams.get(markIdUrlParam);
  const allNotes: Note[] = [
    ...(noteData?.userNotes || []),
    ...(noteData?.userGroupNotes || []),
    ...(noteData?.generalNotes || []),
  ];
  const searchKeywordsParam: string | null = searchParams.get(
    searchKeywordUrlParam,
  );

  const { refetch: refetchRefererences, isFetching: fetchingReferences } =
    useGetApiArticlesReferencesByIdQuery(
      articleId
        ? {
            id: articleId,
          }
        : skipToken,
      { refetchOnMountOrArgChange: true },
    );
  const [
    updateMarks,
    { isError: updateMarksIsError, error: updateMarksError },
  ] = usePutApiMarksMutation();
  const [
    updateSourceContentAreaModifiedDates,
    {
      isError: updateSourceContentAreaModifiedDateIsError,
      error: updateSourceContentAreaModifiedDateError,
    },
  ] = usePutApiSourceContentAreasUpdateModifiedDatesMutation();
  const { refetch: refetchNotes, isFetching: fetchingNotes } =
    useGetApiNotesByVersionIdQuery(
      version.id
        ? {
            versionId: version.id,
          }
        : skipToken,
    );

  const category = useGetCategoryByArticleId(articleId);
  const userCanEditArticle =
    category?.permittedActions?.includes(
      RightKey.RightArticleManagementEditArticle,
    ) || false;
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const { isFetching: fetchingVersions } = useGetApiVersionsArticleByIdQuery(
    articleId
      ? { id: articleId, editMode: editModeIsActive && userCanEditArticle }
      : skipToken,
  );

  const [isFetching, setIsFetching] = useState(
    fetchingReferences || fetchingNotes || fetchingVersions,
  );
  const [dataIsConsistent, setDataIsConsistent] = useState(false);
  const [calculationProcessing, setCalculationProcessing] = useState(true);

  useEffect(() => {
    if (searchKeywordsParam) {
      setActiveMarkVisibilityGroup(null);
      const searchKeywords: string[] = searchKeywordsParam.split(',');
      const regex = getMultipleWordSearchRegex(searchKeywords);

      setSearchRegex(regex);
    } else {
      setSearchRegex(null);
    }
  }, [searchKeywordsParam, htmlContent]);

  useEffect(() => {
    if (calculationProcessing) {
      return;
    }

    // Prevent full application reload when opening the link of an anchor tag
    const contentRoot = document.getElementById(VERSION_CONTENT_CONTAINER_ID);
    const anchorTags: NodeListOf<HTMLAnchorElement> | undefined =
      contentRoot?.querySelectorAll(`a[class='${contentReferenceClass}']`);

    if (!anchorTags) {
      return;
    }

    anchorTags.forEach((a) => {
      ['click', 'onkeydown'].forEach((evt) =>
        a.addEventListener(
          evt,
          (e) => {
            preventApplicationReloadAndOpenInternalRoute(e, a);
          },
          false,
        ),
      );
    });

    // eslint-disable-next-line consistent-return
    return () => {
      anchorTags.forEach((a) => {
        ['click', 'onkeydown'].forEach((evt) =>
          a.removeEventListener(
            evt,
            (e) => {
              preventApplicationReloadAndOpenInternalRoute(e, a);
            },
            false,
          ),
        );
      });
    };
  }, [calculationProcessing]);

  useEffect(() => {
    if (calculationProcessing) {
      return;
    }

    // Add spans with highlight border to search results
    if (!searchRegex) {
      return;
    }

    const contentWithLinks = htmlContent.repeat(1);

    const newContent = contentWithLinks.replace(
      searchRegex,
      (match) =>
        `<span class='${SEARCH_RESULT_SPAN} border border-2 border-primary'>${match}</span>`,
    );

    dispatch(setVersionHtmlContent(newContent));

    setTimeout(() => {
      const focusEl = document.getElementsByClassName(SEARCH_RESULT_SPAN)[0];

      if (focusEl) {
        focusEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  }, [calculationProcessing]);

  useEffect(() => {
    setIsFetching(fetchingReferences || fetchingNotes || fetchingVersions);
  }, [fetchingReferences, fetchingNotes, fetchingVersions]);

  useEffect(() => {
    const getDataIsConsistent = (): boolean => {
      if (
        !referenceData ||
        !noteData ||
        !version ||
        noteData.versionId !== version.id
      ) {
        return false;
      }

      return true;
    };

    if (isFetching) {
      setDataIsConsistent(false);
      setCalculationProcessing(true);
    } else {
      const dataIC = getDataIsConsistent();
      setDataIsConsistent(dataIC);

      if (!dataIC) {
        setCalculationProcessing(true);
      }
    }
  }, [isFetching, version]);

  const findAndShowNote = (e: Event) => {
    e.preventDefault();

    if (noteDataProp && noteData) {
      const idExtentionRegex = /(-(\d)+\/(\d)+)$/g;
      const markEl = e.target as HTMLElement;
      const markId = markEl.id.replace(idExtentionRegex, '');
      const note: Note =
        noteData[`${noteDataProp}`]?.find((n) => n?.mark?.id === markId) || {};

      dispatch(setActiveNote(note));
      dispatch(setShowNotePopoverOpened(true));
    }
  };

  useEffect(() => {
    // Make currently displayed marks clickable
    if (!activeMarkVisibilityGroup || calculationProcessing) {
      return;
    }

    const marks = Array.from(document.getElementsByTagName('MARK'));
    marks.forEach((mark) => {
      mark.addEventListener('click', (e) => findAndShowNote(e));
    });
  }, [htmlContent, calculationProcessing, activeMarkVisibilityGroup]);

  useEffect(() => {
    // Set focus on article title
    const articleTitleElement = document
      .getElementsByClassName('article-content')[0]
      .getElementsByClassName('card-title')[0]
      .getElementsByTagName('h1')[0];

    articleTitleElement.focus();
  }, [htmlContent]);

  const hasRecentChanges = (
    elementModifiedDate: string,
    versionModifiedDate: string,
  ): boolean => {
    if (Date.parse(elementModifiedDate) > Date.parse(versionModifiedDate)) {
      return false;
    }
    return true;
  };

  const tryToFindElement = (
    element: Note | ArticleReference,
    tagName: 'mark' | 'a',
  ): boolean => {
    // contains a range when the exact pattern is found exactly where expected, is undefined otherwise
    const testContent = makeContent({
      content: decodedVersionHtmlContent,
      elements: [element],
      withoutIds: true,
      tagName,
    });

    if (testContent.elementsNotFound.length === 0) {
      return true;
    }

    return false;
  };

  const evaluateContentChangesForMarks = (
    notes: Note[],
    content: string,
  ): string[] | undefined => {
    const marksNotFound: string[] = [];
    const marksToUpdate: Mark[] = [];
    notes.forEach((note) => {
      if (!note.mark?.id || !note.mark.modifiedDate || !version.modifiedDate) {
        return;
      }

      if (hasRecentChanges(note.mark.modifiedDate, version.modifiedDate)) {
        const markWasFound = tryToFindElement(note, 'mark');
        if (!markWasFound) {
          const recalculatedMark = getMarkFromTrustedSuggestion(
            content,
            note.mark,
          );
          if (recalculatedMark) {
            marksToUpdate.push({
              id: note.mark.id,
              noteId: note.id,
              ...recalculatedMark,
            });
          } else {
            marksNotFound.push(note.mark.id);
          }
        } else {
          marksToUpdate.push(note.mark);
        }
      }
    });

    if (marksToUpdate.length > 0) {
      updateMarks({ body: marksToUpdate }).then(() => {
        refetchNotes();
      });
    }
    return marksNotFound;
  };

  const evaluateContentChangesForLinks = (
    references: ArticleReference[],
  ): string[] | undefined => {
    const linksNotFound: string[] = [];
    const linksToUpdate: string[] = [];
    references.forEach((reference) => {
      if (
        !reference.sourceContentArea?.id ||
        !reference.sourceContentArea?.modifiedDate ||
        !version.modifiedDate
      ) {
        return;
      }

      if (
        hasRecentChanges(
          reference.sourceContentArea.modifiedDate,
          version.modifiedDate,
        )
      ) {
        const linkWasFound = tryToFindElement(reference, 'a');
        if (!linkWasFound) {
          linksNotFound.push(reference.sourceContentArea?.id);
        } else {
          linksToUpdate.push(reference.sourceContentArea?.id);
        }
      }
    });

    if (linksToUpdate.length > 0) {
      updateSourceContentAreaModifiedDates({ body: linksToUpdate }).then(() => {
        refetchRefererences();
      });
    }
    return linksNotFound;
  };

  useEffect(() => {
    if (!dataIsConsistent) {
      return;
    }

    let contentWithLinks = decodedVersionHtmlContent;
    let linksNotFound: string[] = [];

    if (referenceData.length > 0 && version.isRecentVersion) {
      linksNotFound = evaluateContentChangesForLinks(referenceData) || [];
      const linksToShow = [];

      linksToShow.push(
        ...(referenceData.filter(
          (r) =>
            r.sourceContentArea?.id &&
            r.sourceArticleId === articleId &&
            !linksNotFound.includes(r.sourceContentArea?.id),
        ) || []),
      );

      const contentObjectWithLinks = makeContent({
        content: decodedVersionHtmlContent,
        elements: linksToShow,
        tagName: 'a',
      });

      contentWithLinks = contentObjectWithLinks.content;

      contentObjectWithLinks.elementsNotFound.forEach((linkId) => {
        if (!linksNotFound.includes(linkId)) {
          linksNotFound.push(linkId);
        }
      });
    }

    if (
      category?.permittedActions?.includes(
        RightKey.RightArticleManagementEditArticle,
      )
    ) {
      dispatch(setLinksNeedingAction([...linksNotFound]));
    }

    let currentNoteData: Note[] = [];
    if (noteDataProp) {
      currentNoteData = noteData[`${noteDataProp}`] || [];
    }

    if (!allNotes.find((note) => note.mark)) {
      dispatch(setVersionHtmlContent(contentWithLinks));
      dispatch(setMarksNeedingAction([]));
      setCalculationProcessing(false);

      return;
    }

    const marksNotFound =
      evaluateContentChangesForMarks(allNotes, contentWithLinks) || [];
    const marksToShow = [];

    if (currentNoteData.length > 0) {
      marksToShow.push(
        ...(currentNoteData.filter(
          (n) => n.mark?.id && !marksNotFound.includes(n.mark.id),
        ) || []),
      );
    }

    const contentObject = makeContent({
      content: contentWithLinks,
      elements: marksToShow,
      tagName: 'mark',
    });

    dispatch(setVersionHtmlContent(contentObject.content));

    contentObject.elementsNotFound.forEach((markId) => {
      if (!marksNotFound.includes(markId)) {
        marksNotFound.push(markId);
      }
    });

    dispatch(setMarksNeedingAction([...marksNotFound]));

    setCalculationProcessing(false);
  }, [version, referenceData, noteData, noteDataProp, dataIsConsistent]);

  useEffect(() => {
    if (calculationProcessing) {
      return;
    }

    if (markIdFromBookmark) {
      const noteOfMarkIdInUrl = allNotes.find(
        (n) => n.mark?.id === markIdFromBookmark,
      );

      if (noteOfMarkIdInUrl) {
        dispatch(setActiveNote(noteOfMarkIdInUrl));
        dispatch(setShowNotePopoverOpened(true));
        scrollMarkIntoView(noteOfMarkIdInUrl);
        searchParams.delete(markIdUrlParam);
        setSearchParams(searchParams);
      }
    }
  }, [calculationProcessing]);

  useEffect(() => {
    if (markIdFromBookmark && !calculationProcessing) {
      const noteOfMarkIdInUrl = allNotes.find(
        (n) => n.mark?.id === markIdFromBookmark,
      );

      if (noteOfMarkIdInUrl) {
        dispatch(setActiveNote(noteOfMarkIdInUrl));
        dispatch(setShowNotePopoverOpened(true));
        scrollMarkIntoView(noteOfMarkIdInUrl);
        searchParams.delete(markIdUrlParam);
        setSearchParams(searchParams);
      }
    }
  }, [markIdFromBookmark, calculationProcessing]);

  useEffect(() => {
    if (searchParams.has(searchKeywordUrlParam) && activeMarkVisibilityGroup) {
      searchParams.delete(searchKeywordUrlParam);
      setSearchParams(searchParams);
      setSearchRegex(null);
    }
    setNoteDataProp(
      getSortedNotesPropertyByVisibilityGroup(activeMarkVisibilityGroup),
    );
  }, [activeMarkVisibilityGroup]);

  useEffect(() => {
    if (updateMarksIsError) {
      dispatch(
        addMessage({
          id: 'UpdateMarkError',
          variant: 'danger',
          messageKeyBody:
            updateMarksError && 'data' in updateMarksError
              ? updateMarksError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (updateSourceContentAreaModifiedDateIsError) {
      dispatch(
        addMessage({
          id: 'UpdateSourceContentAreaError',
          variant: 'danger',
          messageKeyBody:
            updateSourceContentAreaModifiedDateError &&
            'data' in updateSourceContentAreaModifiedDateError
              ? updateSourceContentAreaModifiedDateError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [updateMarksIsError, updateSourceContentAreaModifiedDateIsError]);

  return (
    <>
      {!calculationProcessing && <VersionAlerts />}
      <div
        id={VERSION_CONTENT_CONTAINER_ID}
        className='mt-2 pt-1 version-content'
        aria-busy={calculationProcessing}>
        {calculationProcessing && <Loader />}
        {!calculationProcessing && parse(htmlContent)}
      </div>
    </>
  );
}

export default VersionContent;
