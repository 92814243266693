import { decode } from 'html-entities';
import { SyntheticEvent } from 'react';
import { Form } from 'react-bootstrap';
import { paddingOffsetForTreeElements } from '../../shared/constants';
import { ICategoryTreeItem } from '../content-tree/types';

interface ISearchFilterCategoryTreeItemProps {
  element: ICategoryTreeItem;
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  getNodeProps: Function;
  handleSelect: Function;
  handleExpand: Function;
  isSelected: boolean;
}

function SearchFilterCategoryTreeItem({
  element,
  level,
  isBranch,
  isExpanded,
  getNodeProps,
  handleSelect,
  handleExpand,
  isSelected,
}: ISearchFilterCategoryTreeItemProps): JSX.Element {
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getNodeProps({
        onClick: (e: SyntheticEvent) => {
          if (
            (e.target as HTMLElement).classList.contains('form-check-input')
          ) {
            return;
          }

          handleExpand(e);
        },
      })}
      style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
      <div className='d-flex'>
        {isBranch && isExpanded && (
          <i className='me-2 mt-1 fs-5 icon-carretup' aria-hidden />
        )}
        {isBranch && !isExpanded && (
          <i className='me-2 mt-1 fs-5 icon-carretdown' aria-hidden />
        )}
        <Form.Check
          className='ms-2 mt-1'
          type='checkbox'
          onChange={(e) => {
            e.stopPropagation();
            handleSelect(e);
          }}
          checked={isSelected}
          id={decode(element.name)}
          label={
            <span className='visually-hidden'>{decode(element.name)}</span>
          }
        />
        <span>
          <i
            className={`${element.categoryTypeIconClass} me-1 fs-4 align-text-top`}
            aria-hidden
            style={{
              color: element.categoryTypeColorHexCode || '',
            }}
          />
          <span>{decode(element.name)}</span>
        </span>
      </div>
    </div>
  );
}

export default SearchFilterCategoryTreeItem;
