import { SetStateAction, SyntheticEvent } from 'react';
import { Dropdown } from 'react-bootstrap';
import ContextActionItemContent from './ContextActionItemContent';
import { ContextAction } from './types';

interface IContextMenuButtonProps {
  action: ContextAction;
  setShow: (value: SetStateAction<boolean>) => void;
}

function ContextMenuButton({ action, setShow }: IContextMenuButtonProps) {
  const handleOnClick = (event: SyntheticEvent<HTMLElement>): void => {
    event.preventDefault();

    if (action.onClick) {
      action.onClick();
    }
  };

  return (
    <Dropdown.Item
      as='button'
      onClick={(e) => {
        handleOnClick(e);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleOnClick(e);
          setShow(false);
        }
      }}
      className='flex-grow'>
      <ContextActionItemContent action={action} />
    </Dropdown.Item>
  );
}

export default ContextMenuButton;
