import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useAppDispatch } from '../../../redux/hooks';
import {
  ArticleReference,
  ArticleReferenceList,
  ExternalReference,
  useGetApiArticlesReferencesByIdQuery,
  useGetApiCategoriesArticleReferencesByIdQuery,
  useGetApiCategoryTreeQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomAccordion from '../../accordion/CustomAccordion';
import ArticleReferences from './ArticleReferences';
import DeleteReferenceDialog from '../dialogs/DeleteReferenceDialog';
import RenameReferenceDialog from '../dialogs/RenameReferenceDialog';
import ExternalReferences from './ExternalReferences';
import {
  EDIT_MODE_KEY,
  articleIdUrlParam,
  categoryIdUrlParam,
} from '../../../shared/constants';
import { setArticleReferencesWithLink } from '../../../redux/store/content/slice';
import LinkNeedsActionDialog from '../dialogs/LinkNeedsActionDialog';
import { RightKey } from '../../../shared/enums';
import useGetCategoryByArticleId from '../../../hooks/useGetCategoryByArticleId';

interface IReferencesListProps {
  userCanEditReference: boolean;
}

function ReferencesList({
  userCanEditReference,
}: IReferencesListProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const categoryId: string | null = searchParams.get(categoryIdUrlParam);
  const defaultActiveReferenceKey = '1';
  const [activeReferenceKey, setActiveReferenceKey] = useState(
    defaultActiveReferenceKey,
  );
  const [numberOfReferences, setNumberOfReferences] = useState<number[]>([]);
  const [numberOfExternalReferences, setNumberOfExternalReferences] =
    useState<number>();
  const [deleteDialogShow, setDeleteDialogShow] = useState(false);
  const [renameDialogShow, setRenameDialogShow] = useState(false);
  const [contextReference, setContextReference] = useState<
    ArticleReference | ExternalReference
  >({});
  const [linkNeedsActionDialogOpened, setLinkNeedsActionDialogOpened] =
    useState(false);
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const {
    data: articleReferenceData,
    isError: getReferencesOfArticleIsError,
    isFetching: getReferencesOfArticleIsFetching,
    error: getReferencesOfArticleError,
  } = useGetApiArticlesReferencesByIdQuery(
    articleId
      ? {
          id: articleId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const {
    data: categoryReferenceData,
    isError: getReferencesOfCategoryIsError,
    isFetching: getReferencesOfCategoryIsFetching,
    error: getReferencesOfCategoryError,
  } = useGetApiCategoriesArticleReferencesByIdQuery(
    categoryId ? { id: categoryId } : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { data: contentTreeData } = useGetApiCategoryTreeQuery();
  const categoryByCategoryId = contentTreeData?.resultObject?.categories?.find(
    (c) => c.id === categoryId,
  );
  const categoryByArticleId = useGetCategoryByArticleId(articleId);
  const category = categoryByArticleId || categoryByCategoryId;

  // permissions
  const userCanEditArticle = category?.permittedActions?.includes(
    RightKey.RightArticleManagementEditArticle,
  );

  const getRefHasContentLink = (r: ArticleReference) =>
    !!(r.sourceContentArea || r.hasContentLink);

  const getShowReference = (
    ref: ArticleReference,
    refList: ArticleReference[],
  ) => {
    const previousRefs = refList.slice(0, refList.indexOf(ref));

    if (!editModeIsActive || !userCanEditArticle) {
      if (articleId === ref.sourceArticleId) {
        return (
          previousRefs.length < 1 ||
          !previousRefs.find(
            (r) =>
              getRefHasContentLink(r) &&
              r.targetCategoryId === ref.targetCategoryId &&
              r.targetArticleId === ref.targetArticleId &&
              r.targetContentAreaId === ref.targetContentAreaId,
          )
        );
      }

      if (
        (articleId === ref.targetArticleId ||
          categoryId === ref.targetCategoryId) &&
        getRefHasContentLink(ref)
      ) {
        return (
          previousRefs.length < 1 ||
          !previousRefs.find(
            (r) =>
              r.sourceArticleId === ref.sourceArticleId &&
              getRefHasContentLink(r),
          )
        );
      }
    }

    return true;
  };

  const filterReferencesLists = (arL: ArticleReferenceList[]) => {
    const newRefLists: ArticleReferenceList[] = [];

    arL.forEach((list) => {
      const newRefs: ArticleReference[] = [];

      list.articleReferences?.forEach((ref) => {
        if (getShowReference(ref, list.articleReferences || [])) {
          newRefs.push(ref);
        }
      });

      if (newRefs.length > 0) {
        const newList = { ...list, articleReferences: newRefs };
        newRefLists.push(newList);
      }
    });

    return newRefLists;
  };

  const referencesOfArticle = filterReferencesLists(
    articleReferenceData?.resultObject?.articleReferenceLists || [],
  );
  const referencesOfCategory = filterReferencesLists(
    categoryReferenceData?.resultObject || [],
  );
  const isFetching =
    getReferencesOfArticleIsFetching || getReferencesOfCategoryIsFetching;
  const thereAreReferencesOfArticle =
    referencesOfArticle && referencesOfArticle.length > 0;
  const thereAreReferencesOfCategory =
    referencesOfCategory && referencesOfCategory.length > 0;
  const articleReferenceLists: ArticleReferenceList[] | undefined | null =
    thereAreReferencesOfArticle ? referencesOfArticle : referencesOfCategory;
  const externalReferences: ExternalReference[] | undefined | null =
    articleReferenceData?.resultObject?.externalReferences;
  const thereAreExternalReferences =
    externalReferences && externalReferences.length > 0;
  const thereAreReferences =
    thereAreReferencesOfArticle ||
    thereAreReferencesOfCategory ||
    thereAreExternalReferences;

  const getAllArticleReferencesWithLinks = (): ArticleReference[] => {
    const articleRefs: ArticleReference[] = [];

    articleReferenceData?.resultObject?.articleReferenceLists?.forEach(
      (arL) => {
        arL.articleReferences?.forEach((aR) => {
          articleRefs.push(aR);
        });
      },
    );

    return articleRefs.filter((aR) => getRefHasContentLink(aR));
  };

  useEffect(() => {
    if (getReferencesOfArticleIsError) {
      dispatch(
        addMessage({
          id: 'GetReferencesOfArticleError',
          variant: 'danger',
          messageKeyBody:
            getReferencesOfArticleError && 'data' in getReferencesOfArticleError
              ? getReferencesOfArticleError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getReferencesOfCategoryIsError) {
      dispatch(
        addMessage({
          id: 'GetReferencesOfCategoryError',
          variant: 'danger',
          messageKeyBody:
            getReferencesOfCategoryError &&
            'data' in getReferencesOfCategoryError
              ? getReferencesOfCategoryError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getReferencesOfArticleIsError, getReferencesOfCategoryIsError]);

  useEffect(() => {
    const numberAR: number[] = [];

    articleReferenceLists?.forEach((arL, index) => {
      numberAR.push(arL.articleReferences?.length as number);
      if (numberAR[index] > numberOfReferences[index]) {
        setActiveReferenceKey(String(index + 1));
      }
    });
    if (numberAR.length < numberOfReferences.length) {
      setActiveReferenceKey(defaultActiveReferenceKey);
    }
    if (numberOfReferences.length > 0) {
      if (numberAR.length > numberOfReferences.length) {
        setActiveReferenceKey(String(numberAR.length));
      }
    }
    dispatch(setArticleReferencesWithLink(getAllArticleReferencesWithLinks()));
    setNumberOfReferences(numberAR);
  }, [articleReferenceData, categoryReferenceData]);

  useEffect(() => {
    const numberER = externalReferences?.length as number;

    if (numberOfExternalReferences !== undefined) {
      if (numberER > numberOfExternalReferences) {
        setActiveReferenceKey('0');
      }
      if (numberOfExternalReferences === 0) {
        if (numberER > 0) {
          setActiveReferenceKey('0');
        }
      }
    }
    dispatch(setArticleReferencesWithLink(getAllArticleReferencesWithLinks()));
    if (numberER !== 0) {
      setNumberOfExternalReferences(numberER);
    }
    if (numberER === 0 && !thereAreExternalReferences) {
      setActiveReferenceKey(defaultActiveReferenceKey);
      setNumberOfExternalReferences(0);
    }
  }, [externalReferences]);

  useEffect(() => {
    articleReferenceLists?.forEach((arL) => {
      arL.articleReferences?.forEach((aR) => {
        if (aR.id === contextReference.id) {
          setActiveReferenceKey(String(articleReferenceLists.indexOf(arL) + 1));
        }
      });
    });
    dispatch(setArticleReferencesWithLink(getAllArticleReferencesWithLinks()));
  }, [contextReference]);

  useEffect(() => {
    if (
      thereAreExternalReferences &&
      !thereAreReferencesOfArticle &&
      !thereAreReferencesOfCategory
    ) {
      setActiveReferenceKey('0');
    } else {
      setActiveReferenceKey(defaultActiveReferenceKey);
    }
  }, [thereAreReferencesOfArticle, thereAreReferencesOfCategory]);

  useEffect(() => {
    setActiveReferenceKey(defaultActiveReferenceKey);
    setNumberOfReferences([]);
    setNumberOfExternalReferences(undefined);
  }, [articleId]);

  return (
    <>
      {isFetching ? (
        <p>{translation('loaded')}</p>
      ) : (
        <>
          {!thereAreReferences && <p>{translation('noReferences')}</p>}
          {thereAreReferences && (
            <CustomAccordion activeKey={activeReferenceKey}>
              <ArticleReferences
                setActiveReferenceKey={setActiveReferenceKey}
                setContextReference={setContextReference}
                setDeleteDialogShow={setDeleteDialogShow}
                setRenameDialogShow={setRenameDialogShow}
                activeReferenceKey={activeReferenceKey}
                currentOpenedArticleId={articleId}
                currentOpenedCategoryId={categoryId}
                articleReferenceLists={articleReferenceLists}
                userCanEditReference={userCanEditReference}
                setLinkNeedsActionDialogOpened={setLinkNeedsActionDialogOpened}
              />
              <ExternalReferences
                setActiveReferenceKey={setActiveReferenceKey}
                setContextReference={setContextReference}
                setDeleteDialogShow={setDeleteDialogShow}
                setRenameDialogShow={setRenameDialogShow}
                activeReferenceKey={activeReferenceKey}
                externalReferences={externalReferences}
                userCanEditReference={userCanEditReference}
              />
            </CustomAccordion>
          )}
        </>
      )}
      <DeleteReferenceDialog
        dialogShow={deleteDialogShow}
        setDialogShow={setDeleteDialogShow}
        reference={contextReference}
      />
      <RenameReferenceDialog
        dialogShow={renameDialogShow}
        setDialogShow={setRenameDialogShow}
        reference={contextReference}
      />
      <LinkNeedsActionDialog
        show={linkNeedsActionDialogOpened}
        setShow={setLinkNeedsActionDialogOpened}
      />
    </>
  );
}

export default ReferencesList;
