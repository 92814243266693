import {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

interface IContextTogglerProps {
  onClick: (e: MouseEvent<HTMLElement> | KeyboardEvent) => void;
  'aria-expanded': boolean;
}

function ContextToggler(
  {
    onClick,
    'aria-expanded': ariaExpanded,
    children,
  }: PropsWithChildren<IContextTogglerProps>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { t: translation } = useTranslation();

  return (
    <button
      ref={ref}
      type='button'
      aria-expanded={ariaExpanded}
      aria-label={translation('contextMenu')}
      className='dropdown-toggle btn btn-light'
      onClick={(e) => {
        onClick(e);
        e.preventDefault();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || (!ariaExpanded && e.key === 'ArrowDown')) {
          e.preventDefault();
          onClick(e);
        }

        // custom key navigation required, because otherwise underlying elements are also triggered
        if (ariaExpanded && e.key === 'ArrowDown') {
          const targetElement = e.target as HTMLElement;
          const contextMenu: Element | null = targetElement?.nextElementSibling;
          const ulElementOfMenu: Element | null =
            contextMenu?.firstElementChild || null;
          const allMenuItems: HTMLCollectionOf<HTMLLIElement> | undefined =
            ulElementOfMenu?.getElementsByTagName('li');

          e.stopPropagation();
          (allMenuItems?.item(0)?.firstElementChild as HTMLElement).focus();
        }
      }}>
      {children}
    </button>
  );
}

export default forwardRef(ContextToggler);
