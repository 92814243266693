import { CategoryTree } from '../../redux/store/api/api';
import { ContentTreeItems, IArticleTreeItem, ICategoryTreeItem } from './types';

export function getTreeCategories(treeData: CategoryTree): ContentTreeItems {
  let treeItems: ICategoryTreeItem[] = [];

  treeData.categories?.forEach((category) => {
    treeItems.push({
      id: category.id,
      parent: treeData.categories?.find((c) => c.id === category.parentId)
        ? category.parentId || '0'
        : '0',
      name: category.name,
      order: category.order,
      abbreviation: category.abbreviation,
      deleted: category.deleted,
      disabled: category.disabled,
      categoryTypeColorHexCode: category.categoryTypeColorHexCode,
      categoryTypeIconClass: category.categoryTypeIconClass,
      categoryTypeId: category.categoryTypeId,
      isRecursivePermitted: category.isRecursivePermitted,
      permittedActions: category.permittedActions,
      children: category.categoryIds || [],
      parents: category.treePath || [],
      lastModifiedDate: category.lastModified,
      articleIds: category.articleIds,
      categoryIds: category.categoryIds,
    } as ICategoryTreeItem);
  });

  const rootCategoryIds: string[] = treeItems
    .filter(
      (i) => i.parent === '0' || !treeItems.find((t) => t.id === i.parent),
    )
    .map((i) => i.id);

  // parent element for all tree items on start of array
  treeItems = (
    [
      {
        id: '0',
        parent: null,
        name: '',
        order: 0,
        abbreviation: '',
        deleted: false,
        disabled: false,
        categoryTypeId: '',
        categoryTypeColorHexCode: '',
        categoryTypeIconClass: '',
        children: rootCategoryIds,
        parents: [],
        lastModifiedDate: '',
        articleIds: [],
        categoryIds: [],
      },
    ] as ICategoryTreeItem[]
  ).concat(treeItems);

  return treeItems;
}

export function getTreeItems(treeData: CategoryTree): ContentTreeItems {
  let treeItems: ContentTreeItems = [];
  treeData.categories?.forEach((category) => {
    treeItems.push({
      id: category.id,
      parent: treeData.categories?.find((c) => c.id === category.parentId)
        ? category.parentId || '0'
        : '0',
      name: category.name,
      order: category.order,
      abbreviation: category.abbreviation,
      deleted: category.deleted,
      disabled: category.disabled,
      categoryTypeId: category.categoryTypeId,
      categoryTypeIconClass: category.categoryTypeIconClass,
      categoryTypeColorHexCode: category.categoryTypeColorHexCode,
      isRecursivePermitted: category.isRecursivePermitted,
      permittedPath: category.permittedPath,
      permittedActions: category.permittedActions,
      children: (category.categoryIds || []).concat(category.articleIds || []),
      articleIds: category.articleIds,
      categoryIds: category.categoryIds,
      parents: category.treePath || [],
      lastModifiedDate: category.lastModified,
    } as ICategoryTreeItem);
  });

  // add articles to treeItems
  treeData.articles?.forEach((article) =>
    treeItems.push({
      id: article.id,
      parent: article.categoryId || '0',
      order: article.order,
      type: article.type,
      deleted: article.deleted,
      disabled: article.disabled,
      recentVersionId: article.recentVersionId,
      recentVersionTitle: article.recentVersionTitle,
      versionsDisabled: article.versionsDisabled,
      versionsOnlyDrafts: article.versionsOnlyDrafts,
      versionsInvalid: article.versionsInvalid,
      children: [],
    } as IArticleTreeItem),
  );

  const rootCategoryIds: string[] = treeItems
    .filter(
      (i) => i.parent === '0' || !treeItems.find((t) => t.id === i.parent),
    )
    .map((i) => i.id);

  // parent element for all tree items on start of array
  treeItems = (
    [
      {
        id: '0',
        parent: null,
        name: '',
        order: 0,
        abbreviation: '',
        deleted: false,
        disabled: false,
        categoryTypeId: '',
        categoryTypeColorHexCode: '',
        categoryTypeIconClass: '',
        children: rootCategoryIds,
        parents: [],
        lastModifiedDate: '',
        articleIds: [],
        categoryIds: [],
      },
    ] as (ICategoryTreeItem | IArticleTreeItem)[]
  ).concat(treeItems);

  return treeItems;
}
