import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { decode } from 'html-entities';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useGetApiVersionsByIdQuery } from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import { getTagsWithIds, getTextPassageIds } from './functions';
import Loader from '../loader/Loader';
import { selectActiveVersion } from '../../redux/store/content/slice';

interface IInternalReferenceInArticle {
  textPassage: string | null;
  setTextPassage: (id: string) => void;
  textPassageIsValid: boolean;
  setTextPassageIsValid: (valid: boolean) => void;
  sourceContentAreaAncestorIds: string[];
}

function InternalReferenceInArticle({
  textPassage,
  setTextPassage,
  textPassageIsValid,
  setTextPassageIsValid,
  sourceContentAreaAncestorIds,
}: IInternalReferenceInArticle) {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const activeVersion = useAppSelector(selectActiveVersion);
  const {
    data: versionData,
    isFetching: getVersionIsFetching,
    isError,
    error,
  } = useGetApiVersionsByIdQuery(
    activeVersion.id ? { id: activeVersion.id || '' } : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const versionTextPassageIds = useMemo<string[]>(() => {
    if (versionData?.resultObject) {
      return getTextPassageIds(
        decode(versionData.resultObject.htmlContent || ''),
      );
    }
    return [];
  }, [versionData]);

  const versionTextPassages = useMemo<string[]>(() => {
    if (versionData?.resultObject) {
      return getTagsWithIds(decode(versionData.resultObject.htmlContent || ''));
    }
    return [];
  }, [versionData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetVersionError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <div className='mt-3' aria-busy={getVersionIsFetching}>
      {getVersionIsFetching && <Loader />}
      {versionData && !getVersionIsFetching && (
        <fieldset
          className='max-350 ps-1'
          aria-describedby={
            textPassageIsValid ? undefined : 'TextPassageIsError'
          }>
          <legend className='mb-1 fs-6 mt-3 fw-bold'>
            {translation('chooseTextPassage')}*
          </legend>
          {versionTextPassages.map((p, i) => (
            <Form.Check
              name='TextPassage'
              key={versionTextPassageIds[i]}
              type='radio'>
              <Form.Check.Input
                className='mt-3'
                checked={versionTextPassageIds[i] === textPassage}
                onChange={(e) => {
                  setTextPassage(e.target.value);
                  setTextPassageIsValid(true);
                }}
                type='radio'
                disabled={sourceContentAreaAncestorIds.includes(
                  versionTextPassageIds[i],
                )}
                value={versionTextPassageIds[i]}
              />
              <Form.Check.Label>{parse(p)}</Form.Check.Label>
            </Form.Check>
          ))}
          {!textPassageIsValid && (
            <Form.Control.Feedback
              id='TextPassageIsError'
              type='invalid'
              className='d-block'>
              {translation('fieldNotEmpty')}
            </Form.Control.Feedback>
          )}
        </fieldset>
      )}
    </div>
  );
}

export default InternalReferenceInArticle;
