import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiRolesContextQuery,
  useGetApiUserGroupsQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  clearSelectedUsers,
  selectContentPermissionsDialogOpened,
  selectContextUserGroup,
  setContentPermissionsDialogOpened,
} from '../../../redux/store/user-management/slice';
import { PermissionContext } from '../../../shared/enums';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import ContentPermissionsTree from '../content-permissions/ContentPermissionsTree';

function ContentPermissionsDialog() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const userGroupId = useAppSelector(selectContextUserGroup).id || '';
  const [selectedRole, setSelectedRole] = useState<string>('0');
  const dialogShow = useAppSelector(selectContentPermissionsDialogOpened);
  const {
    data: roles,
    isFetching,
    isError,
    error,
  } = useGetApiRolesContextQuery(
    dialogShow
      ? {
          context: PermissionContext.Category,
          userGroupId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { refetch: refetchGetUserGroups } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });
  const rolesInUse = roles?.resultObject?.filter((r) => r.usedInContext) || [];

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetRolesError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <CustomDialog
      titleId='ContentPermissionsDialog'
      dialogTitle={translation('contentPermissions')}
      show={dialogShow}
      closeFunction={() => {
        refetchGetUserGroups();
        dispatch(clearSelectedUsers());
        dispatch(setContentPermissionsDialogOpened(false));
        setSelectedRole('0');
      }}
      closeTitle={translation('close')}>
      {isFetching && <Loader />}
      {!isFetching && roles?.resultObject && (
        <>
          <p className='m-0'>{translation('rolesThatAreInUse')}</p>
          {rolesInUse.length === 0 && <p>{translation('noRolesInUse')}</p>}
          {rolesInUse.length > 0 && (
            <ul>
              {rolesInUse.map((r) => (
                <li key={r.id || ''}>{r.name}</li>
              ))}
            </ul>
          )}
          <Form>
            <Form.Group className='mb-3' controlId='RoleForPermission'>
              <Form.Label>{translation('selectRole')}</Form.Label>
              <Form.Select
                required
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                }}>
                <option value='0' disabled hidden>
                  {translation('role')}
                </option>
                {roles.resultObject.map((role) => (
                  <option key={role.id} value={role.id || ''}>
                    {role.name}
                    {role.usedInContext ? ` - (${translation('inUse')})` : ''}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
          {selectedRole !== '0' && (
            <ContentPermissionsTree roleId={selectedRole} />
          )}
        </>
      )}
    </CustomDialog>
  );
}

export default ContentPermissionsDialog;
