import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect, useState } from 'react';
import Layout from './layouts/Layout';
import Home from './views/Home';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import {
  ApplicationPaths,
  LoginActions,
  LogoutActions,
} from './components/api-authorization/ApiAuthorizationConstants';
import Login from './components/api-authorization/Login';
import Logout from './components/api-authorization/Logout';
import ChangePassword from './components/api-authorization/ChangePassword';
import UserManagement from './views/UserManagement';
import RoleManagement from './views/RoleManagement';
import CategoryTypeManagement from './views/CategoryTypeManagement';
import CreateArticle from './views/CreateArticle';
import Article from './views/Article';
import EditVersion from './views/EditArticle';
import EditDraft from './views/EditDraft';
import CategoryReference from './views/CategoryReference';
import Search from './views/Search';
import authService from './components/api-authorization/AuthorizeService';
import AppIdleTimer from './components/idle-timer/AppIdleTimer';
import GlobalSettingsManagement from './views/GlobalSettingsManagement';
import Imprint from './views/Imprint';
import Privacy from './views/Privacy';

function App(): JSX.Element {
  const { t: translation } = useTranslation();
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const checkAuthenticatedState = async () => {
    const isAuthenticated = await authService.isAuthenticated();
    setAuthenticated(isAuthenticated);
  };

  useLayoutEffect(() => {
    checkAuthenticatedState();
  }, []);

  return (
    <>
      <AppIdleTimer isAuthenticated={authenticated} />
      <Layout>
        <Routes>
          <Route
            path='/'
            element={
              <AuthorizeRoute
                forceLogin
                accessibleWithoutEditMode
                title={translation('home')}>
                <Home />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/user-management'
            element={
              <AuthorizeRoute forceLogin title={translation('userManagement')}>
                <UserManagement />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/role-management'
            element={
              <AuthorizeRoute forceLogin title={translation('roleManagement')}>
                <RoleManagement />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/category-type-management'
            element={
              <AuthorizeRoute forceLogin title={translation('categoryTypes')}>
                <CategoryTypeManagement />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/global-settings'
            element={
              <AuthorizeRoute forceLogin title={translation('globalSettings')}>
                <GlobalSettingsManagement />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/create-article'
            element={
              <AuthorizeRoute forceLogin title={translation('createArticle')}>
                <CreateArticle />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/edit-article'
            element={
              <AuthorizeRoute forceLogin title={translation('editArticle')}>
                <EditVersion />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/edit-draft'
            element={
              <AuthorizeRoute forceLogin title={translation('editDraft')}>
                <EditDraft />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/article'
            element={
              <AuthorizeRoute
                forceLogin
                accessibleWithoutEditMode
                title={translation('article')}>
                <Article />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/category-reference'
            element={
              <AuthorizeRoute
                forceLogin
                accessibleWithoutEditMode
                title={translation('categoryReference')}>
                <CategoryReference />
              </AuthorizeRoute>
            }
          />
          <Route
            path='/search'
            element={
              <AuthorizeRoute
                forceLogin
                accessibleWithoutEditMode
                title={translation('search')}>
                <Search />
              </AuthorizeRoute>
            }
          />
          <Route path='/imprint' element={<Imprint />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
            <Route
              path={LoginActions.Login}
              element={<Login action={LoginActions.Login} />}
            />
            <Route
              path={LoginActions.LoginFailed}
              element={<Login action={LoginActions.LoginFailed} />}
            />
            <Route
              path={LoginActions.LoginCallback}
              element={<Login action={LoginActions.LoginCallback} />}
            />
            <Route
              path={LoginActions.Profile}
              element={<Login action={LoginActions.Profile} />}
            />
            <Route
              path={ApplicationPaths.LogOut}
              element={<Logout action={LogoutActions.Logout} />}
            />
            <Route
              path={LogoutActions.LogoutCallback}
              element={<Logout action={LogoutActions.LogoutCallback} />}
            />
            <Route
              path={LogoutActions.LoggedOut}
              element={<Logout action={LogoutActions.LoggedOut} />}
            />
          </Route>
          <Route
            path={ApplicationPaths.IdentityChangePasswordPath}
            element={
              <ChangePassword
                action={ApplicationPaths.IdentityChangePasswordPath}
              />
            }
          />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
