import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import {
  ArticleReference,
  ExternalReference,
} from '../../../redux/store/api/api';
import { EDIT_MODE_KEY } from '../../../shared/constants';
import AccordionItem from '../../accordion/AccordionItem';
import { ContextAction } from '../../dropdown-menus/types';
import CustomListGroup from '../../lists/CustomListGroup';

interface IExternalReferencesProps {
  setActiveReferenceKey: (key: string) => void;
  activeReferenceKey: string;
  externalReferences: ExternalReference[] | undefined | null;
  setContextReference: (
    reference: ExternalReference | ArticleReference,
  ) => void;
  setDeleteDialogShow: (show: boolean) => void;
  setRenameDialogShow: (show: boolean) => void;
  userCanEditReference: boolean;
}

function ExternalReferences({
  setActiveReferenceKey,
  activeReferenceKey,
  externalReferences,
  setContextReference,
  setDeleteDialogShow,
  setRenameDialogShow,
  userCanEditReference,
}: IExternalReferencesProps) {
  const { t: translation } = useTranslation();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);

  const getContextActions = (reference: ExternalReference): ContextAction[] => {
    const actions: ContextAction[] = [];

    actions.push({
      iconClass: 'icon-umbenennen',
      iconColorClass: 'text-body',
      name: translation('renameReference'),
      onClick: () => {
        setContextReference(reference);
        setRenameDialogShow(true);
      },
    });

    actions.push({
      iconClass: 'icon-trash',
      iconColorClass: 'text-danger',
      name: translation('deleteReference'),
      onClick: () => {
        setContextReference(reference);
        setDeleteDialogShow(true);
      },
    });

    return actions;
  };

  return (
    <div>
      {externalReferences && externalReferences.length > 0 && (
        <AccordionItem
          title={
            <>
              <i
                aria-hidden
                className='icon-externer_link text-primary me-1 fs-5 align-text-bottom'
              />
              {translation('externalReferences')}
            </>
          }
          setActiveKey={setActiveReferenceKey}
          activeKey={activeReferenceKey}
          eventKey='0'>
          <CustomListGroup
            listItems={externalReferences.map((r) => ({
              id: r.id || '',
              iconClass: 'icon-externer_link text-primary',
              content: r.name || '',
              href: r.hyperlink || '',
              openInNewTab: true,
              contextActions:
                editModeIsActive && userCanEditReference
                  ? getContextActions(r)
                  : undefined,
            }))}
          />
        </AccordionItem>
      )}
    </div>
  );
}

export default ExternalReferences;
