import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ChevronDoubleLeft, ChevronDoubleRight } from 'react-bootstrap-icons';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  PostApiSearchArticlesApiResponse,
  usePostApiSearchArticlesMutation,
  PostApiSearchCategoriesApiResponse,
  usePostApiSearchCategoriesMutation,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import CustomCard from '../cards/CustomCard';
import Loader from '../loader/Loader';
import './Search.scss';
import {
  includeVersionsUrlParam,
  searchKeywordUrlParam,
  searchOptionUrlParam,
  selectedCategoriesUrlParam,
  searchResultSortUrlParam,
  searchResultTypeUrlParam,
} from '../../shared/constants';
import SearchArticleResult from './SearchArticleResult';
import { SearchResultSort } from '../../shared/enums';
import SearchCategoryResult from './SearchCategoryResult';
import SearchForm from './SearchForm';
import {
  selectSearchIncludeVersions,
  selectSearchKeyword,
  selectSearchOption,
  selectSelectedCategoryIdsForSearch,
  setSearchIncludeVersions,
  setSearchKeyword,
  setSearchOption,
  setSelectedCategoryIdsForSearch,
} from '../../redux/store/content/slice';
import { SearchOption } from '../../redux/store/content/types';
import { SearchResultType } from './types';

function SearchCards(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchKeywordParam: string =
    searchParams.get(searchKeywordUrlParam) || '';
  const searchResultTypeParam: string =
    searchParams.get(searchResultTypeUrlParam) || 'articles';
  const searchOptionParam: SearchOption =
    SearchOption[
      searchParams.get(searchOptionUrlParam) as keyof typeof SearchOption
    ] || SearchOption.AllWords;
  const includeVersionsParam: boolean =
    searchParams.get(includeVersionsUrlParam) === 'true' || false;
  const selectedCategoriesParamString: string | null = searchParams.get(
    selectedCategoriesUrlParam,
  );
  const selectedCategoriesParam: string[] | undefined =
    selectedCategoriesParamString && selectedCategoriesParamString !== ''
      ? selectedCategoriesParamString.split(',')
      : undefined;
  const searchKeyword = useAppSelector(selectSearchKeyword);
  const selectedCategoryIds = useAppSelector(
    selectSelectedCategoryIdsForSearch,
  );
  const searchOption = useAppSelector(selectSearchOption);
  const includeVersions = useAppSelector(selectSearchIncludeVersions);
  const [searchResultType, setSearchResultType] = useState<SearchResultType>(
    searchResultTypeParam as SearchResultType,
  );

  const [
    searchCategory,
    {
      isError: searchCategoryIsError,
      error: searchCategoryError,
      isLoading: searchCategoryIsLoading,
    },
  ] = usePostApiSearchCategoriesMutation();
  const [searchCategoryResponse, setSearchCategoryResponse] =
    useState<PostApiSearchCategoriesApiResponse | null>(null);
  const [
    searchArticle,
    {
      isError: searchArticlesIsError,
      error: searchArticlesError,
      isLoading: searchArticleIsLoading,
    },
  ] = usePostApiSearchArticlesMutation();
  const [searchArticleResponse, setSearchArticleResponse] =
    useState<PostApiSearchArticlesApiResponse | null>(null);
  const [sortOption, setSortOption] = useState<SearchResultSort>(() => {
    const sortParam = searchParams.get(searchResultSortUrlParam);
    if (sortParam) {
      return +sortParam as SearchResultSort;
    }
    return SearchResultSort.ByRelevance;
  });

  const itemsPerPage = 25;
  const [startOffset, setStartOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const startSearch = () => {
    if (
      searchKeyword.trim() !== '' &&
      searchKeywordUrlParam !== searchKeyword.trim()
    ) {
      searchParams.set(searchKeywordUrlParam, searchKeyword.trim());
    }

    searchParams.set(searchOptionUrlParam, SearchOption[searchOption]);
    searchParams.set(includeVersionsUrlParam, includeVersions.toString());
    searchParams.set(searchResultSortUrlParam, sortOption.toString());
    searchParams.set(searchResultTypeUrlParam, searchResultType);

    if (selectedCategoryIds.length > 0) {
      searchParams.set(
        selectedCategoriesUrlParam,
        selectedCategoryIds.toString(),
      );
    }

    setSearchParams(searchParams);

    if (searchResultType === 'articles') {
      searchArticle({
        searchAttributes: {
          searchString: searchKeyword,
          searchExact: searchOption === SearchOption.ExactExpression,
          includeAttachments: true,
          searchResultSort: sortOption,
          selectedCategoryIds: selectedCategoryIds || selectedCategoriesParam,
          includeVersions,
          startOffset,
          itemsPerPage,
        },
      })
        .unwrap()
        .then((response) => setSearchArticleResponse(response));
    } else {
      searchCategory({
        searchAttributes: {
          searchString: searchKeywordParam,
          selectedCategoryIds,
          includeVersions,
        },
      })
        .unwrap()
        .then((response) => setSearchCategoryResponse(response));
    }
  };

  const handleSortChange = useCallback((option: SearchResultSort) => {
    setSortOption(option);
    setStartOffset(0);
  }, []);

  useEffect(() => {
    dispatch(setSearchKeyword(searchKeywordParam));
    dispatch(setSelectedCategoryIdsForSearch(selectedCategoriesParam || []));
    dispatch(setSearchOption(searchOptionParam));
    dispatch(setSearchIncludeVersions(includeVersionsParam));
    setSearchResultType(searchResultTypeParam as SearchResultType);
  }, []);

  useEffect(() => {
    if (searchArticlesIsError) {
      dispatch(
        addMessage({
          id: 'SearchArticlesError',
          variant: 'danger',
          messageKeyBody:
            searchArticlesError && 'data' in searchArticlesError
              ? searchArticlesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (searchCategoryIsError) {
      dispatch(
        addMessage({
          id: 'SearchCategoriesError',
          variant: 'danger',
          messageKeyBody:
            searchCategoryError && 'data' in searchCategoryError
              ? searchCategoryError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [searchArticlesIsError, searchCategoryIsError]);

  useEffect(() => {
    if (!searchArticleIsLoading && !searchCategoryIsLoading) {
      startSearch();
    }
  }, [
    sortOption,
    startOffset,
    searchKeywordParam,
    selectedCategoriesParamString,
    searchOptionParam,
    includeVersionsParam,
    searchResultType,
  ]);

  useEffect(() => {
    if (
      searchArticleResponse?.resultObject?.resultCount &&
      searchArticleResponse.resultObject.resultCount > 0
    ) {
      setPageCount(
        Math.ceil(
          (searchArticleResponse.resultObject?.resultCount || 0) / itemsPerPage,
        ),
      );
    } else {
      setPageCount(0);
    }
  }, [searchArticleResponse]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newStartOffset =
      (event.selected * itemsPerPage) %
      (searchArticleResponse?.resultObject?.resultCount || 0);
    setStartOffset(newStartOffset);
  };

  return (
    <>
      <div className='search-filter-card-container'>
        <CustomCard>
          <SearchForm
            startOffset={startOffset}
            setStartOffset={setStartOffset}
            startSearch={startSearch}
          />
        </CustomCard>
      </div>
      {(searchArticleIsLoading ||
        searchCategoryIsLoading ||
        searchCategoryResponse?.resultObject ||
        searchArticleResponse?.resultObject) && (
        <Tabs
          className='search-tabs mt-5'
          activeKey={searchResultType}
          onSelect={(k) => {
            setSearchResultType(k as SearchResultType);
            searchParams.set(searchResultTypeUrlParam, searchResultType);
          }}>
          <Tab
            className='search-result-container'
            eventKey='articles'
            title={translation('articles')}
            tabAttrs={{
              className: `mb-2 shadow-sm btn-outline-dark ${
                searchResultType === 'articles' ? 'active' : ''
              }`,
            }}>
            <CustomCard
              helpId='help_4_7'
              iconClass='icon-search'
              title={
                !searchArticleIsLoading
                  ? `${
                      searchArticleResponse?.resultObject?.resultCount || '0'
                    } ${translation('searchResults')}`
                  : ''
              }>
              <div aria-busy={searchArticleIsLoading}>
                {searchArticleIsLoading && <Loader />}
                {!searchArticleIsLoading &&
                  searchArticleResponse?.resultObject &&
                  (searchArticleResponse.resultObject?.resultCount || 0) >
                    0 && (
                    <SearchArticleResult
                      searchResult={searchArticleResponse.resultObject}
                      onSortChange={handleSortChange}
                      sortOption={sortOption}
                    />
                  )}
              </div>
            </CustomCard>
            {!searchArticleIsLoading &&
              (searchArticleResponse?.resultObject?.resultCount || 0) > 0 && (
                <ReactPaginate
                  nextLabel={<ChevronDoubleRight aria-hidden />}
                  nextAriaLabel={translation('nextPage')}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={10}
                  marginPagesDisplayed={3}
                  pageCount={pageCount}
                  previousLabel={<ChevronDoubleLeft aria-hidden />}
                  previousAriaLabel={translation('previousPage')}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination mt-3 justify-content-center'
                  activeClassName='active'
                  initialPage={startOffset / itemsPerPage}
                  ariaLabelBuilder={(pageIndex, selectedPage) =>
                    `${translation('page')} ${pageIndex}${
                      selectedPage
                        ? `. ${translation('thisIsCurrentPage')}`
                        : ''
                    }`
                  }
                />
              )}
          </Tab>
          <Tab
            className='search-result-container'
            eventKey='categories'
            title={translation('categories')}
            tabAttrs={{
              className: `mb-2 shadow-sm btn-outline-dark ${
                searchResultType === 'categories' ? 'active' : ''
              }`,
            }}>
            <CustomCard
              helpId='help_4_7'
              title={
                !searchCategoryIsLoading && searchCategoryResponse?.resultObject
                  ? `${searchCategoryResponse.resultObject
                      ?.resultCount} ${translation('searchResults')}`
                  : ''
              }>
              <div aria-busy={searchCategoryIsLoading}>
                {searchCategoryIsLoading && <Loader />}
                {!searchCategoryIsLoading &&
                  searchCategoryResponse?.resultObject && (
                    <SearchCategoryResult
                      searchCategoryResult={
                        searchCategoryResponse.resultObject?.items ?? []
                      }
                      foundTerms={
                        searchCategoryResponse.resultObject?.foundTerms ?? []
                      }
                    />
                  )}
              </div>
            </CustomCard>
          </Tab>
        </Tabs>
      )}
    </>
  );
}

export default SearchCards;
