import { coreModuleName } from '@reduxjs/toolkit/dist/query/core/module';
import { reactHooksModuleName } from '@reduxjs/toolkit/dist/query/react/module';
import {
  Api,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import authService from '../../../components/api-authorization/AuthorizeService';

type CustomErrorData = {
  messageKey: string;
  resultObject?: { [key: string]: string };
  statusCode: number;
};

function getBaseApi(): Api<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    {
      status: 'CUSTOM_ERROR';
      data?: CustomErrorData;
      error: string;
    },
    {},
    FetchBaseQueryMeta
  >,
  {},
  'api',
  never,
  typeof coreModuleName | typeof reactHooksModuleName
> {
  // https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#setting-default-headers-on-requests
  // https://docs.microsoft.com/en-us/aspnet/core/security/authentication/identity-api-authorization?view=aspnetcore-6.0#retrieve-and-attach-the-access-token-to-the-response
  return createApi({
    baseQuery: fetchBaseQuery({
      baseUrl: '/',
      prepareHeaders: async (headers) => {
        const token = await authService.getAccessToken();

        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
      },
    }),
    endpoints: () => ({}),
  });
}

// Cannot modify baseApi.ts to use default fix from eslist suggestion.
// eslint-disable-next-line import/prefer-default-export
export const baseApi = getBaseApi();
