import { ListGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { SyntheticEvent } from 'react';
import { ContextAction } from '../dropdown-menus/types';
import ContextMenu from '../dropdown-menus/ContextMenu';
import './Lists.scss';
import DescriptiveIcon from '../descriptive-icons/DescriptiveIcon';
import { checkClickOnContextMenu } from '../../shared/utils';

export type ListItem = {
  id: string;
  content: string | React.ReactNode;
  onClick?: (e: SyntheticEvent) => void;
  contextActions?: ContextAction[];
  onlyOneActionAsButton?: boolean;
  iconClass?: string;
  iconColorClass?: string;
  iconColorHexCode?: string;
  iconDescription?: string;
  activeInfo?: string;
  href?: string;
  openInNewTab?: boolean;
};

interface ICustomListGroupProps {
  listItems: ListItem[];
  activeListItem?: string;
}

function CustomListGroup({
  listItems,
  activeListItem,
}: ICustomListGroupProps): JSX.Element {
  const navigate = useNavigate();

  const getListItemContent = (
    iconClass: string | undefined,
    iconColor: string | undefined,
    iconColorHexCode: string | undefined,
    description: string | undefined,
    content: string | React.ReactNode,
  ) => (
    <span className={iconClass ? 'd-flex' : undefined}>
      {iconClass &&
        (description ? (
          <DescriptiveIcon
            iconClass={iconClass}
            colorClass={iconColor}
            colorHexCode={iconColorHexCode}
            description={description}
          />
        ) : (
          <i
            aria-hidden
            className={`me-2 ${iconClass} ${iconColor || ''}`}
            style={iconColorHexCode ? { color: iconColorHexCode } : undefined}
          />
        ))}
      <span className='text-break li-content'>{content}</span>
    </span>
  );

  const getLinkElement = (item: ListItem) => (
    <>
      {item.openInNewTab && (
        <a
          className='text-body text-decoration-none'
          href={item.href}
          target='_blank'
          rel='noopener noreferrer'>
          {getListItemContent(
            item.iconClass,
            item.iconColorClass,
            item.iconColorHexCode,
            item.iconDescription,
            item.content,
          )}
        </a>
      )}
      {!item.openInNewTab && (
        <Link
          className='text-body text-decoration-none'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate(item.href || '/', {
                replace: true,
              });
            }
          }}
          to={item.href || ''}>
          {getListItemContent(
            item.iconClass,
            item.iconColorClass,
            item.iconColorHexCode,
            item.iconDescription,
            item.content,
          )}
        </Link>
      )}
    </>
  );

  return (
    <ListGroup as='ul'>
      {listItems.map((item) => {
        const onClickFn =
          typeof item.onClick !== 'undefined'
            ? item.onClick
            : () => {
                // no onClick function
              };
        return (
          <ListGroup.Item
            as='li'
            tabIndex={item.onClick || item.href ? 0 : undefined}
            aria-current={item.id === activeListItem}
            className={`${item.contextActions ? 'd-flex ' : ''}${
              item.id === activeListItem ? 'active-li-element' : ''
            } ${item.onClick || item.href ? 'cursor-pointer' : ''}`}
            key={item.id}
            onKeyDown={(e) => {
              if (checkClickOnContextMenu(e)) {
                return;
              }

              if (e.key === 'Enter') {
                onClickFn(e);
              }
            }}
            onClick={(e) => {
              if (checkClickOnContextMenu(e)) {
                return;
              }

              onClickFn(e);

              if (item.href && item.openInNewTab) {
                window.open(item.href, '_blank', 'noopener,noreferrer');
              }

              if (item.href && !item.openInNewTab) {
                navigate(item.href, {
                  replace: true,
                });
              }
            }}>
            {item.href && getLinkElement(item)}
            {!item.href &&
              getListItemContent(
                item.iconClass,
                item.iconColorClass,
                item.iconColorHexCode,
                item.iconDescription,
                item.content,
              )}
            {item.contextActions && (
              <ContextMenu
                contextActions={item.contextActions}
                onlyOneActionAsButton={item.onlyOneActionAsButton}
              />
            )}
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

CustomListGroup.defaultProps = { activeListItem: undefined };

export default CustomListGroup;
