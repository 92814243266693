import { Mark, SourceContentArea } from '../../../redux/store/api/api';
import {
  CALCULATION_CONTENT_CONTAINER_ID,
  VERSION_CONTENT_CONTAINER_ID,
} from '../../../shared/constants';
import {
  getNodeIgnoreFiberNodes,
  isFiberNode,
  isMarkOrLink,
  isTextNode,
} from './nodes';

const additionalCharacterLength = 10;

const calculateOffset = (node: Node, offset: number): number => {
  const prevSibling = getNodeIgnoreFiberNodes(node, 'previousSibling');
  const parent = getNodeIgnoreFiberNodes(node, 'parentNode');

  if (parent && isMarkOrLink(parent)) {
    return calculateOffset(parent, offset);
  }

  if (
    !prevSibling ||
    (!isMarkOrLink(prevSibling) && !isTextNode(prevSibling))
  ) {
    return offset;
  }

  const nodeContentLength = prevSibling.textContent?.length || 0;
  const newOffset = offset + nodeContentLength;

  return calculateOffset(prevSibling, newOffset);
};

const findPreviousSiblings = (node: Node): number => {
  const previousSibling = getNodeIgnoreFiberNodes(node, 'previousSibling');

  if (previousSibling === null) {
    return 1;
  }

  if (
    (isMarkOrLink(node) || isTextNode(node) || isFiberNode(node)) &&
    (isMarkOrLink(previousSibling) || isTextNode(previousSibling))
  ) {
    return findPreviousSiblings(previousSibling);
  }

  return 1 + findPreviousSiblings(previousSibling);
};

// nodePath: Array of numbers
// Each number represents how much siblings a node has to the left
// Each index represents a level in the DOM
const findPath = (
  node: Node,
  nodePath: number[],
  contentContainerId: string,
  doc: Document,
): number[] => {
  if (isFiberNode(node) || isMarkOrLink(node)) {
    if (node.parentElement) {
      return findPath(node.parentElement, nodePath, contentContainerId, doc);
    }

    return nodePath;
  }

  const rootEl: Element | null | undefined =
    doc.getElementById(contentContainerId);
  const siblingsOnLevel = findPreviousSiblings(node);
  nodePath.push(siblingsOnLevel);

  if (node.parentElement !== rootEl && node.parentElement) {
    return findPath(node.parentElement, nodePath, contentContainerId, doc);
  }

  return nodePath;
};

const getStartNodeContainerId = (element: HTMLElement | null): string => {
  if (
    !element ||
    element.id === VERSION_CONTENT_CONTAINER_ID ||
    element.id === CALCULATION_CONTENT_CONTAINER_ID
  ) {
    return '';
  }
  if (element.nodeName === 'A' || element.nodeName === 'SPAN') {
    return getStartNodeContainerId(element.parentElement);
  }
  if (element.id) {
    return element.id;
  }
  return getStartNodeContainerId(element.closest('[id]'));
};

const getPrecedingCharacters = (range: Range): string => {
  const newStartOffset =
    range.startOffset - additionalCharacterLength >= 0
      ? range.startOffset - additionalCharacterLength
      : 0;
  range.setEnd(range.startContainer, range.startOffset);
  range.setStart(range.startContainer, newStartOffset);
  return range.toString();
};

const getSubsequentCharacters = (range: Range): string => {
  const endNodeLength = range.endContainer.nodeValue?.length ?? 0;
  const newEndOffset =
    range.endOffset + additionalCharacterLength <= endNodeLength
      ? range.endOffset + additionalCharacterLength
      : endNodeLength;
  range.setStart(range.endContainer, range.endOffset);
  range.setEnd(range.endContainer, newEndOffset);
  return range.toString();
};

const convertFromRange = (
  range: Range,
  contentContainerId: string,
  doc: Document,
): Mark | SourceContentArea => {
  const startNodeContainerId = getStartNodeContainerId(
    range.startContainer.parentElement,
  );
  const precedingCharacters = getPrecedingCharacters(range.cloneRange());
  const subsequentCharacters = getSubsequentCharacters(range.cloneRange());
  const startOffset = calculateOffset(range.startContainer, range.startOffset);
  const endOffset = calculateOffset(range.endContainer, range.endOffset);
  const startPath: number[] = findPath(
    range.startContainer,
    [],
    contentContainerId,
    doc,
  );
  const endPath: number[] = findPath(
    range.endContainer,
    [],
    contentContainerId,
    doc,
  );

  return {
    startNodeContainerId,
    precedingCharacters,
    subsequentCharacters,
    patternString: range?.toString(),
    startNodeData: `{"nodePath": [${startPath}], "offset": ${startOffset}}`,
    endNodeData: `{"nodePath": [${endPath}], "offset": ${endOffset}}`,
  };
};

export default convertFromRange;
