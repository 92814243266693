import { useTranslation } from 'react-i18next';
import CustomDialog from '../../dialogs/CustomDialog';

interface IVersionSaveDialogProps {
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
  saveAsNewVersion: boolean;
  handleUpdateVersion: () => void;
  handleAddVersion: () => void;
}
function VersionSaveDialog({
  dialogShow,
  setDialogShow,
  saveAsNewVersion,
  handleAddVersion,
  handleUpdateVersion,
}: IVersionSaveDialogProps) {
  const { t: translation } = useTranslation();

  const handleSaveVersion = (): void => {
    if (saveAsNewVersion) {
      handleAddVersion();
      setDialogShow(false);
    } else {
      handleUpdateVersion();
      setDialogShow(false);
    }
  };

  return (
    <CustomDialog
      titleId='VersionSaveDialog'
      show={dialogShow}
      closeFunction={() => {
        setDialogShow(false);
      }}
      closeTitle={translation('cancel')}
      dialogTitle={translation(
        saveAsNewVersion ? 'saveNewVersion' : 'overwriteVersion',
      )}
      actionTitle={translation(saveAsNewVersion ? 'save' : 'overwrite')}
      actionFunction={handleSaveVersion}>
      {saveAsNewVersion && (
        <p>{translation('shouldContentSaveAsNewVersion')}</p>
      )}
      {!saveAsNewVersion && <p>{translation('shouldVersionBeOverwrite')}</p>}
    </CustomDialog>
  );
}

export default VersionSaveDialog;
