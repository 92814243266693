import { SyntheticEvent, useEffect } from 'react';
import { decode } from 'html-entities';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useDeleteApiPermissionsByIdMutation,
  useGetApiPermissionsQuery,
  usePostApiPermissionsMutation,
  usePutApiPermissionsByIdMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { selectContextUserGroup } from '../../../redux/store/user-management/slice';
import { paddingOffsetForTreeElements } from '../../../shared/constants';
import { RightKey } from '../../../shared/enums';
import { ICategoryTreeItem } from '../../content-tree/types';
import TriStateCheckbox from '../../forms/TriStateCheckbox';

interface IContentPermissionsTreeItemProps {
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  element: ICategoryTreeItem;
  getNodeProps: Function;
  isSelected: boolean;
  isHalfSelected: boolean;
  handleExpand: Function;
  roleId: string;
  permissionId: string | null;
  lastClickedElementId: string | null;
  setLastClickedElementId: (elementId: string) => void;
}

function ContentPermissionsTreeItem({
  level,
  isBranch,
  isExpanded,
  element,
  getNodeProps,
  isSelected,
  isHalfSelected,
  handleExpand,
  roleId,
  permissionId,
  lastClickedElementId,
  setLastClickedElementId,
}: IContentPermissionsTreeItemProps) {
  const dispatch = useAppDispatch();
  const userGroup = useAppSelector(selectContextUserGroup);
  const [
    addPermission,
    { isError: addPermissionIsError, error: addPermissionError },
  ] = usePostApiPermissionsMutation();
  const [
    changeRecursive,
    { isError: changeRecursiveIsError, error: changeRecursiveError },
  ] = usePutApiPermissionsByIdMutation();
  const [
    deletePermission,
    { isError: deletePermissionIsError, error: deletePermissionError },
  ] = useDeleteApiPermissionsByIdMutation();
  const { refetch } = useGetApiPermissionsQuery({
    roleId,
    userGroupId: userGroup.id || '',
  });

  // permissions
  const userCanAddPermission = userGroup.permittedActions?.includes(
    RightKey.RightPermissionManagementCreate,
  );
  const userCanDeletePermission = userGroup.permittedActions?.includes(
    RightKey.RightPermissionManagementDelete,
  );

  useEffect(() => {
    if (addPermissionIsError) {
      dispatch(
        addMessage({
          id: 'AddPermissionError',
          variant: 'danger',
          messageKeyBody:
            addPermissionError && 'data' in addPermissionError
              ? addPermissionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (changeRecursiveIsError) {
      dispatch(
        addMessage({
          id: 'ChangeRecursiveError',
          variant: 'danger',
          messageKeyBody:
            changeRecursiveError && 'data' in changeRecursiveError
              ? changeRecursiveError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (deletePermissionIsError) {
      dispatch(
        addMessage({
          id: 'DeletePermissionError',
          variant: 'danger',
          messageKeyBody:
            deletePermissionError && 'data' in deletePermissionError
              ? deletePermissionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [addPermissionIsError, changeRecursiveIsError, deletePermissionIsError]);

  useEffect(() => {
    if (lastClickedElementId) {
      document.getElementById(lastClickedElementId)?.focus();
    }
  }, [lastClickedElementId]);

  const handleAddPermission = () => {
    addPermission({
      permission: {
        roleId,
        userGroupId: userGroup.id || '',
        targetId: element.id,
        recursiveInheritanceEnabled: false,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'AddPermissionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        setLastClickedElementId(element.id || '');
      });
  };

  const handleUpdatePermission = () => {
    changeRecursive({
      id: permissionId || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdatePermissionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        setLastClickedElementId(element.id || '');
      });
  };

  const handleDeletePermission = () => {
    deletePermission({
      id: permissionId || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeletePermissionSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        setLastClickedElementId(element.id || '');
      });
  };

  return (
    <div className='li-inner'>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getNodeProps({
          onClick: (e: SyntheticEvent) => {
            if (
              (e.target as HTMLElement).classList.contains('form-check-input')
            ) {
              return;
            }

            handleExpand(e);
          },
        })}
        style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
        <div className='d-flex'>
          {isBranch && isExpanded && (
            <i className='me-2 icon-carretup' aria-hidden />
          )}
          {isBranch && !isExpanded && (
            <i className='me-2 icon-carretdown' aria-hidden />
          )}
          <TriStateCheckbox
            id={element.id}
            onChange={(checked) => {
              if (checked === 'mixed') {
                if (userCanAddPermission) {
                  handleAddPermission();
                }
                return;
              }
              if (checked) {
                if (userCanAddPermission) {
                  handleUpdatePermission();
                }
                return;
              }
              if (!checked && userCanDeletePermission) {
                handleDeletePermission();
              }
            }}
            label={decode(element.name)}
            iconClass={element.categoryTypeIconClass}
            iconColor={element.categoryTypeColorHexCode}
            checked={
              isSelected ||
              element.isRecursivePermitted ||
              (isHalfSelected ? 'mixed' : false)
            }
            disabled={element.isRecursivePermitted && permissionId === null}
          />
        </div>
      </div>
    </div>
  );
}

export default ContentPermissionsTreeItem;
