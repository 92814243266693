import { useEffect, useMemo, useState } from 'react';
import TreeView from 'react-accessible-treeview';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  Article,
  Category,
  useGetApiRecycleBinQuery,
} from '../../redux/store/api/api';
import {
  addExpandedRecycleBinCategory,
  removeExpandedRecycleBinCategory,
  selectExpandedRecycleBinCategories,
} from '../../redux/store/content/slice';
import { addMessage } from '../../redux/store/layout/slice';
import { getTreeItems } from '../content-tree/functions';
import {
  ContentTreeItems,
  IArticleTreeItem,
  ICategoryTreeItem,
} from '../content-tree/types';
import Loader from '../loader/Loader';
import DeleteDialog from './dialogs/DeleteDialog';
import RecycleBinTreeItem from './RecycleBinTreeItem';
import RestoreDialog from './dialogs/RestoreDialog';
import './RecycleBin.scss';
import IconButtonWithText from '../buttons/IconButtonWithText';
import EmptyRecycleBinDialog from './dialogs/EmptyRecycleBinDialog';
import {
  convertArticleTreeItemToArticle,
  convertCategoryTreeItemToCategory,
} from '../../shared/utils';

function RecycleBin() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [deleteArticleDialogShow, setDeleteArticleDialogShow] = useState(false);
  const [restoreDialogShow, setRestoreDialogShow] = useState(false);
  const [emptyRecycleBinDialogShow, setEmptyRecycleBinDialogShow] =
    useState(false);
  const [selectedItems, setSelectedItems] = useState<(Article | Category)[]>(
    [],
  );
  const expandedCategories = useAppSelector(selectExpandedRecycleBinCategories);
  const {
    data: recycleBinData,
    isError: getRecycleBinDataIsError,
    isFetching: getRecycleBinDataIsFetching,
    error,
  } = useGetApiRecycleBinQuery();

  const treeData = useMemo<ContentTreeItems>(() => {
    if (recycleBinData?.resultObject) {
      return getTreeItems(recycleBinData.resultObject);
    }
    return [];
  }, [recycleBinData]);

  useEffect(() => {
    if (getRecycleBinDataIsError) {
      dispatch(
        addMessage({
          id: 'GetRecycleBinDataError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [getRecycleBinDataIsError]);

  return (
    <>
      <div aria-busy={getRecycleBinDataIsFetching}>
        {getRecycleBinDataIsFetching && <Loader />}
        {!getRecycleBinDataIsFetching && recycleBinData?.resultObject && (
          <>
            {treeData.length > 1 && (
              <>
                <span className='float-end'>
                  <IconButtonWithText
                    variant='outline-danger'
                    iconClassName='icon-trash'
                    label={translation('emptyRecycleBin')}
                    onClick={() => {
                      setEmptyRecycleBinDialogShow(true);
                    }}
                  />
                </span>
                <TreeView
                  id='RecycleBinTree'
                  multiSelect
                  togglableSelect
                  expandedIds={expandedCategories.filter(
                    (e) =>
                      !!recycleBinData.resultObject?.categories?.find(
                        (c) => c.id === e,
                      ),
                  )}
                  defaultSelectedIds={selectedItems
                    .map((s) => s.id || '')
                    .filter(
                      (s) =>
                        !!recycleBinData.resultObject?.categories?.find(
                          (c) => c.id === s,
                        ),
                    )}
                  data={treeData}
                  onSelect={(e) => {
                    const selectedTreeElements = treeData.filter((t) =>
                      Array.from(e.treeState.selectedIds).includes(t.id),
                    );

                    const selectedElements: (Article | Category)[] =
                      selectedTreeElements.map((s) => {
                        if ('recentVersionId' in s) {
                          return convertArticleTreeItemToArticle(s);
                        }
                        return convertCategoryTreeItemToCategory(s);
                      });

                    setSelectedItems(selectedElements);
                  }}
                  onExpand={(e) => {
                    const { id } = e.element as ICategoryTreeItem;
                    if (e.isExpanded) {
                      dispatch(addExpandedRecycleBinCategory(id));
                    } else {
                      dispatch(removeExpandedRecycleBinCategory([id]));
                    }
                  }}
                  nodeRenderer={({
                    element,
                    getNodeProps,
                    level,
                    isBranch,
                    isExpanded,
                    handleSelect,
                    handleExpand,
                    isSelected,
                  }) => {
                    const convertedElement = element as
                      | IArticleTreeItem
                      | ICategoryTreeItem;
                    return RecycleBinTreeItem({
                      level,
                      isBranch,
                      isExpanded,
                      getNodeProps,
                      setDeleteArticleDialogShow,
                      setRestoreDialogShow,
                      selectedItems,
                      handleSelect,
                      handleExpand,
                      isSelected,
                      element: convertedElement,
                    });
                  }}
                />
              </>
            )}
            {treeData.length <= 1 && <p>{translation('recycleBinIsEmpty')}</p>}
          </>
        )}
      </div>
      <DeleteDialog
        dialogShow={deleteArticleDialogShow}
        setDialogShow={setDeleteArticleDialogShow}
        itemsToDelete={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      <RestoreDialog
        dialogShow={restoreDialogShow}
        setDialogShow={setRestoreDialogShow}
        itemsToRestore={selectedItems}
        setSelectedItems={setSelectedItems}
      />
      <EmptyRecycleBinDialog
        dialogShow={emptyRecycleBinDialogShow}
        setDialogShow={setEmptyRecycleBinDialogShow}
        setSelectedItems={setSelectedItems}
      />
    </>
  );
}

export default RecycleBin;
