import { useEffect, useMemo, useState } from 'react';
import TreeView from 'react-accessible-treeview';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useGetApiBookmarkFoldersQuery } from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import Loader from '../loader/Loader';
import BookmarkTreeItem from './BookmarkTreeItem';
import { getCompleteBookmarkTreeItems } from './functions';
import {
  BookmarkTreeItems,
  IBookmarkFolderTreeItem,
  IBookmarkTreeItem,
  MovingBookmark,
  MovingBookmarkFolder,
} from './types';
import RenameBookmarkDialog from './dialog/RenameBookmarkDialog';
import MoveBookmarkItemDialog from './dialog/MoveBookmarkItemDialog';
import {
  addExpandedBookmarkFolderId,
  removeExpandedBookmarkFolderId,
  selectExpandedBookmarkFolderIds,
} from '../../redux/store/content/slice';

function BookmarksTree(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const expandedBookmarkFolders = useAppSelector(
    selectExpandedBookmarkFolderIds,
  );
  const [renameBookmarkDialogOpened, setRenameBookmarkDialogOpened] =
    useState(false);
  const [moveDialogOpened, setMoveDialogOpened] = useState(false);
  const [moveBookmarkIsActive, setMoveBookmarkIsActive] = useState(false);
  const [movingBookmark, setMovingBookmark] = useState<MovingBookmark>({
    bookmark: {},
  });
  const [moveBookmarkFolderIsActive, setMoveBookmarkFolderIsActive] =
    useState(false);
  const [movingBookmarkFolder, setMovingBookmarkFolder] =
    useState<MovingBookmarkFolder>({ bookmarkFolder: {} });

  const {
    data: bookmarkTreeData,
    isFetching,
    isError,
    error,
  } = useGetApiBookmarkFoldersQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetBookmarkFoldersError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const treeData = useMemo<BookmarkTreeItems>(() => {
    if (bookmarkTreeData?.resultObject) {
      return getCompleteBookmarkTreeItems(bookmarkTreeData?.resultObject);
    }
    return [];
  }, [bookmarkTreeData]);

  return (
    <>
      {(moveBookmarkIsActive || moveBookmarkFolderIsActive) && (
        <Alert variant='warning'>
          {moveBookmarkIsActive && (
            <>
              <p className='mt-1'>
                {translation('followingBookmarkIsSelectedForMoving')}
              </p>
              <p className='fw-bold mb-5'>{movingBookmark.bookmark.name}</p>
              <Button
                className='position-absolute m-2 end-0 bottom-0'
                onClick={() => {
                  setMoveBookmarkIsActive(false);
                }}
                variant='outline-dark'>
                {translation('cancelMove')}
              </Button>
            </>
          )}
          {moveBookmarkFolderIsActive && (
            <>
              <p className='mt-1'>
                {translation('followingBookmarkFolderIsSelectedForMoving')}
              </p>
              <p className='fw-bold mb-5'>
                {movingBookmarkFolder.bookmarkFolder.name}
              </p>
              <div className='position-absolute m-2 end-0 bottom-0'>
                <Button
                  onClick={() => setMoveBookmarkFolderIsActive(false)}
                  variant='outline-dark'>
                  {translation('cancelMove')}
                </Button>
              </div>
            </>
          )}
        </Alert>
      )}
      <div aria-busy={isFetching}>
        {isFetching && <Loader />}
        {!isFetching && bookmarkTreeData && treeData.length > 1 && (
          <TreeView
            expandedIds={expandedBookmarkFolders}
            data={treeData}
            onExpand={(e) => {
              const { id } = e.element as IBookmarkFolderTreeItem;
              if (e.isExpanded) {
                dispatch(addExpandedBookmarkFolderId(id));
              } else {
                dispatch(removeExpandedBookmarkFolderId(id));
              }
            }}
            nodeRenderer={({
              element,
              getNodeProps,
              handleExpand,
              level,
              isExpanded,
              isBranch,
            }) =>
              BookmarkTreeItem({
                setMoveDialogOpened,
                movingBookmark,
                setMovingBookmark,
                movingBookmarkFolder,
                setMovingBookmarkFolder,
                moveBookmarkIsActive,
                setMoveBookmarkIsActive,
                moveBookmarkFolderIsActive,
                setMoveBookmarkFolderIsActive,
                setRenameBookmarkDialogOpened,
                level,
                isBranch,
                isExpanded,
                handleExpand,
                getNodeProps,
                element: element as IBookmarkFolderTreeItem | IBookmarkTreeItem,
              })
            }
          />
        )}
      </div>
      <RenameBookmarkDialog
        dialogShow={renameBookmarkDialogOpened}
        setDialogShow={setRenameBookmarkDialogOpened}
      />
      <MoveBookmarkItemDialog
        dialogShow={moveDialogOpened}
        setDialogShow={setMoveDialogOpened}
        movingBookmark={movingBookmark}
        movingBookmarkFolder={movingBookmarkFolder}
        moveBookmarkIsActive={moveBookmarkIsActive}
        moveBookmarkFolderIsActive={moveBookmarkFolderIsActive}
        setMoveBookmarkFolderIsActive={setMoveBookmarkFolderIsActive}
        setMoveBookmarkIsActive={setMoveBookmarkIsActive}
      />
    </>
  );
}

export default BookmarksTree;
