import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useDeleteApiNotesByIdMutation,
  useGetApiBookmarkFoldersQuery,
  useGetApiNotesByVersionIdQuery,
} from '../../../redux/store/api/api';
import {
  selectActiveNote,
  selectActiveVersion,
  setActiveNote,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

interface IDeleteNoteDialogProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

function DeleteNoteDialog({
  show,
  setShow,
}: IDeleteNoteDialogProps): JSX.Element {
  const { t: translation } = useTranslation();
  const note = useAppSelector(selectActiveNote);
  const version = useAppSelector(selectActiveVersion);
  const [deleteNote, { isError: deleteNoteIsError, isLoading, error }] =
    useDeleteApiNotesByIdMutation();
  const { refetch: refetchNotes } = useGetApiNotesByVersionIdQuery(
    version.id
      ? {
          versionId: version.id,
        }
      : skipToken,
  );
  const { refetch: refetchBookmarkTree } = useGetApiBookmarkFoldersQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (deleteNoteIsError) {
      dispatch(
        addMessage({
          id: 'DeleteNoteError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [deleteNoteIsError]);

  const handleDeleteNote = (): void => {
    deleteNote({ id: note.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteNoteSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setShow(false);
        if (note.mark) {
          refetchBookmarkTree();
        }
        refetchNotes();
      });
  };

  return (
    <CustomDialog
      titleId='DeleteNoteDialog'
      dialogTitle={translation('deleteNote')}
      show={show}
      closeFunction={() => {
        setShow(false);
        dispatch(setActiveNote({}));
      }}
      actionFunction={handleDeleteNote}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {`${translation('deleteNotePermanently')} ${
            note.mark ? translation('deletionIncludesNote') : ''
          }`}
          <p className='fw-bold'>{note.name}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteNoteDialog;
