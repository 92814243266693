import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import { MovingBookmark, MovingBookmarkFolder } from '../types';
import {
  useGetApiBookmarkFoldersQuery,
  usePutApiBookmarkFoldersMoveByIdMutation,
  usePutApiBookmarksMoveByIdMutation,
} from '../../../redux/store/api/api';
import { useAppDispatch } from '../../../redux/hooks';
import { addMessage } from '../../../redux/store/layout/slice';
import { addExpandedBookmarkFolderId } from '../../../redux/store/content/slice';

interface IMoveBookmarkItemDialogProps {
  movingBookmark: MovingBookmark;
  movingBookmarkFolder: MovingBookmarkFolder;
  moveBookmarkIsActive: boolean;
  setMoveBookmarkIsActive: (moveIsActive: boolean) => void;
  moveBookmarkFolderIsActive: boolean;
  setMoveBookmarkFolderIsActive: (moveIsActive: boolean) => void;
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
}

function MoveBookmarkItemDialog({
  movingBookmark,
  movingBookmarkFolder,
  moveBookmarkIsActive,
  setMoveBookmarkIsActive,
  moveBookmarkFolderIsActive,
  setMoveBookmarkFolderIsActive,
  dialogShow,
  setDialogShow,
}: IMoveBookmarkItemDialogProps) {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const [
    moveBookmarkFolder,
    {
      isLoading: moveBookmarkFolderIsLoading,
      isError: moveBookmarkFolderIsError,
      error: moveBookmarkFolderError,
    },
  ] = usePutApiBookmarkFoldersMoveByIdMutation();
  const [
    moveBookmark,
    {
      isLoading: moveBookmarkIsLoading,
      isError: moveBookmarkIsError,
      error: moveBookmarkError,
    },
  ] = usePutApiBookmarksMoveByIdMutation();
  const { refetch } = useGetApiBookmarkFoldersQuery();

  useEffect(() => {
    if (moveBookmarkFolderIsError) {
      dispatch(
        addMessage({
          id: 'MoveBookmarkFolderError',
          variant: 'danger',
          messageKeyBody:
            moveBookmarkFolderError && 'data' in moveBookmarkFolderError
              ? moveBookmarkFolderError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (moveBookmarkIsError) {
      dispatch(
        addMessage({
          id: 'MoveBookmarkError',
          variant: 'danger',
          messageKeyBody:
            moveBookmarkError && 'data' in moveBookmarkError
              ? moveBookmarkError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [moveBookmarkFolderIsError, moveBookmarkIsError]);

  const handleMoveItem = useCallback(() => {
    if (moveBookmarkFolderIsActive) {
      const targetBookmarkFolderId =
        movingBookmarkFolder.targetBookmarkFolderId ||
        movingBookmarkFolder.bookmarkFolder.parentId ||
        '';

      moveBookmarkFolder({
        id: movingBookmarkFolder.bookmarkFolder.id || '',
        position: movingBookmarkFolder.newPosition,
        targetBookmarkFolderId,
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'MoveBookmarkFolderSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          setDialogShow(false);
          setMoveBookmarkFolderIsActive(false);
          refetch();
          dispatch(addExpandedBookmarkFolderId(targetBookmarkFolderId || ''));
        });
    }

    if (moveBookmarkIsActive) {
      const targetBookmarkFolderId =
        movingBookmark.targetBookmarkFolderId ||
        movingBookmark.bookmark.bookmarkFolderId ||
        '';

      moveBookmark({
        id: movingBookmark.bookmark.id || '',
        position: movingBookmark.newPosition,
        targetBookmarkFolderId,
      })
        .unwrap()
        .then((result) => {
          if (result.messageKey && result.messageKey !== '') {
            dispatch(
              addMessage({
                id: 'MoveBookmarkSuccess',
                variant: 'success',
                messageKeyBody: result.messageKey,
              }),
            );
          }
          setDialogShow(false);
          setMoveBookmarkIsActive(false);
          refetch();
          dispatch(addExpandedBookmarkFolderId(targetBookmarkFolderId || ''));
        });
    }
  }, [
    moveBookmarkFolderIsActive,
    moveBookmarkFolderIsActive,
    movingBookmark,
    movingBookmarkFolder,
  ]);

  return (
    <CustomDialog
      titleId='MoveBookmarkItemDialog'
      actionButtonDisabled={
        moveBookmarkFolderIsLoading || moveBookmarkIsLoading
      }
      dialogTitle={translation(
        moveBookmarkIsActive ? 'moveBookmark' : 'moveBookmarkFolder',
      )}
      show={dialogShow}
      closeFunction={() => {
        setDialogShow(false);
      }}
      actionFunction={handleMoveItem}
      closeTitle={translation('cancel')}
      actionTitle={translation('move')}>
      {(moveBookmarkFolderIsLoading || moveBookmarkIsLoading) && <Loader />}
      {!moveBookmarkFolderIsLoading && !moveBookmarkIsLoading && (
        <p>
          {translation(
            moveBookmarkIsActive
              ? 'moveBookmarkToSelectedPosition'
              : 'moveBookmarkFolderToSelectedPosition',
          )}
        </p>
      )}
    </CustomDialog>
  );
}

export default MoveBookmarkItemDialog;
