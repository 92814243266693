import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { decode } from 'html-entities';
import { useSearchParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  ImportPreview,
  useGetApiCategoryTreeQuery,
  useGetApiVersionsArticleByIdQuery,
  usePostApiCategoriesImportMutation,
} from '../../../redux/store/api/api';
import {
  selectContextCategory,
  selectImportContentDialogOpened,
  selectIsReferenceImport,
  setContentImportDialogOpened,
  setContextCategory,
  setIsReferenceImport,
} from '../../../redux/store/content/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import CustomListGroup from '../../lists/CustomListGroup';
import Loader from '../../loader/Loader';
import FileInput from '../../inputs/FileInput';
import {
  articleIdUrlParam,
  EDIT_MODE_KEY,
  EXPANDED_CATEGORY_IDS,
} from '../../../shared/constants';
import { formatDateString } from '../../../shared/utils';
import useGetCategoryByArticleId from '../../../hooks/useGetCategoryByArticleId';
import { RightKey } from '../../../shared/enums';

function ContentImportDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [expandedCategoryIds, setExpandedCategoryIds] = useSessionStorage<
    string[]
  >(EXPANDED_CATEGORY_IDS, []);
  const category = useAppSelector(selectContextCategory);
  const dialogShow = useAppSelector(selectImportContentDialogOpened);
  const isReferenceImport = useAppSelector(selectIsReferenceImport);
  const [files, setFiles] = useState<File[] | null>(null);
  const [validFrom, setValidFrom] = useState(
    formatDateString(new Date().toString()),
  );
  const [filesAreValid, setFilesAreValid] = useState(true);
  const [dateIsValid, setDateIsValid] = useState(true);
  const [importPreview, setImportPreview] = useState<string[]>([]);
  const [importError, setImportError] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const { refetch: refetchGetCategories } = useGetApiCategoryTreeQuery();
  const categoryForRight = useGetCategoryByArticleId(articleId);
  const userCanEditArticle =
    categoryForRight?.permittedActions?.includes(
      RightKey.RightArticleManagementEditArticle,
    ) || false;
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const { refetch: refetchGetVersions } = useGetApiVersionsArticleByIdQuery(
    articleId
      ? { id: articleId, editMode: editModeIsActive && userCanEditArticle }
      : skipToken,
  );
  const [importContent, { isError, isLoading, error }] =
    usePostApiCategoriesImportMutation();
  const [isCorrection, setIsCorrection] = useState(false);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'ImportContentError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const validateInputs = () => {
    let isValid = true;

    if (!files || files.length === 0) {
      setFilesAreValid(false);
      isValid = false;
    }

    if (!isReferenceImport && validFrom.trim().length === 0) {
      setDateIsValid(false);
      isValid = false;
    }

    return isValid;
  };

  const handleImportArticle = () => {
    if (!validateInputs()) {
      return;
    }

    const isPreview = importPreview && importPreview.length === 0;

    if (files) {
      const formData = new FormData();
      files.forEach((file) =>
        formData.append('importFiles', file as unknown as Blob),
      );

      importContent({
        categoryId: category.id || '',
        body: formData as unknown as { importFiles: Blob[] },
        isPreview,
        isCorrection,
        isReferenceImport,
        validFrom,
      })
        .unwrap()
        .then((payload) => {
          if (isPreview) {
            const importPayload = payload.resultObject as ImportPreview;
            setImportPreview(
              importPayload.previewElements?.map(
                (pe) => pe.description || '',
              ) || [],
            );
            setImportError(importPayload.errorElements || []);
          } else {
            if (payload.messageKey && payload.messageKey !== '') {
              dispatch(
                addMessage({
                  id: 'ContentImportSuccess',
                  variant: 'success',
                  messageKeyBody: payload.messageKey,
                }),
              );
            }
            setFiles(null);
            setImportPreview([]);
            setImportError([]);
            setFilesAreValid(true);
            setDateIsValid(true);
            setExpandedCategoryIds(
              _.union(expandedCategoryIds, [category.id || '']),
            );
            dispatch(setContextCategory({}));
            if (articleId) {
              refetchGetVersions();
            }
            dispatch(setContentImportDialogOpened(false));
            setTimeout(() => {
              refetchGetCategories();
            });
          }
        });
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files));
      setImportPreview([]);
      setImportError([]);
      setFilesAreValid(true);
    }
  };

  return (
    <CustomDialog
      titleId='ContentImportDialog'
      actionButtonDisabled={isLoading}
      dialogTitle={
        isReferenceImport
          ? translation('importArticleReference')
          : translation('importArticle')
      }
      show={dialogShow}
      closeFunction={() => {
        setFiles(null);
        setImportPreview([]);
        setImportError([]);
        dispatch(setContentImportDialogOpened(false));
        dispatch(setIsReferenceImport(false));
        dispatch(setContextCategory({}));
        setFilesAreValid(true);
        setDateIsValid(true);
      }}
      actionFunction={handleImportArticle}
      closeTitle={translation('cancel')}
      actionTitle={
        importPreview?.length > 0
          ? translation('import')
          : translation('verify')
      }>
      <FileInput
        required
        labelName={`${translation('selectImportFiles')}*`}
        multiple
        onChange={handleOnChange}
        accept='.xml,text/xml,application/xml'
        files={files}
        setFiles={setFiles}
        isValid={filesAreValid}
        invalidMessage={translation('fieldNotEmpty')}
      />
      {!isReferenceImport && (
        <>
          <Form.Check
            className='mt-3'
            type='checkbox'
            id='CorrectionImport'
            label={translation('correctionImport')}
            checked={isCorrection}
            onChange={() => setIsCorrection(!isCorrection)}
          />
          <Form.Group className='mb-3 mt-4' controlId='LastModified'>
            <Form.Label>
              {translation('importValidityDatelastModifiedFrom')}*
            </Form.Label>
            <Form.Control
              aria-describedby={dateIsValid ? undefined : 'DateInputError'}
              isInvalid={!dateIsValid}
              value={validFrom}
              type='date'
              onChange={(e) => {
                setValidFrom(e.target.value);
                if (e.target.value.trim()) {
                  setDateIsValid(true);
                }
              }}
            />
            <Form.Control.Feedback id='DateInputError' type='invalid'>
              {translation('fieldNotEmpty')}
            </Form.Control.Feedback>
          </Form.Group>
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
      <div aria-busy={isLoading}>
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            {files && files.length > 0 && (
              <>
                <h3 className='mt-4 fs-5'>{translation('uploadFilesList')}</h3>
                <CustomListGroup
                  listItems={files.map((f: File, i: number) => ({
                    id: i.toString(),
                    content: f.name,
                    iconClass: 'icon-artikel',
                  }))}
                />
              </>
            )}
            {importPreview && importPreview.length > 0 && (
              <>
                <h3 className='mt-2 fs-5'>
                  {translation('importPreviewList')}
                </h3>
                <div className='import-preview-list'>
                  <CustomListGroup
                    listItems={importPreview.map((a: string, i: number) => ({
                      id: i.toString(),
                      content: decode(a.replace('Create -', '') || ''),
                      iconClass: 'icon-artikel',
                    }))}
                  />
                </div>
              </>
            )}
            {importError && importError.length > 0 && (
              <>
                <h3 className='mt-2 fs-5'>{translation('importErrorList')}</h3>
                <div className='import-preview-list'>
                  <CustomListGroup
                    listItems={importError.map((a: string, i: number) => ({
                      id: i.toString(),
                      content: a,
                      iconClass: 'icon-artikel',
                    }))}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </CustomDialog>
  );
}

export default ContentImportDialog;
