import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsAddUserCheckAddByIdQuery,
  useGetApiUserGroupsQuery,
  usePostApiUsersMutation,
} from '../../../redux/store/api/api';
import {
  addExpandedUserGroup,
  clearSelectedUsers,
  selectAddUserDialogOpened,
  selectContextUserGroup,
  setAddUserDialogOpened,
} from '../../../redux/store/user-management/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import UserForm from '../UserForm';
import Loader from '../../loader/Loader';

function AddUserDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectAddUserDialogOpened);
  const userGroup = useAppSelector(selectContextUserGroup);
  const userGroupId = useAppSelector(selectContextUserGroup).id || '';
  const [email, setEmail] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>('');
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [lastName, setLastName] = useState<string>('');
  const [isValidLastName, setIsValidLastName] = useState(true);
  const { refetch } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });
  const [addUser, { isError: addUserIsError, isLoading, error: addUserError }] =
    usePostApiUsersMutation();
  const {
    data: addUserIsPossible,
    isFetching,
    isError: getAddUserCheckError,
    error: userCheckError,
  } = useGetApiUserGroupsAddUserCheckAddByIdQuery(
    dialogShow && userGroup.id
      ? {
          id: userGroup.id,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (getAddUserCheckError) {
      dispatch(
        addMessage({
          id: 'UserCheckError',
          messageKeyBody:
            userCheckError && 'data' in userCheckError
              ? userCheckError.data?.messageKey
              : 'unknownError',
          variant: 'danger',
        }),
      );
    }
  }, [getAddUserCheckError]);

  const resetInputValues = (): void => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setIsValidEmail(true);
    setIsValidFirstName(true);
    setIsValidLastName(true);
  };

  const validateInputs = () => {
    let valid = true;

    if (!isValidEmail || email.trim().length === 0) {
      setIsValidEmail(false);
      valid = false;
    }

    if (firstName.trim().length === 0) {
      setIsValidFirstName(false);
      valid = false;
    }

    if (lastName.trim().length === 0) {
      setIsValidLastName(false);
      valid = false;
    }

    return valid;
  };

  const handleAddUser = (): void => {
    if (!validateInputs()) {
      return;
    }

    addUser({
      user: {
        firstname: firstName,
        lastname: lastName,
        userGroupId,
        email,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'AddUserSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(addExpandedUserGroup(userGroupId || ''));
        refetch();
        dispatch(setAddUserDialogOpened(false));
        resetInputValues();
        dispatch(clearSelectedUsers());
      });
  };

  return (
    <CustomDialog
      titleId='AddUserDialog'
      actionButtonDisabled={!addUserIsPossible || isLoading}
      dialogTitle={translation('addUser')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setAddUserDialogOpened(false));
        resetInputValues();
      }}
      actionFunction={handleAddUser}
      closeTitle={translation('cancel')}
      actionTitle={
        addUserIsPossible?.resultObject ? translation('add') : undefined
      }>
      {(isFetching || isLoading) && <Loader />}
      {!isFetching && !isLoading && (
        <>
          {addUserIsPossible?.resultObject && (
            <UserForm
              email={email}
              setEmail={setEmail}
              isValidEmail={isValidEmail}
              setIsValidEmail={setIsValidEmail}
              firstName={firstName}
              setFirstName={setFirstName}
              isValidFirstName={isValidFirstName}
              setIsValidFirstName={setIsValidFirstName}
              lastName={lastName}
              setLastName={setLastName}
              isValidLastName={isValidLastName}
              setIsValidLastName={setIsValidLastName}
            />
          )}
          {!addUserIsPossible?.resultObject && (
            <p>{translation('addUserImpossible')}</p>
          )}
        </>
      )}
      {addUserIsError && addUserError && 'data' in addUserError && (
        <Alert
          aria-label={translation('closeAlert')}
          variant='danger'
          className='mt-3'
          dismissible>
          <p className='m-0'>
            {translation(addUserError.data?.messageKey || 'unknownError')}
          </p>
        </Alert>
      )}
    </CustomDialog>
  );
}

export default AddUserDialog;
