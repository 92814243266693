import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { decode } from 'html-entities';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  Category,
  useGetApiCategoryTreeQuery,
} from '../../redux/store/api/api';
import { useCategoriesExportByIdMutation } from '../../redux/store/api/fileApi';
import {
  setAddCategoryDialogOpened,
  setAddStructureElementDialogOpened,
  setContentImportDialogOpened,
  setContextCategory,
  setEditCategoryDialogOpened,
  setIsReferenceImport,
  setChangeValidityDateDialogOpened,
  setMovingElements,
  setMoveElementsStarted,
  selectMoveElementsStarted,
  selectMovingElements,
  setNewPositionForMovingElements,
  setMoveElementsDialogOpened,
  setDisableElementsDialogOpened,
  setActivateElementsDialogOpened,
  setElementsToRecycleBinDialogOpened,
  setChangePublishDatesDialogOpened,
  setControlledSelectedIds,
} from '../../redux/store/content/slice';
import { addMessage, removeMessage } from '../../redux/store/layout/slice';
import { RightKey } from '../../shared/enums';
import { EDIT_MODE_KEY } from '../../shared/constants';
import {
  convertCategoryTreeItemToCategory,
  downloadFile,
} from '../../shared/utils';
import ContextMenu from '../dropdown-menus/ContextMenu';
import { ContextAction } from '../dropdown-menus/types';
import { ICategoryTreeItem } from './types';
import { getCreateArticleUrl } from '../../shared/urlBuilder';
import DescriptiveIcon from '../descriptive-icons/DescriptiveIcon';

interface ICategoryTreeElementProps {
  categoryTreeElement: ICategoryTreeItem;
  isBranch: boolean;
  isExpanded: boolean;
  permittedActions: RightKey[];
  handleSelect: Function;
  isSelected: boolean;
  selectedIds: string[];
}

function CategoryTreeElement({
  categoryTreeElement,
  isBranch,
  isExpanded,
  permittedActions,
  handleSelect,
  isSelected,
  selectedIds,
}: ICategoryTreeElementProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const moveElementsStarted = useAppSelector(selectMoveElementsStarted);
  const movingElements = useAppSelector(selectMovingElements);
  const { data: contentTreeData } = useGetApiCategoryTreeQuery();
  const blackClass = 'text-body';
  const successClass = 'text-success';
  const dangerClass = 'text-danger';
  const iconDeactivate = 'icon-deaktivieren';
  const iconActivate = 'icon-aktivieren';
  const [exportCategory] = useCategoriesExportByIdMutation();
  const category: Category =
    convertCategoryTreeItemToCategory(categoryTreeElement);
  const categoryHasArticles =
    !!category.articleIds && category.articleIds?.length > 0;

  // permissions
  const userCanEditArticles = permittedActions.includes(
    RightKey.RightArticleManagementEditArticle,
  );
  const userCanCreateArticles = permittedActions.includes(
    RightKey.RightArticleManagementCreateArticle,
  );
  const userCanCreatePdfArticles = permittedActions.includes(
    RightKey.RightArticleManagementCreatePdfArticleVersion,
  );
  const userCanImportArticles = permittedActions.includes(
    RightKey.RightCategoryManagementImportArticles,
  );
  const userCanCreateStructureElements = permittedActions.includes(
    RightKey.RightArticleManagementCreateEditDeleteStructureElement,
  );
  const userCanCreateCategories = permittedActions.includes(
    RightKey.RightCategoryManagementCreateCategory,
  );
  const userCanEditCategories = permittedActions.includes(
    RightKey.RightCategoryManagementEditCategory,
  );
  const userCanDeleteCategories = permittedActions.includes(
    RightKey.RightCategoryManagementDeleteCategory,
  );
  const userCanDeleteArticles = permittedActions.includes(
    RightKey.RightArticleManagementDeleteArticle,
  );
  const userCanChangePuplicationDate = permittedActions.includes(
    RightKey.RightCategoryManagementChangePublishDate,
  );
  const userCanChangeValidityDate = permittedActions.includes(
    RightKey.RightArticleManagementChangeValidFromDateRecursively,
  );

  const getSelectedElementsContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    if (userCanEditArticles || userCanEditCategories) {
      contextActions.push({
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation('moveSelectedElements'),
        onClick: () => {
          dispatch(setMovingElements(selectedIds));
          dispatch(setMoveElementsStarted(true));
        },
      });

      contextActions.push({
        iconClass: iconActivate,
        iconColorClass: 'text-success',
        name: translation('activateSelectedElements'),
        onClick: () => {
          dispatch(setActivateElementsDialogOpened(true));
        },
      });

      contextActions.push({
        iconClass: iconDeactivate,
        iconColorClass: dangerClass,
        name: translation('deactivateSelectedElements'),
        onClick: () => {
          dispatch(setDisableElementsDialogOpened(true));
        },
      });
    }

    if (userCanDeleteArticles || userCanDeleteCategories) {
      contextActions.push({
        iconClass: 'icon-trash',
        iconColorClass: dangerClass,
        name: translation('moveSelectedElementsToRecycleBin'),
        onClick: () => {
          dispatch(setElementsToRecycleBinDialogOpened(true));
        },
      });
    }

    return contextActions;
  };

  const getMoveContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];
    const categoryHasParent = category.parentId && category.parentId !== '0';
    const movingElementsCount = movingElements.elementIds.length;

    if (moveElementsStarted) {
      if (
        userCanCreateArticles &&
        !movingElements.elementIds.includes(category.id || '')
      ) {
        contextActions.push({
          iconClass: 'icon-move',
          iconColorClass: blackClass,
          name: translation(
            movingElementsCount === 1
              ? 'moveElementToThisCategory'
              : 'moveElementsToThisCategory',
          ),
          onClick: () => {
            dispatch(
              setNewPositionForMovingElements({
                newPosition: undefined,
                targetCategory: category,
              }),
            );
            dispatch(setMoveElementsDialogOpened(true));
          },
          addDividerAfterItem: true,
        });

        // Only if there is at least one category among the moving elements
        // And all moving articles are children of the moving categories
        if (
          category.parentId !== '0' &&
          !!contentTreeData?.resultObject?.categories?.find((c) =>
            movingElements.elementIds.includes(c.id || ''),
          ) &&
          contentTreeData?.resultObject?.articles
            ?.filter((a) => movingElements.elementIds.includes(a.id || ''))
            .filter((a) =>
              movingElements.elementIds.includes(a.categoryId || ''),
            ).length ===
            contentTreeData?.resultObject?.articles?.filter((a) =>
              movingElements.elementIds.includes(a.id || ''),
            ).length
        ) {
          contextActions.push({
            iconClass: 'icon-move',
            iconColorClass: blackClass,
            name: translation(
              movingElementsCount === 1
                ? 'moveElementOverThisElement'
                : 'moveElementsOverThisElement',
            ),
            onClick: () => {
              dispatch(
                setNewPositionForMovingElements({
                  newPosition: category.order || 0,
                  targetCategory: { id: category.parentId },
                }),
              );
              dispatch(setMoveElementsDialogOpened(true));
            },
          });

          contextActions.push({
            iconClass: 'icon-move',
            iconColorClass: blackClass,
            name: translation(
              movingElementsCount === 1
                ? 'moveElementBelowThisElement'
                : 'moveElementsBelowThisElement',
            ),
            onClick: () => {
              dispatch(
                setNewPositionForMovingElements({
                  newPosition: (category.order || 0) + 1,
                  targetCategory: { id: category.parentId },
                }),
              );
              dispatch(setMoveElementsDialogOpened(true));
            },
            addDividerAfterItem: true,
          });
        }
      }

      contextActions.push({
        iconClass: iconDeactivate,
        iconColorClass: dangerClass,
        name: translation('stopMove'),
        onClick: () => {
          dispatch(setMoveElementsStarted(false));
        },
      });

      return contextActions;
    }

    if (categoryHasParent && userCanEditCategories) {
      contextActions.push({
        helpId: 'help_2_2_8',
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation('startMoveCategory'),
        onClick: () => {
          dispatch(setMovingElements([category.id || '']));
          dispatch(setMoveElementsStarted(true));
        },
        addDividerAfterItem: true,
      });
    }

    return contextActions;
  };

  const getEditModeContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];
    const categoryHasParent = category.parentId && category.parentId !== '';
    const categoryHasChildren =
      (category.articleIds?.length || 0) > 0 ||
      (category.categoryIds?.length || 0) > 0;
    const categoryId = category.id || '';

    contextActions.push(...getMoveContextActions());

    if (moveElementsStarted) {
      return contextActions;
    }

    if (
      (category.articleIds?.length || 0) > 0 &&
      (userCanEditArticles || userCanDeleteArticles)
    ) {
      if (
        selectedIds.filter((s) => category.articleIds?.includes(s)).length <
        (category.articleIds?.length || 0)
      ) {
        contextActions.push({
          iconClass: 'icon-stapel',
          iconColorClass: blackClass,
          name: translation('selectAllArticles'),
          onClick: () => {
            const articlesOfCategory = category.articleIds || [];
            dispatch(
              setControlledSelectedIds(
                _.uniq([...articlesOfCategory, ...selectedIds]),
              ),
            );
          },
          addDividerAfterItem: true,
        });
      } else {
        contextActions.push({
          iconClass: 'icon-stapel',
          iconColorClass: blackClass,
          name: translation('deselectAllArticles'),
          onClick: () => {
            dispatch(
              setControlledSelectedIds(
                [...selectedIds].filter(
                  (s) => !category.articleIds?.includes(s),
                ),
              ),
            );
          },
          addDividerAfterItem: true,
        });
      }
    }

    if (userCanCreateCategories) {
      contextActions.push({
        helpId: 'help_2_2_5',
        iconClass: 'icon-folder_new',
        iconColorClass: successClass,
        name: translation('addSubcategory'),
        onClick: () => {
          dispatch(setContextCategory(category));
          dispatch(setAddCategoryDialogOpened(true));
        },
      });
    }

    if (userCanEditCategories) {
      contextActions.push({
        iconClass: 'icon-edit',
        iconColorClass: blackClass,
        name: translation('editCategory'),
        onClick: () => {
          dispatch(setContextCategory(category));
          dispatch(setEditCategoryDialogOpened(true));
        },
        addDividerAfterItem: !categoryHasParent,
      });

      if (userCanEditCategories) {
        contextActions.push({
          helpId: 'help_2_2_7',
          iconClass: category.disabled ? iconActivate : iconDeactivate,
          iconColorClass: category.disabled ? successClass : dangerClass,
          name: translation(
            category.disabled ? 'activateCategory' : 'deactivateCategory',
          ),
          onClick: () => {
            dispatch(setContextCategory(category));
            if (category.disabled) {
              dispatch(setActivateElementsDialogOpened(true));
            }
            if (!category.disabled) {
              dispatch(setDisableElementsDialogOpened(true));
            }
          },
        });
      }

      if (categoryHasParent && userCanDeleteCategories) {
        contextActions.push({
          helpId: 'help_2_2_6',
          iconClass: 'icon-trash',
          iconColorClass: dangerClass,
          name: translation('moveCategoryToRecycleBin'),
          onClick: () => {
            dispatch(setContextCategory(category));
            dispatch(setElementsToRecycleBinDialogOpened(true));
          },
          addDividerAfterItem: true,
        });
      }
    }

    if (userCanCreateStructureElements) {
      contextActions.push({
        helpId: 'help_2_2_2',
        iconClass: 'icon-struktur',
        iconColorClass: successClass,
        name: translation('createStructureElement'),
        onClick: () => {
          dispatch(setContextCategory(category));
          dispatch(setAddStructureElementDialogOpened(true));
        },
      });
    }

    if (userCanCreateArticles) {
      contextActions.push({
        helpId: 'help_2_2_3',
        iconClass: 'icon-artikel_new',
        iconColorClass: successClass,
        name: translation('addArticle'),
        href: getCreateArticleUrl(categoryId, false),
      });
    }

    if (userCanCreatePdfArticles) {
      contextActions.push({
        iconClass: 'icon-artikel_pdf_new',
        iconColorClass: successClass,
        name: translation('addArticlePdf'),
        href: getCreateArticleUrl(categoryId, true),
        addDividerAfterItem: true,
      });
    }

    if (userCanImportArticles) {
      contextActions.push({
        iconClass: 'icon-import_gesetz',
        iconColorClass: successClass,
        name: translation('importArticle'),
        onClick: () => {
          dispatch(setContextCategory(category));
          dispatch(setContentImportDialogOpened(true));
          dispatch(setIsReferenceImport(false));
        },
      });
    }

    if (userCanImportArticles) {
      contextActions.push({
        iconClass: 'icon-import_gesetz',
        iconColorClass: successClass,
        name: translation('importArticleReference'),
        onClick: () => {
          dispatch(setContextCategory(category));
          dispatch(setContentImportDialogOpened(true));
          dispatch(setIsReferenceImport(true));
        },
        addDividerAfterItem: userCanEditCategories && categoryHasChildren,
      });
    }

    if (categoryHasChildren) {
      if (userCanChangeValidityDate) {
        contextActions.push({
          iconClass: 'icon-tree',
          iconColorClass: successClass,
          name: translation('changeValidityDateRecursively'),
          onClick: () => {
            dispatch(setContextCategory(category));
            dispatch(setChangeValidityDateDialogOpened(true));
          },
        });
      }

      if (userCanChangePuplicationDate) {
        contextActions.push({
          helpId: 'help_2_2_9',
          iconClass: 'icon-aenderungen',
          iconColorClass: successClass,
          name: translation('changePublicationPeriod'),
          onClick: () => {
            dispatch(setContextCategory(category));
            dispatch(setChangePublishDatesDialogOpened(true));
          },
          addDividerAfterItem: categoryHasArticles,
        });
      }
    }

    return contextActions;
  };

  const getContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    if (editModeIsActive) {
      if (selectedIds.length > 0 && !moveElementsStarted) {
        contextActions.push(...getSelectedElementsContextActions());

        return contextActions;
      }

      contextActions.push(...getEditModeContextActions());
    }

    if (categoryHasArticles) {
      contextActions.push({
        helpId: 'help_2_2_1',
        iconClass: 'icon-kategorie_pdf',
        iconColorClass: dangerClass,
        name: translation('exportCategoryAsPdf'),
        onClick: () => {
          dispatch(
            addMessage({
              id: 'GenerateCategoryPdf',
              messageKeyHeader: 'pdfIsGenerating',
              messageKeyBody: 'pleaseBePatient',
              variant: 'info',
              timeInMs: 9000000000,
            }),
          );
          exportCategory({
            id: category.id || '',
          })
            .unwrap()
            .then((url) => {
              downloadFile(url, decode(category.name).replace(/\s/g, ''));
              dispatch(removeMessage('GenerateCategoryPdf'));
              dispatch(
                addMessage({
                  id: 'GeneratePdfSuccess',
                  messageKeyBody: 'generatePdfSuccess',
                  variant: 'success',
                }),
              );
            })
            .catch(() => {
              dispatch(removeMessage('GenerateCategoryPdf'));
              dispatch(
                addMessage({
                  id: 'ExportCategoryError',
                  messageKeyBody: 'unknownError',
                  variant: 'danger',
                }),
              );
            });
        },
      });
    }

    return contextActions;
  };

  return (
    <div className='d-flex pe-5'>
      {isBranch && isExpanded && (
        <i className='icon-carretup icon-tree-collapse' aria-hidden />
      )}
      {isBranch && !isExpanded && (
        <i className='icon-carretdown icon-tree-collapse' aria-hidden />
      )}
      {editModeIsActive &&
        (userCanEditCategories || userCanDeleteCategories) && (
          <Form.Check
            className='ms-1 mt-1 me-1'
            type='checkbox'
            onChange={(e) => {
              handleSelect(e);
              e.stopPropagation();
            }}
            checked={isSelected}
            id={`${decode(category.name)}_${category.id}`}
          />
        )}

      <div className={`d-flex ms-1${category.disabled ? ' text-muted' : ''}`}>
        <i
          className={`${category.categoryTypeIconClass} me-1`}
          aria-hidden
          style={{ color: category.categoryTypeColorHexCode || '' }}
        />
        {category.disabled && (
          <DescriptiveIcon
            iconClass={`${iconDeactivate}`}
            colorClass='text-danger'
            description={translation('categoryIsDisabled')}
          />
        )}
        <span>{decode(category.name)}</span>
      </div>
      {(editModeIsActive || categoryHasArticles) && (
        <ContextMenu contextActions={getContextActions()} />
      )}
    </div>
  );
}

export default CategoryTreeElement;
