import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDialog from '../../dialogs/CustomDialog';
import {
  useDeleteApiBookmarkFoldersByIdMutation,
  useGetApiBookmarkFoldersQuery,
} from '../../../redux/store/api/api';
import Loader from '../../loader/Loader';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  removeExpandedBookmarkFolderId,
  selectContextBookmarkFolder,
  selectDeleteBookmarkFolderDialogOpened,
  setDeleteBookmarkFolderDialogOpened,
} from '../../../redux/store/content/slice';

function DeleteBookmarkFolderDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectDeleteBookmarkFolderDialogOpened);
  const contextBookmarkFolder = useAppSelector(selectContextBookmarkFolder);
  const { t: translation } = useTranslation();
  const [deleteBookmarkFolder, { isLoading, isError, error }] =
    useDeleteApiBookmarkFoldersByIdMutation();
  const { refetch } = useGetApiBookmarkFoldersQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'DeleteBookmarkFolderError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  const handleDeleteBookmarkFolder = () => {
    deleteBookmarkFolder({ id: contextBookmarkFolder.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteBookmarkFolderSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setDeleteBookmarkFolderDialogOpened(false));
        refetch();
        dispatch(
          removeExpandedBookmarkFolderId(contextBookmarkFolder.id || ''),
        );
      });
  };

  return (
    <CustomDialog
      titleId='DeleteBookmarkFolderDialog'
      dialogTitle={translation('deleteBookmarkFolder')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setDeleteBookmarkFolderDialogOpened(false));
      }}
      actionFunction={handleDeleteBookmarkFolder}
      closeTitle={translation('cancel')}
      actionTitle={translation('delete')}
      actionButtonVariant='outline-danger'
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>{translation('deleteBookmarkFolderPermanently')}</p>
          <p className='fw-bold'>{contextBookmarkFolder.name}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default DeleteBookmarkFolderDialog;
