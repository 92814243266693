import { useLocalStorage } from 'usehooks-ts';
import { Link } from 'react-router-dom';
import { Button, Dropdown, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useLayoutEffect, useState } from 'react';
import authService from '../api-authorization/AuthorizeService';
import LoginMenu from '../api-authorization/LoginMenu';
import { RightKey } from '../../shared/enums';
import { EDIT_MODE_KEY } from '../../shared/constants';
import { useGetApiPermissionsUserQuery } from '../../redux/store/api/api';
import {
  addMessage,
  setUserGuideDialogOpened,
} from '../../redux/store/layout/slice';
import { useAppDispatch } from '../../redux/hooks';
import EnterPasswordDialog from './dialogs/EnterPasswordDialog';
import SendErrorReportDialog from './dialogs/SendErrorReport';
import SettingLinks from './SettingLinks';
import SupportTextDialog from './dialogs/SupportTextDialog';
import UserGuideDialog from './dialogs/UserGuideDialog';
import './NavbarLinks.scss';
import DropdownMenu from '../dropdown-menus/DropdownMenu';

function NavbarDropdowns(): JSX.Element | null {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [passwordDialogOpened, setPasswordDialogOpened] =
    useState<boolean>(false);
  const [supportTextDialogOpened, setSupportTextDialogOpened] =
    useState<boolean>(false);
  const [errorReportDialogOpened, setErrorReportDialogOpened] =
    useState<boolean>(false);
  const [editModeIsActive, setEditModeIsActive] = useLocalStorage<boolean>(
    EDIT_MODE_KEY,
    false,
  );
  const {
    data: permissionData,
    isError,
    isLoading,
    error,
  } = useGetApiPermissionsUserQuery();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetPermissionsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  // permissions
  const userHasAccessToUserManagement = permissionData?.resultObject?.includes(
    RightKey.RightAccessUserManagement,
  );
  const userHasAccessToRoleManagement = permissionData?.resultObject?.includes(
    RightKey.RightAccessRoleManagement,
  );
  const userHasAccessToCategoryTypeManagement =
    permissionData?.resultObject?.includes(
      RightKey.RightAccessCategoryTypeManagement,
    );
  const userHasAccessToGlobalSettingsManagement =
    permissionData?.resultObject?.includes(
      RightKey.RightAccessGlobalSettingsManagement,
    );
  const userHasAdministrationRights =
    userHasAccessToUserManagement ||
    userHasAccessToRoleManagement ||
    userHasAccessToCategoryTypeManagement;
  const userHasManagementRights =
    userHasAdministrationRights ||
    permissionData?.resultObject?.includes(
      RightKey.RightAccessCategoryManagement,
    );

  const userHasUseSupportRights = permissionData?.resultObject?.includes(
    RightKey.RightSystemManagementUseSupport,
  );

  const checkAuthenticatedState = async () => {
    const isAuthenticated = await authService.isAuthenticated();
    setAuthenticated(isAuthenticated);
  };

  useLayoutEffect(() => {
    checkAuthenticatedState();
  }, []);

  return authenticated && !isLoading ? (
    <>
      {userHasManagementRights && (
        <Button
          onClick={() => {
            if (editModeIsActive) {
              setEditModeIsActive(false);
            } else {
              setPasswordDialogOpened(true);
            }
          }}
          variant='outline-dark'
          className={`${
            editModeIsActive ? 'header-active-background' : ''
          } rounded-circle circle-btn`}
          title={
            editModeIsActive
              ? translation('deactivateEditMode')
              : translation('activateEditMode')
          }
          aria-label={
            editModeIsActive
              ? translation('deactivateEditMode')
              : translation('activateEditMode')
          }>
          <i className='icon-edit' aria-hidden />
        </Button>
      )}
      <SettingLinks
        userHasAccessToUserManagement={userHasAccessToUserManagement || false}
        userHasAccessToRoleManagement={userHasAccessToRoleManagement || false}
        userHasAccessToCategoryTypeManagement={
          userHasAccessToCategoryTypeManagement || false
        }
        userHasAccessToGlobalSettingsManagement={
          userHasAccessToGlobalSettingsManagement || false
        }
        userHasAdministrationRights={userHasAdministrationRights || false}
      />
      <LoginMenu />
      <Dropdown>
        <Dropdown.Toggle
          aria-label={translation('informationsAndHelp')}
          variant='outline-dark'
          className='rounded-circle circle-btn'>
          <i
            className='icon-info'
            aria-hidden
            title={translation('informationsAndHelp')}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu as={DropdownMenu} align='end'>
          <li>
            <NavDropdown.Item
              onClick={() => {
                dispatch(setUserGuideDialogOpened({ show: true }));
              }}>
              <i className='icon-info me-2' aria-hidden />
              {translation('userGuide')}
            </NavDropdown.Item>
          </li>
          {userHasUseSupportRights && (
            <>
              <li>
                <NavDropdown.Item
                  onClick={() => {
                    setErrorReportDialogOpened(true);
                  }}>
                  <i className='icon-info me-2' aria-hidden />
                  {translation('sendErrorReport')}
                </NavDropdown.Item>
              </li>
              <li>
                <NavDropdown.Item
                  onClick={() => {
                    setSupportTextDialogOpened(true);
                  }}>
                  <i className='icon-info me-2' aria-hidden />
                  {translation('support')}
                </NavDropdown.Item>
              </li>
            </>
          )}
          <li>
            <NavDropdown.Item as={Link} to='/imprint'>
              <i className='icon-info me-2' aria-hidden />
              {translation('imprint')}
            </NavDropdown.Item>
          </li>
          <li>
            <NavDropdown.Item as={Link} to='/privacy'>
              <i className='icon-info me-2' aria-hidden />
              {translation('privacy')}
            </NavDropdown.Item>
          </li>
        </Dropdown.Menu>
      </Dropdown>
      {!editModeIsActive && (
        <EnterPasswordDialog
          show={passwordDialogOpened}
          onClose={() => {
            setPasswordDialogOpened(false);
          }}
          setEditModeActive={setEditModeIsActive}
        />
      )}
      <SendErrorReportDialog
        show={errorReportDialogOpened}
        onClose={() => setErrorReportDialogOpened(false)}
      />
      <SupportTextDialog
        show={supportTextDialogOpened}
        setShow={setSupportTextDialogOpened}
      />
      <UserGuideDialog />
    </>
  ) : null;
}

export default NavbarDropdowns;
