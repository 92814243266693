import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useState } from 'react';
import TreeView from 'react-accessible-treeview';
import { useTranslation } from 'react-i18next';
import { decode } from 'html-entities';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  Article,
  Category,
  useGetApiArticlesReferencesByIdQuery,
  useGetApiCategoriesArticleReferencesByIdQuery,
  useGetApiCategoryTreeQuery,
  useGetApiRecycleBinQuery,
  usePutApiRecycleBinRestoreMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { ContentTreeItems, ICategoryTreeItem } from '../../content-tree/types';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import RestoreTreeItem from '../RestoreTreeItem';
import { getTreeCategories } from '../../content-tree/functions';
import {
  articleIdUrlParam,
  categoryIdUrlParam,
} from '../../../shared/constants';
import {
  removeExpandedRecycleBinCategory,
  selectContextArticle,
  selectContextCategory,
  setContextArticle,
  setContextCategory,
} from '../../../redux/store/content/slice';

interface IRestoreDialogProps {
  dialogShow: boolean;
  setDialogShow: (show: boolean) => void;
  itemsToRestore: (Article | Category)[];
  setSelectedItems: (items: (Article | Category)[]) => void;
}

function RestoreDialog({
  dialogShow,
  setDialogShow,
  itemsToRestore,
  setSelectedItems,
}: IRestoreDialogProps) {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const contextArticle = useAppSelector(selectContextArticle);
  const contextCategory = useAppSelector(selectContextCategory);
  const [searchParams] = useSearchParams();
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const categoryId: string | null = searchParams.get(categoryIdUrlParam);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null,
  );
  const {
    data: categoryData,
    isError: getCategoriesIsError,
    error: getCategoriesError,
    isFetching,
  } = useGetApiCategoryTreeQuery();
  const { refetch: refetchGetReferencesOfCategory } =
    useGetApiCategoriesArticleReferencesByIdQuery(
      categoryId ? { id: categoryId } : skipToken,
    );
  const { refetch: refetchGetRecycleBin } = useGetApiRecycleBinQuery();
  const { refetch: refetchArticleReferences } =
    useGetApiArticlesReferencesByIdQuery(
      articleId
        ? {
            id: articleId,
          }
        : skipToken,
    );
  const { refetch: refetchGetCategoryTree } = useGetApiCategoryTreeQuery();
  const [
    restoreItems,
    {
      isError: restoreIsError,
      error: restoreError,
      isLoading: restoreIsLoading,
    },
  ] = usePutApiRecycleBinRestoreMutation();

  useEffect(() => {
    if (getCategoriesIsError) {
      dispatch(
        addMessage({
          id: 'GetCategoriesError',
          variant: 'danger',
          messageKeyBody:
            getCategoriesError && 'data' in getCategoriesError
              ? getCategoriesError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (restoreIsError) {
      dispatch(
        addMessage({
          id: 'RestoreError',
          variant: 'danger',
          messageKeyBody:
            restoreError && 'data' in restoreError
              ? restoreError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getCategoriesIsError, restoreIsError]);

  const treeData = useMemo<ContentTreeItems>(() => {
    if (categoryData?.resultObject) {
      return getTreeCategories(categoryData.resultObject);
    }
    return [];
  }, [categoryData]);

  const handleRestore = (): void => {
    const articlesToRestore: Article[] = contextArticle.id
      ? [contextArticle]
      : (itemsToRestore.filter((i) => 'categoryId' in i) as Article[]);
    const categoriesToRestore: Category[] = contextCategory.id
      ? [contextCategory]
      : (itemsToRestore.filter((i) => 'categoryTypeId' in i) as Category[]);

    const articleIds = articlesToRestore.map((a) => a.id || '');
    const categoryIds = categoriesToRestore.map((c) => c.id || '');

    restoreItems({
      categoryTreeBatchOperationItems: {
        articleIds,
        categoryIds,
      },
      targetCategoryId: selectedCategoryId || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'RestoreSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        if (articleId) {
          refetchArticleReferences();
        }

        if (categoryId) {
          refetchGetReferencesOfCategory();
        }

        refetchGetRecycleBin();
        refetchGetCategoryTree();
        dispatch(removeExpandedRecycleBinCategory(categoryIds));
        setDialogShow(false);
        dispatch(setContextArticle({}));
        dispatch(setContextCategory({}));
        setSelectedItems([]);
      });
  };

  return (
    <CustomDialog
      wideDialog
      dialogId='RestoreDialog'
      titleId='RestoreDialogTitle'
      dialogTitle={translation(
        itemsToRestore.length > 1 ? 'restoreElements' : 'restoreElement',
      )}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setContextArticle({}));
        dispatch(setContextCategory({}));
        setDialogShow(false);
        setSelectedCategoryId(null);
      }}
      actionButtonDisabled={!selectedCategoryId || restoreIsLoading}
      actionFunction={handleRestore}
      closeTitle={translation('cancel')}
      actionTitle={translation('restore')}
      actionButtonVariant='outline-danger'>
      {restoreIsLoading && <Loader />}
      {!restoreIsLoading && (
        <>
          {itemsToRestore.length === 1 && (
            <p className='fw-bold'>
              {'categoryId' in itemsToRestore[0]
                ? decode(itemsToRestore[0].recentVersionTitle)
                : decode((itemsToRestore[0] as Category).name)}
            </p>
          )}
          <p aria-labelledby='RestoreTree'>
            {translation(
              itemsToRestore.length > 1
                ? 'chooseCategoryToRestoreElements'
                : 'chooseCategoryToRestoreElement',
            )}
          </p>
          {isFetching && <Loader />}
          {!isFetching && categoryData && (
            <TreeView
              id='RestoreTree'
              clickAction='FOCUS'
              data={treeData}
              nodeRenderer={({
                element,
                getNodeProps,
                level,
                isBranch,
                isExpanded,
              }) =>
                RestoreTreeItem({
                  level,
                  isBranch,
                  isExpanded,
                  getNodeProps,
                  selectedCategoryId,
                  setSelectedCategoryId,
                  element: element as ICategoryTreeItem,
                })
              }
            />
          )}
        </>
      )}
    </CustomDialog>
  );
}

export default RestoreDialog;
