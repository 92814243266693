import { ErrorBoundary } from 'react-error-boundary';
import { useWindowSize } from 'usehooks-ts';
import { PropsWithChildren, useEffect, useLayoutEffect, useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import authService from '../components/api-authorization/AuthorizeService';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  selectMessages,
  selectSidebarOpened,
  setSidebarOpened,
} from '../redux/store/layout/slice';
import SidebarContent from './SidebarContent';
import { lgBreakpoint } from '../shared/constants';
import AutomaticallyClosingToast from '../components/notifications/AutomaticallyClosingToast';
import GlobalFallback from '../components/error-fallback/GlobalFallback';

function Main({ children }: PropsWithChildren<{}>): JSX.Element {
  const messages = useAppSelector(selectMessages);
  const sidebarOpened = useAppSelector(selectSidebarOpened);
  const dispatch = useAppDispatch();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const { width: windowWidth } = useWindowSize();

  const checkAuthenticatedState = async () => {
    const isAuthenticated = await authService.isAuthenticated();
    setAuthenticated(isAuthenticated);
  };

  useEffect(() => {
    if (windowWidth >= lgBreakpoint) {
      dispatch(setSidebarOpened(true));
    }
    if (windowWidth < lgBreakpoint) {
      dispatch(setSidebarOpened(false));
    }
  }, [windowWidth]);

  useLayoutEffect(() => {
    checkAuthenticatedState();
  }, []);

  return (
    <main className='d-flex gap-4'>
      {authenticated && (
        <div
          id='Sidebar'
          className={`sidebar-container bg-white${
            sidebarOpened ? ' open' : ''
          }`}>
          <div className={`sidebar-content${sidebarOpened ? ' open' : ''}`}>
            <ErrorBoundary fallback={<GlobalFallback />}>
              <SidebarContent />
            </ErrorBoundary>
          </div>
        </div>
      )}
      <div className='content-container'>
        <ErrorBoundary fallback={<GlobalFallback />}>{children}</ErrorBoundary>
      </div>
      <ToastContainer className='p-2' position='bottom-end'>
        {messages.map((m) => {
          if (
            (!m.messageKeyBody || m.messageKeyBody.trim() === '') &&
            (!m.messageKeyHeader || m.messageKeyHeader.trim() === '')
          ) {
            return null;
          }

          return <AutomaticallyClosingToast key={m.id} message={m} />;
        })}
      </ToastContainer>
    </main>
  );
}

export default Main;
