import { decode } from 'html-entities';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import VersionContent from '../version/VersionContent';
import MarksButtonGroup from '../marks/MarksButtonGroup';
import CustomCard from '../cards/CustomCard';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  selectComparerIsActive,
  setComparerIsActive,
} from '../../redux/store/content/slice';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';
import { useGetApiCategoryTreeQuery } from '../../redux/store/api/api';
import useRefetchContentTreeIfInvalid from '../../hooks/useRefetchContentTreeIfInvalid';
import ComparerContent from '../compare-versions/ComparerContent';
import { getArticleUrl } from '../../shared/urlBuilder';
import {
  SEARCH_RESULT_SPAN,
  searchKeywordUrlParam,
} from '../../shared/constants';
import { getMultipleWordSearchRegex } from '../../shared/utils';
import ArticlePdfView from './ArticlePdfView';
import { ArticleType } from '../../shared/enums';
import VersionContentFallback from '../error-fallback/VersionContentFallback';

function ArticleView() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const comparerIsActive = useAppSelector(selectComparerIsActive);
  const version = useAppSelector(selectActiveVersion);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const searchKeywordsParam: string | null = searchParams.get(
    searchKeywordUrlParam,
  );
  const [versionTitle, setVersionTitle] = useState(version.title);

  const category = useGetCategoryByArticleId(version.articleId);

  const { data: contentTreeData } = useGetApiCategoryTreeQuery();

  useRefetchContentTreeIfInvalid([version]);

  const currentArticle = contentTreeData?.resultObject?.articles?.find(
    (a) => a.id === version.articleId,
  );
  const previousArticle = contentTreeData?.resultObject?.articles
    ?.filter(
      (a) =>
        currentArticle?.order !== undefined &&
        a.order !== undefined &&
        a.categoryId === currentArticle?.categoryId &&
        !a.disabled &&
        a.order < currentArticle?.order &&
        a.type !== ArticleType.StructureElement,
    )
    .sort((a, b) => (b.order || 0) - (a.order || 0))[0];
  const nextArticle = contentTreeData?.resultObject?.articles
    ?.filter(
      (a) =>
        currentArticle?.order !== undefined &&
        a.order !== undefined &&
        a.categoryId === currentArticle?.categoryId &&
        !a.disabled &&
        a.order > currentArticle?.order &&
        a.type !== ArticleType.StructureElement,
    )
    .sort((a, b) => (a.order || 0) - (b.order || 0))[0];

  useEffect(() => {
    // Set focus on article title
    const articleTitleElement = document
      .getElementsByClassName('article-content')[0]
      .getElementsByClassName('card-title')[0]
      .getElementsByTagName('h1')[0];

    articleTitleElement.focus();
  }, [comparerIsActive]);

  useEffect(() => {
    dispatch(setComparerIsActive(false));
  }, [location]);

  useEffect(() => {
    if (searchKeywordsParam) {
      const searchKeywords: string[] = searchKeywordsParam.split(',');
      const regex = getMultipleWordSearchRegex(searchKeywords);

      setVersionTitle(
        version.title?.replace(
          regex,
          (match) =>
            `<span class='${SEARCH_RESULT_SPAN} border border-2 border-primary'>${match}</span>`,
        ),
      );
    } else {
      setVersionTitle(version.title);
    }
  }, [searchKeywordsParam, version.title]);

  return (
    <div className='article-content'>
      {comparerIsActive && <ComparerContent />}
      {!comparerIsActive && (
        <>
          <CustomCard
            focusableHeadline
            headlineAsH1
            iconClass={category?.categoryTypeIconClass || undefined}
            iconColorHexCode={category?.categoryTypeColorHexCode || 'text-dark'}
            title={decode(versionTitle) || translation('article')}
            subTitleContent={
              version.lastModifiedBy ? (
                <div className='mt-1 ms-4'>
                  <i className='icon-edit fs-6 me-2' aria-hidden />
                  <span className='fs-6 fw-normal text-muted'>{`${translation(
                    'lastModifiedBy',
                  )}: ${version.lastModifiedBy}`}</span>
                </div>
              ) : undefined
            }>
            {version.pdfFileId && <ArticlePdfView />}
            {!version.pdfFileId && (
              <>
                <MarksButtonGroup />
                <ErrorBoundary fallback={<VersionContentFallback />}>
                  <VersionContent />
                </ErrorBoundary>
              </>
            )}
          </CustomCard>
          <div className='row mb-2'>
            {previousArticle && (
              <div className='col mt-2'>
                <Link
                  to={getArticleUrl(previousArticle.id || '')}
                  className='btn btn-outline-dark'>
                  {translation('previousArticle')}
                </Link>
              </div>
            )}
            {nextArticle && (
              <div className='col mt-2'>
                <Link
                  to={getArticleUrl(nextArticle?.id || '')}
                  className='btn btn-outline-dark float-end'>
                  {translation('nextArticle')}
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ArticleView;
