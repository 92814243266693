import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo } from 'react';
import TreeView from 'react-accessible-treeview';
import { Form } from 'react-bootstrap';
import parse from 'html-react-parser';
import { decode } from 'html-entities';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import {
  useGetApiCategoryTreeQuery,
  useGetApiVersionsByIdQuery,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import { getTreeItems } from '../content-tree/functions';
import {
  ContentTreeItems,
  IArticleTreeItem,
  ICategoryTreeItem,
} from '../content-tree/types';
import Loader from '../loader/Loader';
import ReferenceTreeItem from './reference-tree/ReferenceTreeItem';
import { ReferenceBehaviourString } from './types';
import { getTagsWithIds, getTextPassageIds } from './functions';

interface IInternalReferenceWithTextPassageFormProps {
  setSelectedReferences: (
    element: (IArticleTreeItem | ICategoryTreeItem)[],
  ) => void;
  selectedReferences: (IArticleTreeItem | ICategoryTreeItem)[] | null;
  selectedReferenceIsValid: boolean;
  setSelectedReferenceIsValid: (valid: boolean) => void;
  referenceBehaviour: ReferenceBehaviourString;
  setReferenceBehaviour: (referenceBehaviour: ReferenceBehaviourString) => void;
  textPassage: string | null;
  setTextPassage: (id: string | null) => void;
  textPassageIsValid: boolean;
  setTextPassageIsValid: (valid: boolean) => void;
}

function InternalReferenceWithTextPassageForm({
  setSelectedReferences,
  selectedReferences,
  selectedReferenceIsValid,
  setSelectedReferenceIsValid,
  referenceBehaviour,
  setReferenceBehaviour,
  textPassage,
  setTextPassage,
  textPassageIsValid,
  setTextPassageIsValid,
}: IInternalReferenceWithTextPassageFormProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const selectedArticleReference: IArticleTreeItem | null = selectedReferences
    ? (selectedReferences[0] as IArticleTreeItem)
    : null;
  const {
    data: contentTreeData,
    isFetching: getTreeIsFetching,
    isError: getTreeIsError,
    error: getTreeError,
  } = useGetApiCategoryTreeQuery();

  const {
    data: versionData,
    isFetching: getVersionIsFetching,
    isError: getVersionIsError,
    error: getVersionError,
  } = useGetApiVersionsByIdQuery(
    selectedArticleReference
      ? { id: selectedArticleReference.recentVersionId || '' }
      : skipToken,
  );

  const treeData = useMemo<ContentTreeItems>(() => {
    if (contentTreeData?.resultObject) {
      return getTreeItems(contentTreeData.resultObject);
    }
    return [];
  }, [contentTreeData]);

  const versionTextPassageIds = useMemo<string[]>(() => {
    if (versionData?.resultObject) {
      return getTextPassageIds(
        decode(versionData.resultObject.htmlContent || ''),
      );
    }
    return [];
  }, [versionData]);

  const versionTextPassages = useMemo<string[]>(() => {
    if (versionData?.resultObject) {
      return getTagsWithIds(decode(versionData.resultObject.htmlContent || ''));
    }
    return [];
  }, [versionData]);

  useEffect(() => {
    if (getTreeIsError) {
      dispatch(
        addMessage({
          id: 'GetTreeError',
          variant: 'danger',
          messageKeyBody:
            getTreeError && 'data' in getTreeError
              ? getTreeError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (getVersionIsError) {
      dispatch(
        addMessage({
          id: 'GetVersionError',
          variant: 'danger',
          messageKeyBody:
            getVersionError && 'data' in getVersionError
              ? getVersionError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getTreeIsError, getVersionIsError]);

  return (
    <div className='mt-3'>
      <fieldset
        aria-describedby={
          selectedReferenceIsValid ? undefined : 'SelectedReferenceIsError'
        }>
        <legend className='fw-bold fs-6'>
          {translation('selectArticleToBeLinked')}*
        </legend>
        <div
          className='max-350 border-top border-bottom'
          aria-busy={getTreeIsFetching}>
          {getTreeIsFetching && <Loader />}
          {contentTreeData && !getTreeIsFetching && treeData.length > 0 && (
            <TreeView
              id='ReferenceTree'
              data={treeData}
              selectedIds={selectedReferences?.map((s) => s.id) || []}
              onSelect={(e) => {
                if (e.isSelected) {
                  setSelectedReferences([
                    e.element as IArticleTreeItem | ICategoryTreeItem,
                  ]);
                  setSelectedReferenceIsValid(true);
                  setTextPassage(null);
                }
              }}
              nodeRenderer={({
                element,
                getNodeProps,
                level,
                isBranch,
                isSelected,
                isExpanded,
                handleSelect,
                handleExpand,
              }) =>
                ReferenceTreeItem({
                  isExpanded: isExpanded || false,
                  element: element as IArticleTreeItem | ICategoryTreeItem,
                  onlyHtmlArticleSelectable: true,
                  allowMultipleReferenceOnSameTarget: true,
                  isBranch,
                  isSelected,
                  level,
                  getNodeProps,
                  handleSelect,
                  handleExpand,
                })
              }
            />
          )}
        </div>
        {!selectedReferenceIsValid && (
          <Form.Control.Feedback
            id='SelectedReferenceIsError'
            type='invalid'
            className='d-block'>
            {translation('fieldNotEmpty')}
          </Form.Control.Feedback>
        )}
      </fieldset>
      {versionData && !getVersionIsFetching && (
        <fieldset
          aria-describedby={
            selectedReferenceIsValid ? undefined : 'TextPassageIsError'
          }>
          <div className='max-350 ps-1'>
            <legend className='mb-1 mt-3 fw-bold fs-6'>
              {translation('chooseTextPassage')}*
            </legend>
            {versionTextPassages.map((p, i) => (
              <Form.Check
                name='TextPassage'
                className='mt-2'
                key={versionTextPassageIds[i]}
                type='radio'
                id={`TextPassage_${versionTextPassageIds[i]}`}>
                <Form.Check.Input
                  checked={versionTextPassageIds[i] === textPassage}
                  onChange={(e) => {
                    setTextPassage(e.target.value);
                    setTextPassageIsValid(true);
                  }}
                  type='radio'
                  value={versionTextPassageIds[i]}
                />
                <Form.Check.Label>{parse(p)}</Form.Check.Label>
              </Form.Check>
            ))}
          </div>
          {!textPassageIsValid && (
            <Form.Control.Feedback
              id='TextPassageIsError'
              type='invalid'
              className='d-block'>
              {translation('fieldNotEmpty')}
            </Form.Control.Feedback>
          )}
        </fieldset>
      )}
      {textPassage && (
        <fieldset>
          <legend className='mb-1 mt-3 fw-bold fs-6'>
            {translation('chooseBehaviourOfReference')}*
          </legend>
          <Form.Check
            id='BidirectionalRadio'
            value='Bidirectional'
            onChange={(e) => {
              if (e.target.checked) {
                setReferenceBehaviour(
                  e.target.value as ReferenceBehaviourString,
                );
              }
            }}
            checked={referenceBehaviour === 'Bidirectional'}
            type='radio'
            name='ReferenceBehaviour'
            label={translation('referenceBehaviourBidirectional')}
          />
          <Form.Check
            id='ForwardOnlyRadio'
            value='ForwardOnly'
            onChange={(e) => {
              if (e.target.checked) {
                setReferenceBehaviour(
                  e.target.value as ReferenceBehaviourString,
                );
              }
            }}
            checked={referenceBehaviour === 'ForwardOnly'}
            type='radio'
            name='ReferenceBehaviour'
            label={translation('referenceBehaviourForwardOnly')}
          />
        </fieldset>
      )}
    </div>
  );
}

export default InternalReferenceWithTextPassageForm;
