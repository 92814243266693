import { useEffect, useMemo, useState } from 'react';
import TreeView from 'react-accessible-treeview';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useGetApiPermissionsQuery } from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import { selectContextUserGroup } from '../../../redux/store/user-management/slice';
import Loader from '../../loader/Loader';
import { getUserGroupTreeItems } from '../functions';
import { IUserGroupTreeItem, UserGroupTreeItems } from '../types';
import UserGroupPermissionsTreeItem from './UserGroupPermissionsTreeItem';

interface IUserGroupPermissionTreeProps {
  roleId: string;
}

function UserGroupPermissionsTree({ roleId }: IUserGroupPermissionTreeProps) {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const userGroup = useAppSelector(selectContextUserGroup);
  const [lastClickedElementId, setLastClickedElementId] = useState<
    string | null
  >(null);
  const {
    data: userGroupPermissionData,
    isFetching,
    isError,
    error,
  } = useGetApiPermissionsQuery(
    {
      roleId,
      userGroupId: userGroup.id || '',
    },
    { refetchOnMountOrArgChange: true },
  );

  const treeData = useMemo<UserGroupTreeItems>(() => {
    if (userGroupPermissionData?.resultObject?.userGroups) {
      return getUserGroupTreeItems(userGroupPermissionData.resultObject);
    }
    return [];
  }, [userGroupPermissionData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetPErmissionsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <div className='permission-container ps-1' aria-busy={isFetching}>
      {isFetching && <Loader />}
      {!isFetching &&
        userGroupPermissionData?.resultObject &&
        treeData.length > 1 && (
          <TreeView
            multiSelect
            clickAction='FOCUS'
            data={treeData}
            nodeRenderer={({
              element,
              getNodeProps,
              level,
              isBranch,
              isExpanded,
              handleExpand,
            }) => {
              const permission =
                userGroupPermissionData.resultObject?.permissions?.find(
                  (p) => p.targetId === element.id,
                );
              return UserGroupPermissionsTreeItem({
                lastClickedElementId,
                setLastClickedElementId,
                level,
                isBranch,
                isExpanded,
                getNodeProps,
                handleExpand,
                roleId,
                isSelected: !!permission,
                permissionId: permission?.id || null,
                element: element as IUserGroupTreeItem,
              });
            }}
          />
        )}
      {!isFetching && (!userGroupPermissionData || treeData.length === 0) && (
        <p>{translation('thereAreNoUserGroups')}</p>
      )}
    </div>
  );
}

export default UserGroupPermissionsTree;
