import { Form } from 'react-bootstrap';
import { decode } from 'html-entities';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSearchParams } from 'react-router-dom';
import { ICategoryTreeItem } from '../../content-tree/types';
import {
  articleIdUrlParam,
  categoryIdUrlParam,
} from '../../../shared/constants';
import {
  useGetApiArticlesReferencesByIdQuery,
  useGetApiCategoriesArticleReferencesByIdQuery,
} from '../../../redux/store/api/api';

interface IReferenceCategoryTreeItemProps {
  categoryElement: ICategoryTreeItem;
  selectable: boolean;
  isSelected: boolean;
  handleSelect: Function;
  allowMultipleReferenceOnSameTarget?: boolean;
  allowDisabling?: boolean;
}

function ReferenceCategoryTreeItem({
  categoryElement,
  selectable,
  isSelected,
  handleSelect,
  allowMultipleReferenceOnSameTarget,
  allowDisabling,
}: IReferenceCategoryTreeItemProps) {
  const [searchParams] = useSearchParams();
  const categoryId: string | null = searchParams.get(categoryIdUrlParam);
  const articleId: string | null = searchParams.get(articleIdUrlParam);
  const { data: referencesOfCategory } =
    useGetApiCategoriesArticleReferencesByIdQuery(
      categoryId ? { id: categoryId } : skipToken,
    );
  const { data: referencesOfArticle } = useGetApiArticlesReferencesByIdQuery(
    articleId
      ? {
          id: articleId,
        }
      : skipToken,
  );

  return (
    <>
      {selectable && (
        <Form.Check
          id={categoryElement.id}
          onClick={(e) => {
            handleSelect(e);
            e.stopPropagation();
          }}
          onChange={(e) => {
            e.stopPropagation();
          }}
          checked={isSelected}
          disabled={
            allowDisabling
              ? !allowMultipleReferenceOnSameTarget &&
                (!!referencesOfArticle?.resultObject?.articleReferenceLists?.find(
                  (r) =>
                    !!r.articleReferences?.find(
                      (a) => a.targetCategoryId === categoryElement.id,
                    ),
                ) ||
                  !!referencesOfCategory?.resultObject?.find(
                    (r) =>
                      !!r.articleReferences?.find(
                        (a) => a.targetCategoryId === categoryElement.id,
                      ),
                  ))
              : false
          }
          type='checkbox'
          label={
            <span className='visually-hidden'>
              {decode(categoryElement.name)}
            </span>
          }
        />
      )}
      <span>
        <i
          aria-hidden
          className={`me-1 ${categoryElement.categoryTypeIconClass}`}
          style={{
            color: categoryElement.categoryTypeColorHexCode,
          }}
        />
        {decode(categoryElement.name)}
      </span>
    </>
  );
}

ReferenceCategoryTreeItem.defaultProps = {
  allowMultipleReferenceOnSameTarget: false,
  allowDisabling: true,
};

export default ReferenceCategoryTreeItem;
