import { decode } from 'html-entities';
import parse from 'html-react-parser';
import { RecentChangesItem } from '../../redux/store/api/api';
import CustomListGroup from '../lists/CustomListGroup';
import './LastChanges.scss';
import { getArticleUrl } from '../../shared/urlBuilder';

interface ILastChangesProps {
  recentChanges: RecentChangesItem[];
}

function LastChangesItems({ recentChanges }: ILastChangesProps): JSX.Element {
  const getLastChangesItem = (item: RecentChangesItem): JSX.Element => (
    <div className='last-changes-item-header'>
      <p className='text-break d-flex'>
        <i
          className={`me-2 align-text-top ${item.iconCssClass || ''}`}
          style={item.iconColor ? { color: item.iconColor } : undefined}
          aria-hidden
        />
        <span>
          {item.categoryAbbreviation ? `${item.categoryAbbreviation}, ` : ''}
          {`${parse(decode(item.versionTitle))} (${item.versionName}, ${
            item.validFrom
              ? new Date(item.validFrom).toLocaleDateString('de-DE')
              : ''
          })`}
        </span>
      </p>
      <span className='last-changes-date text-muted'>
        {item.lastModified
          ? new Date(item.lastModified).toLocaleDateString('de-DE')
          : ''}
      </span>
    </div>
  );

  return (
    <CustomListGroup
      listItems={recentChanges.map((r, index) => ({
        id: index.toString(),
        href: getArticleUrl(r.articleId || '', r.versionId || undefined),
        content: getLastChangesItem(r),
      }))}
    />
  );
}

export default LastChangesItems;
