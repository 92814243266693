import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsQuery,
  usePostApiUserGroupsMutation,
} from '../../../redux/store/api/api';
import {
  addExpandedUserGroup,
  clearSelectedUsers,
  selectAddUserGroupDialogOpened,
  selectContextUserGroup,
  setAddUserGroupDialogOpened,
} from '../../../redux/store/user-management/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import UserGroupForm from '../UserGroupForm';
import Loader from '../../loader/Loader';

function AddUserGroupDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const userGroupParent = useAppSelector(selectContextUserGroup);
  const dialogShow = useAppSelector(selectAddUserGroupDialogOpened);
  const [userGroupName, setUserGroupName] = useState<string>('');
  const [userGroupNameIsValid, setUserGroupNameIsValid] = useState(true);
  const [maxUsersInGroup, setMaxUsersInGroup] = useState<number>(-1);
  const [maxUsersInTreeOfGroup, setMaxUsersInTreeOfGroup] =
    useState<number>(-1);
  const [unlimitedUsersInGroup, setUnlimitedUsersInGroup] =
    useState<boolean>(true);
  const [unlimitedUsersInTreeOfGroup, setUnlimitedUsersInTreeOfGroup] =
    useState<boolean>(true);
  const {
    refetch,
    isError: getUserGroupsIsError,
    error: getUserGroupsError,
  } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });
  const [
    addUserGroup,
    { isError: postUserGroupIsError, isLoading, error: postUserGroupError },
  ] = usePostApiUserGroupsMutation();

  useEffect(() => {
    if (getUserGroupsIsError) {
      dispatch(
        addMessage({
          id: 'GetUserGroupsError',
          variant: 'danger',
          messageKeyBody:
            getUserGroupsError && 'data' in getUserGroupsError
              ? getUserGroupsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (postUserGroupIsError) {
      dispatch(
        addMessage({
          id: 'CreatUserGroupError',
          variant: 'danger',
          messageKeyBody:
            postUserGroupError && 'data' in postUserGroupError
              ? postUserGroupError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getUserGroupsIsError, postUserGroupIsError]);

  const resetInputValues = () => {
    setUserGroupName('');
    setMaxUsersInGroup(-1);
    setMaxUsersInTreeOfGroup(-1);
    setUnlimitedUsersInGroup(true);
    setUnlimitedUsersInTreeOfGroup(true);
    setUserGroupNameIsValid(true);
  };

  const handleAddUserGroup = (): void => {
    if (userGroupName.trim().length === 0) {
      setUserGroupNameIsValid(false);
      return;
    }

    const maxInUserGroup = unlimitedUsersInGroup ? -1 : maxUsersInGroup;
    addUserGroup({
      userGroup: {
        name: userGroupName.trim(),
        userGroupMemberMaximum: maxInUserGroup,
        userGroupTreeMemberMaximum: maxUsersInTreeOfGroup,
        parentId: userGroupParent.id,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'CreateUserGroupSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(addExpandedUserGroup(userGroupParent.id || ''));
        dispatch(setAddUserGroupDialogOpened(false));
        resetInputValues();
        dispatch(clearSelectedUsers());
      });
  };

  return (
    <CustomDialog
      titleId='AddUserGroupDialog'
      actionButtonDisabled={
        (!unlimitedUsersInGroup && maxUsersInGroup < 1) ||
        (!unlimitedUsersInTreeOfGroup && maxUsersInTreeOfGroup < 1) ||
        isLoading
      }
      dialogTitle={translation('addUserGroup')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setAddUserGroupDialogOpened(false));
        resetInputValues();
      }}
      actionFunction={handleAddUserGroup}
      closeTitle={translation('cancel')}
      actionTitle={translation('add')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <UserGroupForm
          userGroupName={userGroupName}
          maxUsersInGroup={maxUsersInGroup}
          maxUsersInTreeOfGroup={maxUsersInTreeOfGroup}
          unlimitedUsersInGroup={unlimitedUsersInGroup}
          unlimitedUsersInTreeOfGroup={unlimitedUsersInTreeOfGroup}
          setUserGroupName={setUserGroupName}
          setMaxUsersInGroup={setMaxUsersInGroup}
          setMaxUsersInTreeOfGroup={setMaxUsersInTreeOfGroup}
          setUnlimitedUsersInGroup={setUnlimitedUsersInGroup}
          setUnlimitedUsersInTreeOfGroup={setUnlimitedUsersInTreeOfGroup}
          userGroupNameIsValid={userGroupNameIsValid}
          setUserGroupNameIsValid={setUserGroupNameIsValid}
        />
      )}
    </CustomDialog>
  );
}

export default AddUserGroupDialog;
