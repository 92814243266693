import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import { Navbar, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import './NavMenu.scss';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import NavbarDropdowns from '../components/navbar-links/NavbarDropdowns';
import {
  mdBreakpoint,
  settingTypeLogo,
  smBreakpoint,
  xlBreakpoint,
} from '../shared/constants';
import authService from '../components/api-authorization/AuthorizeService';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  addMessage,
  selectSidebarOpened,
  setSidebarOpened,
} from '../redux/store/layout/slice';
import logo from '../assets/images/Logo-colored.png';
import SearchFilterInNavbar from '../components/search/SearchFilterInNavbar';
import SearchFieldInNavbar from '../components/search/SearchFieldInNavbar';
import { useGetApiSettingsUserQuery } from '../redux/store/api/api';
import { useFilesByIdMutation } from '../redux/store/api/fileApi';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';

function NavMenu(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sidebarOpened = useAppSelector(selectSidebarOpened);
  const { width } = useWindowSize();
  const widthIsSmallerThanSm = width < smBreakpoint;
  const refSearchFilter = useRef(null);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [searchFilterOpened, setSearchFilterOpened] = useState(false);
  const checkAuthenticatedState = async () => {
    const isAuthenticated = await authService.isAuthenticated();
    setAuthenticated(isAuthenticated);
  };
  const [displayedLogo, setDisplayedLogo] = useState<undefined | string>(
    undefined,
  );
  const { data: settings, error } = useGetApiSettingsUserQuery(
    authenticated ? undefined : skipToken,
  );

  useEffect(() => {
    if ((error as any)?.status === 401) {
      navigate(ApplicationPaths.Login, { replace: true });
    }
  }, [error]);

  const logoSetting = settings?.resultObject?.find(
    (s) => s.settingType?.key === settingTypeLogo,
  );
  const [getLogo] = useFilesByIdMutation();

  useEffect(() => {
    if (!settings) {
      return;
    }

    if (logoSetting?.value) {
      getLogo({ id: logoSetting.value })
        .unwrap()
        .then((logoObjectUrl) => {
          setDisplayedLogo(logoObjectUrl);
        })
        .catch(() => {
          dispatch(
            addMessage({
              id: 'GetLogo',
              messageKeyBody: 'unknownError',
              variant: 'danger',
            }),
          );
        });
    } else {
      setDisplayedLogo(logo);
    }
  }, [settings]);

  useLayoutEffect(() => {
    checkAuthenticatedState();
  }, []);

  useOnClickOutside(refSearchFilter, (e) => {
    if ((e.target as HTMLElement).id !== 'SearchKeywordInHeader') {
      setSearchFilterOpened(false);
    }
  });

  return (
    <header className={sidebarOpened ? 'margin-sidebar' : undefined}>
      {authenticated && (
        <Navbar
          className={`${
            width < mdBreakpoint ? 'justify-content-between' : ''
          }`}>
          {width < mdBreakpoint && (
            <Link to='/' aria-label={translation('logo')}>
              <img
                src={displayedLogo}
                alt=''
                width={`${widthIsSmallerThanSm ? '100px' : '150px'}`}
              />
            </Link>
          )}
          <div
            className={`d-flex w-100 ${
              width >= xlBreakpoint ? 'justify-content-center' : 'ms-4'
            }`}>
            {width >= mdBreakpoint && (
              <SearchFieldInNavbar
                searchFilterOpened={searchFilterOpened}
                setSearchFilterOpened={setSearchFilterOpened}
              />
            )}
            <div className='d-flex me-3 navbar-btn-container'>
              {width < mdBreakpoint && (
                <Button
                  aria-expanded={sidebarOpened}
                  aria-controls='Sidebar'
                  variant='light'
                  className='btn-hover-round'
                  title={
                    sidebarOpened
                      ? translation('closeSidebar')
                      : translation('openSidebar')
                  }
                  aria-label={
                    sidebarOpened
                      ? translation('closeSidebar')
                      : translation('openSidebar')
                  }
                  onClick={() => {
                    dispatch(setSidebarOpened(!sidebarOpened));
                  }}>
                  {sidebarOpened ? (
                    <i className='icon-sidebar_close fs-4' aria-hidden />
                  ) : (
                    <i className='icon-sidebar_open fs-4' aria-hidden />
                  )}
                </Button>
              )}
              {width < mdBreakpoint && (
                <Link
                  className='rounded-circle btn btn-outline-dark header-active-background circle-btn'
                  title={translation('search')}
                  aria-label={translation('search')}
                  to='/search'>
                  <i className='icon-search' aria-hidden />
                </Link>
              )}
              <NavbarDropdowns />
            </div>
          </div>
        </Navbar>
      )}
      {authenticated && width >= smBreakpoint && searchFilterOpened && (
        <div className='search-container'>
          <SearchFilterInNavbar setSearchFilterOpened={setSearchFilterOpened} />
        </div>
      )}
    </header>
  );
}

export default NavMenu;
