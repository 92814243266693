import { VERSION_CONTENT_CONTAINER_ID } from '../../../shared/constants';
import preprocessRange from './preprocessRange';

const validateRange = (range: Range): Range | null => {
  const root = document.getElementById(VERSION_CONTENT_CONTAINER_ID);
  const r = preprocessRange(range);

  if (!r) {
    return null;
  }

  // The range is valid, if the root container contains start- AND end container of the range.
  if (root?.contains(r.startContainer) && root?.contains(r.endContainer)) {
    return range;
  }

  return null;
};

const getRange = (selection: Selection | null): Range | null => {
  let r;
  if (selection && selection.rangeCount > 0) {
    r = selection.getRangeAt(0);
  }

  return r ? validateRange(r) : null;
};

export { validateRange, getRange };
