import { useEffect, useMemo, useState } from 'react';
import TreeView from 'react-accessible-treeview';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useGetApiPermissionsQuery } from '../../../redux/store/api/api';
import { selectContextUserGroup } from '../../../redux/store/user-management/slice';
import { ContentTreeItems, ICategoryTreeItem } from '../../content-tree/types';
import Loader from '../../loader/Loader';
import ContentPermissionsTreeItem from './ContentPermissionsTreeItem';
import { addMessage } from '../../../redux/store/layout/slice';
import { getTreeCategories } from '../../content-tree/functions';

interface IContentSettingsTreeProps {
  roleId: string;
}

function ContentPermissionsTree({ roleId }: IContentSettingsTreeProps) {
  const dispatch = useAppDispatch();
  const { t: translation } = useTranslation();
  const userGroup = useAppSelector(selectContextUserGroup);
  const [lastClickedElementId, setLastClickedElementId] = useState<
    string | null
  >(null);
  const {
    data: categoryPermissionsData,
    isFetching,
    isError,
    error,
  } = useGetApiPermissionsQuery(
    {
      roleId,
      userGroupId: userGroup.id || '',
    },
    { refetchOnMountOrArgChange: true },
  );

  const treeData = useMemo<ContentTreeItems>(() => {
    if (categoryPermissionsData?.resultObject) {
      const data = getTreeCategories(categoryPermissionsData.resultObject);
      return data;
    }
    return [];
  }, [categoryPermissionsData]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetPermissionsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  return (
    <div className='permission-container ps-1' aria-busy={isFetching}>
      {isFetching && <Loader />}
      {!isFetching &&
        categoryPermissionsData?.resultObject &&
        treeData.length > 1 && (
          <TreeView
            multiSelect
            clickAction='FOCUS'
            data={treeData}
            nodeRenderer={({
              element,
              getNodeProps,
              level,
              isBranch,
              isExpanded,
              handleExpand,
            }) => {
              const permission =
                categoryPermissionsData.resultObject?.permissions?.find(
                  (p) => p.targetId === element.id,
                );
              return ContentPermissionsTreeItem({
                lastClickedElementId,
                setLastClickedElementId,
                level,
                isBranch,
                isExpanded,
                getNodeProps,
                handleExpand,
                roleId,
                isSelected:
                  !!permission &&
                  (permission?.recursiveInheritanceEnabled || false),
                isHalfSelected:
                  !!permission &&
                  !(permission?.recursiveInheritanceEnabled || false),
                element: element as ICategoryTreeItem,
                permissionId: permission?.id || null,
              });
            }}
          />
        )}
      {!isFetching && (!categoryPermissionsData || treeData.length === 0) && (
        <p>{translation('thereAreNoCategories')}</p>
      )}
    </div>
  );
}

export default ContentPermissionsTree;
