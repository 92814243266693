import { decode } from 'html-entities';
import parse from 'html-react-parser';
import {
  SearchCategoryResultItem,
  useGetApiCategoryTreeQuery,
} from '../../redux/store/api/api';
import CustomListGroup from '../lists/CustomListGroup';
import { getCategoryReferenceUrl } from '../../shared/urlBuilder';

interface ISearchCategoryResultProps {
  searchCategoryResult: SearchCategoryResultItem[];
  foundTerms: string[];
}

function SearchCategoryResult({
  searchCategoryResult,
  foundTerms,
}: ISearchCategoryResultProps): JSX.Element {
  const { data: contentTreeData } = useGetApiCategoryTreeQuery();

  function getCategory(categoryId: string | undefined | null) {
    if (categoryId) {
      return (
        contentTreeData?.resultObject?.categories?.find(
          (c) => c.id === categoryId,
        ) || undefined
      );
    }
    return undefined;
  }

  const getResultItem = (resultItem: SearchCategoryResultItem): JSX.Element => (
    <>
      <div className='result-item-header'>
        <p className='text-break fw-semibold d-flex mb-0'>
          <i
            className={`me-2 ${
              getCategory(resultItem.categoryId)?.categoryTypeIconClass || ''
            }`}
            style={
              getCategory(resultItem.categoryId)?.categoryTypeColorHexCode
                ? {
                    color:
                      getCategory(resultItem.categoryId)
                        ?.categoryTypeColorHexCode || '',
                  }
                : undefined
            }
            aria-hidden
          />
          <span>
            {getCategory(resultItem.categoryId)?.abbreviation
              ? `${getCategory(resultItem.categoryId)?.abbreviation}, `
              : ''}
            {parse(decode(getCategory(resultItem.categoryId)?.name))}
          </span>
        </p>
      </div>
      <div className='text-muted fs-6 fst-italic'>
        (
        {decode(
          getCategory(getCategory(resultItem.categoryId)?.parentId)
            ?.permittedPath || '',
        )}
        )
      </div>
    </>
  );

  return (
    <CustomListGroup
      listItems={searchCategoryResult.map((r, index) => ({
        id: index.toString(),
        href: getCategoryReferenceUrl(
          r.categoryId || '',
          r.articleIdsWithFoundTerms || undefined,
          foundTerms || undefined,
        ),
        content: getResultItem(r),
      }))}
    />
  );
}

export default SearchCategoryResult;
