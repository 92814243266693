type NodeProperty =
  | 'parentNode'
  | 'previousSibling'
  | 'nextSibling'
  | 'firstChild';

const isFiberNode = (node: Node) =>
  node?.nodeType === Node.TEXT_NODE &&
  node.textContent &&
  (node.textContent.match(/^\n$/g) || node.textContent.match(/^$/g)) &&
  node.textContent?.length === 1;

const isTextNode = (node: Node) =>
  node?.nodeType === Node.TEXT_NODE &&
  node.textContent &&
  !(node.textContent.match(/^\n$/g) || node.textContent.match(/^$/g));

const isMarkOrLink = (node: Node): boolean =>
  node.nodeName === 'MARK' || node.nodeName === 'A';

const isLink = (node: Node): boolean => node.nodeName === 'A';

const getNodeIgnoreFiberNodes = (
  node: Node,
  nodeProperty: NodeProperty,
): Node | null => {
  const n = node[`${nodeProperty}`] as Node;

  if (isFiberNode(n)) {
    return getNodeIgnoreFiberNodes(n, nodeProperty);
  }

  return n;
};

export {
  isFiberNode,
  isTextNode,
  isMarkOrLink,
  isLink,
  getNodeIgnoreFiberNodes,
};
