import { useTranslation } from 'react-i18next';
import { decode } from 'html-entities';
import { useLocalStorage } from 'usehooks-ts';
import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Article } from '../../redux/store/api/api';
import { useArticlesExportByIdMutation } from '../../redux/store/api/fileApi';
import {
  selectMoveElementsStarted,
  selectMovingElements,
  setActivateElementsDialogOpened,
  setElementsToRecycleBinDialogOpened,
  setContextArticle,
  setDisableElementsDialogOpened,
  setMoveElementsDialogOpened,
  setMoveElementsStarted,
  setMovingElements,
  setNewPositionForMovingElements,
  setRenameStructureElementDialogOpened,
} from '../../redux/store/content/slice';
import { addMessage, removeMessage } from '../../redux/store/layout/slice';
import { EDIT_MODE_KEY } from '../../shared/constants';
import { ArticleType, RightKey } from '../../shared/enums';
import {
  convertArticleTreeItemToArticle,
  downloadFile,
  getArticleIcon,
  getArticleTranslationKeyOfIconDescription,
} from '../../shared/utils';
import ContextMenu from '../dropdown-menus/ContextMenu';
import { ContextAction } from '../dropdown-menus/types';
import DescriptiveIcon from '../descriptive-icons/DescriptiveIcon';
import { IArticleTreeItem } from './types';
import { getEditArticleUrl, getEditDraftUrl } from '../../shared/urlBuilder';

interface IArticleTreeElementProps {
  articleTreeElement: IArticleTreeItem;
  permittedActions: RightKey[];
  handleSelect: Function;
  isSelected: boolean;
  selectedIds: string[];
}

function ArticleTreeElement({
  articleTreeElement,
  permittedActions,
  handleSelect,
  isSelected,
  selectedIds,
}: IArticleTreeElementProps): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dangerClass = 'text-danger';
  const successClass = 'text-success';
  const blackClass = 'text-body';
  const iconDeactivate = 'icon-deaktivieren';
  const iconActivate = 'icon-aktivieren';
  const [editModeIsActive] = useLocalStorage<boolean>(EDIT_MODE_KEY, false);
  const [exportArticle] = useArticlesExportByIdMutation();
  const moveElementsStarted = useAppSelector(selectMoveElementsStarted);
  const movingElements = useAppSelector(selectMovingElements);

  // permissions
  const userCanEditArticles = permittedActions.includes(
    RightKey.RightArticleManagementEditArticle,
  );
  const userCanEditCategories = permittedActions.includes(
    RightKey.RightCategoryManagementEditCategory,
  );
  const userCanDeleteArticles = permittedActions.includes(
    RightKey.RightArticleManagementDeleteArticle,
  );
  const userCanDeleteCategories = permittedActions.includes(
    RightKey.RightCategoryManagementDeleteCategory,
  );
  const userCanEditAndDeleteStructureElements = permittedActions.includes(
    RightKey.RightArticleManagementCreateEditDeleteStructureElement,
  );
  const article: Article = convertArticleTreeItemToArticle(articleTreeElement);
  const articleInTreeDisabled =
    article.versionsOnlyDrafts ||
    article.versionsDisabled ||
    article.disabled ||
    article.versionsInvalid;

  const getMovingElementsContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];
    const movingElementsCount = movingElements.elementIds.length;

    contextActions.push({
      iconClass: 'icon-move',
      iconColorClass: blackClass,
      name: translation(
        movingElementsCount === 1
          ? 'moveElementOverThisElement'
          : 'moveElementsOverThisElement',
      ),
      onClick: () => {
        dispatch(
          setNewPositionForMovingElements({
            newPosition: article.order || 0,
            targetCategory: { id: article.categoryId },
          }),
        );
        dispatch(setMoveElementsDialogOpened(true));
      },
    });

    contextActions.push({
      iconClass: 'icon-move',
      iconColorClass: blackClass,
      name: translation(
        movingElementsCount === 1
          ? 'moveElementBelowThisElement'
          : 'moveElementsBelowThisElement',
      ),
      onClick: () => {
        dispatch(
          setNewPositionForMovingElements({
            newPosition: (article.order || 0) + 1,
            targetCategory: { id: article.categoryId },
          }),
        );
        dispatch(setMoveElementsDialogOpened(true));
      },
      addDividerAfterItem: true,
    });

    contextActions.push({
      iconClass: iconDeactivate,
      iconColorClass: dangerClass,
      name: translation('stopMove'),
      onClick: () => {
        dispatch(setMoveElementsStarted(false));
      },
    });

    return contextActions;
  };

  const getToggleActivateContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    if (
      userCanEditAndDeleteStructureElements &&
      article.type === ArticleType.StructureElement
    ) {
      contextActions.push({
        helpId: 'help_2_2_7',
        iconClass: article.disabled ? iconActivate : iconDeactivate,
        iconColorClass: article.disabled ? successClass : dangerClass,
        name: translation(
          article.disabled
            ? 'activateStructureElement'
            : 'deactivateStructureElement',
        ),
        onClick: () => {
          dispatch(setContextArticle(article));
          if (article.disabled) {
            dispatch(setActivateElementsDialogOpened(true));
          }
          if (!article.disabled) {
            dispatch(setDisableElementsDialogOpened(true));
          }
        },
      });
    }

    if (userCanEditArticles && article.type !== ArticleType.StructureElement) {
      contextActions.push({
        helpId: 'help_2_2_7',
        iconClass: article.disabled ? iconActivate : iconDeactivate,
        iconColorClass: article.disabled ? successClass : dangerClass,
        name: translation(
          article.disabled ? 'activateArticle' : 'deactivateArticle',
        ),
        onClick: () => {
          dispatch(setContextArticle(article));
          if (article.disabled) {
            dispatch(setActivateElementsDialogOpened(true));
          }
          if (!article.disabled) {
            dispatch(setDisableElementsDialogOpened(true));
          }
        },
      });
    }

    return contextActions;
  };

  const getSelectedElementsContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    if (userCanEditArticles || userCanEditCategories) {
      contextActions.push({
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation('moveSelectedElements'),
        onClick: () => {
          dispatch(setMovingElements(selectedIds));
          dispatch(setMoveElementsStarted(true));
        },
      });

      contextActions.push({
        iconClass: iconActivate,
        iconColorClass: successClass,
        name: translation('activateSelectedElements'),
        onClick: () => {
          dispatch(setActivateElementsDialogOpened(true));
        },
      });

      contextActions.push({
        iconClass: iconDeactivate,
        iconColorClass: dangerClass,
        name: translation('deactivateSelectedElements'),
        onClick: () => {
          dispatch(setDisableElementsDialogOpened(true));
        },
      });
    }

    if (userCanDeleteArticles || userCanDeleteCategories) {
      contextActions.push({
        iconClass: 'icon-trash',
        iconColorClass: dangerClass,
        name: translation('moveSelectedElementsToRecycleBin'),
        onClick: () => {
          dispatch(setElementsToRecycleBinDialogOpened(true));
        },
      });
    }

    return contextActions;
  };

  const getContextActionsForEditMode = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    if (userCanEditArticles) {
      contextActions.push({
        helpId: 'help_2_2_8',
        iconClass: 'icon-move',
        iconColorClass: blackClass,
        name: translation(
          article.type === ArticleType.StructureElement
            ? 'startMoveStructureElement'
            : 'startMoveArticle',
        ),
        onClick: () => {
          dispatch(setMovingElements([article.id || '']));
          dispatch(setMoveElementsStarted(true));
        },
        addDividerAfterItem: true,
      });

      if (article.type !== ArticleType.StructureElement) {
        contextActions.push({
          iconClass: 'icon-edit',
          iconColorClass: blackClass,
          name: translation('editArticle'),
          href: article.versionsOnlyDrafts
            ? getEditDraftUrl(article.id || '', article.recentVersionId || '')
            : getEditArticleUrl(
                article.id || '',
                article.recentVersionId || '',
              ),
        });
      }
    }

    if (
      userCanEditAndDeleteStructureElements &&
      article.type === ArticleType.StructureElement
    ) {
      contextActions.push({
        iconClass: 'icon-edit',
        iconColorClass: blackClass,
        name: translation('renameStructureElement'),
        onClick: () => {
          dispatch(setContextArticle(article));
          dispatch(setRenameStructureElementDialogOpened(true));
        },
      });
    }

    contextActions.push(...getToggleActivateContextActions());

    if (userCanDeleteArticles) {
      contextActions.push({
        helpId: 'help_2_2_4',
        iconClass: 'icon-trash',
        iconColorClass: dangerClass,
        name: translation(
          article.type === ArticleType.StructureElement
            ? 'moveStructureElementToRecycleBin'
            : 'moveArticleToRecycleBin',
        ),
        onClick: () => {
          dispatch(setContextArticle(article));
          dispatch(setElementsToRecycleBinDialogOpened(true));
        },
        addDividerAfterItem: article.type !== ArticleType.StructureElement,
      });
    }

    return contextActions;
  };

  const getContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];

    if (editModeIsActive) {
      if (selectedIds.length > 0 && !moveElementsStarted) {
        contextActions.push(...getSelectedElementsContextActions());

        return contextActions;
      }

      if (moveElementsStarted) {
        return getMovingElementsContextActions();
      }
      contextActions.push(...getContextActionsForEditMode());
    }

    if (article.type !== ArticleType.StructureElement) {
      contextActions.push({
        iconClass: 'icon-einzel_pdf',
        iconColorClass: dangerClass,
        name: translation('exportArticleAsPdf'),
        onClick: () => {
          dispatch(
            addMessage({
              id: 'GeneratePdf',
              messageKeyHeader: 'pdfIsGenerating',
              messageKeyBody: 'pleaseBePatient',
              variant: 'info',
              timeInMs: 9000000000,
            }),
          );
          exportArticle({
            id: article.id || '',
          })
            .unwrap()
            .then((url) => {
              downloadFile(
                url,
                `${decode(article.recentVersionTitle)?.replace(/\s/g, '')}.pdf`,
              );
              dispatch(removeMessage('GeneratePdf'));
              dispatch(
                addMessage({
                  id: 'GeneratePdfSuccess',
                  messageKeyBody: 'generatePdfSuccess',
                  variant: 'success',
                }),
              );
            })
            .catch(() => {
              dispatch(removeMessage('GeneratePdf'));
              dispatch(
                addMessage({
                  id: 'ExportArticleError',
                  messageKeyBody: 'unknownError',
                  variant: 'danger',
                }),
              );
            });
        },
      });

      contextActions.push({
        iconClass: 'icon-versions_pdf',
        iconColorClass: dangerClass,
        name: translation('exportArticleVersionsAsPdf'),
        onClick: () => {
          dispatch(
            addMessage({
              id: 'GenerateVersionPdf',
              messageKeyHeader: 'pdfIsGenerating',
              messageKeyBody: 'pleaseBePatient',
              variant: 'info',
              timeInMs: 9000000000,
            }),
          );
          exportArticle({
            id: article.id || '',
            includeVersions: true,
          })
            .unwrap()
            .then((url) => {
              downloadFile(
                url,
                `${decode(article.recentVersionTitle).replace(
                  /\s/g,
                  '',
                )}_${translation('allVersionsPdfExtension')}.pdf`,
              );
              dispatch(removeMessage('GenerateVersionPdf'));
              dispatch(
                addMessage({
                  id: 'GenerateVersionPdfSuccess',
                  messageKeyBody: 'generatePdfSuccess',
                  variant: 'success',
                }),
              );
            })
            .catch(() => {
              dispatch(removeMessage('GenerateVersionPdf'));
              dispatch(
                addMessage({
                  id: 'ExportArticleError',
                  messageKeyBody: 'unknownError',
                  variant: 'danger',
                }),
              );
            });
        },
      });
    }

    return contextActions;
  };

  return (
    <div className='d-flex pe-5'>
      {editModeIsActive && (userCanEditArticles || userCanDeleteArticles) && (
        <Form.Check
          className='ms-1 mt-1 me-1 border-light'
          type='checkbox'
          onChange={(e) => {
            handleSelect(e);
            e.stopPropagation();
          }}
          checked={isSelected}
          id={`${decode(article.recentVersionTitle)}_${article.id}`}
        />
      )}
      <div
        className={`d-flex w-100${articleInTreeDisabled ? ' text-muted' : ''}`}>
        {article.disabled && (
          <DescriptiveIcon
            iconClass='me-1 icon-deaktivieren'
            colorClass='text-danger'
            description={translation(
              article.type === ArticleType.StructureElement
                ? 'stuctureElementIsDisabled'
                : 'articleIsDisabled',
            )}
          />
        )}
        {!article.disabled && article.versionsOnlyDrafts && (
          <DescriptiveIcon
            iconClass='me-1 icon-entwurf'
            description={translation('onlyDraftsForThisArticle')}
          />
        )}
        {!article.disabled &&
          !article.versionsOnlyDrafts &&
          article.versionsDisabled && (
            <DescriptiveIcon
              iconClass={`me-1 ${getArticleIcon(
                article.type || ArticleType.Html,
              )}`}
              description={translation('onlyDisabledForThisArticle')}
            />
          )}
        {!article.disabled &&
          !article.versionsOnlyDrafts &&
          !article.versionsDisabled &&
          article.versionsInvalid && (
            <DescriptiveIcon
              iconClass={`me-1 ${getArticleIcon(
                article.type || ArticleType.Html,
              )}`}
              description={translation('noValidVersion')}
            />
          )}
        {!articleInTreeDisabled && (
          <DescriptiveIcon
            iconClass={`me-1 ${getArticleIcon(
              article.type || ArticleType.Html,
            )}`}
            description={translation(
              getArticleTranslationKeyOfIconDescription(
                article.type || ArticleType.Html,
              ),
            )}
          />
        )}
        <span
          className='text-truncate me-2'
          title={decode(article.recentVersionTitle)}>
          {decode(article.recentVersionTitle)}
        </span>
      </div>
      {(editModeIsActive || article.type !== ArticleType.StructureElement) && (
        <ContextMenu contextActions={getContextActions()} />
      )}
    </div>
  );
}

export default ArticleTreeElement;
