import { Form } from 'react-bootstrap';
import { paddingOffsetForTreeElements } from '../../shared/constants';
import { ICategoryTreeItem } from '../content-tree/types';
import { RightKey } from '../../shared/enums';

interface IRestoreTreeItemProps {
  level: number;
  isBranch: boolean;
  isExpanded: boolean | undefined;
  getNodeProps: Function;
  selectedCategoryId: string | null;
  setSelectedCategoryId: (categoryId: string) => void;
  element: ICategoryTreeItem;
}

function RestoreTreeItem({
  level,
  isBranch,
  isExpanded,
  getNodeProps,
  selectedCategoryId,
  setSelectedCategoryId,
  element,
}: IRestoreTreeItemProps) {
  const elementIsChecked = selectedCategoryId === element.id;

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...getNodeProps()}
      style={{ paddingLeft: paddingOffsetForTreeElements * (level - 1) }}>
      <div className='d-flex'>
        {isBranch && isExpanded && (
          <i className='me-2 mt-1 fs-5 icon-carretup' aria-hidden />
        )}
        {isBranch && !isExpanded && (
          <i className='me-2 mt-1 fs-5 icon-carretdown' aria-hidden />
        )}
        <Form.Check
          id={element.id}
          disabled={
            !element.permittedActions?.includes(
              RightKey.RightArticleManagementCreateArticle,
            )
          }
          type='checkbox'
          label={element.name}
          checked={elementIsChecked}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            if (e.code === 'Space' && !elementIsChecked) {
              e.stopPropagation();
              setSelectedCategoryId(element.id);
            }
          }}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedCategoryId(element.id);
            }
          }}
        />
      </div>
    </div>
  );
}

export default RestoreTreeItem;
