import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TreeView from 'react-accessible-treeview';
import CustomDialog from '../../../dialogs/CustomDialog';
import { getBookmarkFolderTreeItems } from '../../../bookmarks/functions';
import {
  BookmarkTreeItems,
  IBookmarkFolderTreeItem,
} from '../../../bookmarks/types';
import {
  useGetApiBookmarkFoldersQuery,
  usePostApiBookmarksMutation,
} from '../../../../redux/store/api/api';
import BookmarkFolderTreeItem from './BookmarkFolderTreeItem';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  selectAddBookmarkDialogOpened,
  selectContextVersion,
  setAddBookmarkDialogOpened,
} from '../../../../redux/store/content/slice';
import Loader from '../../../loader/Loader';
import { addMessage } from '../../../../redux/store/layout/slice';
import useGetCategoryByArticleId from '../../../../hooks/useGetCategoryByArticleId';
import { getVersionName } from '../../../../shared/utils';

function CreateBookmarkDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectAddBookmarkDialogOpened);
  const { t: translation } = useTranslation();
  const version = useAppSelector(selectContextVersion);
  const category = useGetCategoryByArticleId(version.articleId);
  const [expandedBookmarkFolders, setExpandedBookmarkFolders] = useState<
    string[]
  >([]);
  const defaultName = `${
    category?.abbreviation ? `${category.abbreviation}, ` : ''
  }${version.title || ''} ${getVersionName(
    version.name || '',
    translation('versionFrom'),
    version.validFrom || '',
  )}`;
  const [bookmarkName, setBookmarkName] = useState(defaultName);
  const [isValidName, setIsValidName] = useState(true);
  const [selectedBookmarkFolderId, setSelectedBookmarkFolderId] = useState('');
  const [isValidBookmarkFolder, setIsValidBookmarkFolder] = useState(true);
  const {
    data: bookmarkTreeData,
    refetch,
    isFetching,
  } = useGetApiBookmarkFoldersQuery();
  const [addBookmark, { isLoading, isError, error }] =
    usePostApiBookmarksMutation();

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'CreateBookmarkError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    if (dialogShow) {
      setBookmarkName(defaultName);
    }
  }, [dialogShow]);

  const treeData = useMemo<BookmarkTreeItems>(() => {
    if (bookmarkTreeData?.resultObject) {
      return getBookmarkFolderTreeItems(bookmarkTreeData.resultObject);
    }
    return [];
  }, [bookmarkTreeData]);

  const handleAddBookmark = () => {
    if (bookmarkName.trim().length === 0 || !selectedBookmarkFolderId) {
      if (bookmarkName.trim().length === 0) {
        setIsValidName(false);
      }

      if (!selectedBookmarkFolderId) {
        setIsValidBookmarkFolder(false);
      }

      return;
    }

    addBookmark({
      bookmark: {
        name: bookmarkName.trim(),
        bookmarkFolderId: selectedBookmarkFolderId,
        versionId: version.id || '',
        articleId: version.articleId || '',
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'CreateBookmarkSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        dispatch(setAddBookmarkDialogOpened(false));
        refetch();
      });
  };

  return (
    <CustomDialog
      titleId='CreateBookmarkDialog'
      show={dialogShow}
      closeFunction={() => {
        dispatch(setAddBookmarkDialogOpened(false));
      }}
      actionFunction={handleAddBookmark}
      actionTitle={translation('add')}
      closeTitle={translation('close')}
      dialogTitle={translation('addBookmark')}
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <Form.Group className='mb-3' controlId='BookmarkName'>
            <Form.Label>{translation('name')}*</Form.Label>
            <Form.Control
              required
              aria-describedby={
                isValidName ? undefined : 'BookmarkNameInputErrors'
              }
              isInvalid={!isValidName}
              onChange={(e) => {
                setBookmarkName(e.target.value);
                if (e.target.value.trim().length > 0) {
                  setIsValidName(true);
                }
              }}
              type='text'
              value={bookmarkName}
            />
            <Form.Control.Feedback id='BookmarkNameInputError' type='invalid'>
              {translation('fieldNotEmpty')}
            </Form.Control.Feedback>
          </Form.Group>
          <fieldset
            aria-describedby={
              isValidBookmarkFolder ? undefined : 'BookmarkFolderIsError'
            }>
            <legend className='mb-1 fs-6'>
              {translation('chooseAFolder')}*
            </legend>
            {isFetching && <Loader />}
            {!isFetching && (
              <TreeView
                className='mb-2'
                id='BookmarkFolderTree'
                data={treeData}
                onSelect={(e) => {
                  if (e.isSelected) {
                    setIsValidBookmarkFolder(true);
                    setSelectedBookmarkFolderId(
                      (e.element as IBookmarkFolderTreeItem).id,
                    );
                  }
                }}
                expandedIds={expandedBookmarkFolders}
                onExpand={(e) => {
                  const { id } = e.element as IBookmarkFolderTreeItem;
                  if (e.isExpanded) {
                    const ids = [...expandedBookmarkFolders];
                    ids.push(id);
                    setExpandedBookmarkFolders(ids);
                  } else {
                    setExpandedBookmarkFolders(
                      expandedBookmarkFolders.filter((f) => f !== id),
                    );
                  }
                }}
                nodeRenderer={({
                  element,
                  getNodeProps,
                  handleExpand,
                  level,
                  isExpanded,
                  isBranch,
                  handleSelect,
                  isSelected,
                }) =>
                  BookmarkFolderTreeItem({
                    level,
                    isBranch,
                    isExpanded,
                    handleExpand,
                    getNodeProps,
                    handleSelect,
                    isSelected,
                    element: element as IBookmarkFolderTreeItem,
                  })
                }
              />
            )}
            {!isValidBookmarkFolder && (
              <Form.Control.Feedback
                id='BookmarkFolderIsError'
                type='invalid'
                className='d-block'>
                {translation('fieldNotEmpty')}
              </Form.Control.Feedback>
            )}
          </fieldset>
          <p>{translation('fieldsAreRequiredLegend')}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default CreateBookmarkDialog;
