export enum ElementType {
    None = 0,
    Category = 1,
    Article = 2
}
export enum DataType {
    String = 0,
    Integer = 1,
    Text = 2,
    List = 3,
    Image = 4,
    TinySelector = 5,
    Bool = 6
}
export enum SettingContainerType {
    ApplicationSetting = 0,
    ReportError = 1,
    Editor = 2,
    EmailServerSettings = 3,
    EmailTemplates = 4,
    RecentChanges = 5,
    AdditionalPageContent = 6,
    ContentSettings = 7,
    ApplicationSettingUserGroup = 8,
    AllGlobalSettings = 9
}
export enum PermissionContext {
    UserGroup = 1,
    Category = 2,
    System = 4
}
export enum NoteVisibilityType {
    General = 0,
    UserGroup = 1,
    User = 2
}
export enum ArticleType {
    Html = 0,
    Pdf = 1,
    StructureElement = 2
}
export enum SearchResultSort {
    ByRelevance = 0,
    ByDate = 1
}
export enum ReferenceBehaviour {
    Bidirectional = 0,
    ForwardOnly = 1,
    BackwardOnly = 2
}
export enum RightKey {
    RightRoleManagementReadRole = 0,
    RightRoleManagementCreateRole = 1,
    RightRoleManagementEditRole = 2,
    RightRoleManagementDeleteRole = 3,
    RightRoleManagementAddRightToRole = 4,
    RightRoleManagementRemoveRightFromRole = 5,
    RightSystemManagementUseSupport = 6,
    RightPermissionManagementCreate = 7,
    RightPermissionManagementDelete = 8,
    RightPermissionManagementRead = 9,
    RightRoleManagementReadRights = 10,
    RightCategoryTypeManagementRead = 11,
    RightCategoryTypeManagementCreate = 12,
    RightCategoryTypeManagementEdit = 13,
    RightCategoryTypeManagementDelete = 14,
    RightCategoryTypeManagementAssign = 15,
    RightCategoryManagementReadCategory = 16,
    RightCategoryManagementCreateCategory = 17,
    RightCategoryManagementEditCategory = 18,
    RightCategoryManagementDeleteCategory = 19,
    RightCategoryManagementProtectCategory = 20,
    RightCategoryManagementChangeCategoryType = 21,
    RightCategoryManagementChangeLastChangedDate = 22,
    RightCategoryManagementImportArticles = 23,
    RightCategoryManagementChangePublishDate = 24,
    RightArticleManagementReadArticle = 25,
    RightArticleManagementCreateArticle = 26,
    RightArticleManagementEditArticle = 27,
    RightArticleManagementDeleteArticle = 28,
    RightArticleManagementCreatePdfArticleVersion = 29,
    RightArticleManagementCreateEditDeleteStructureElement = 30,
    RightArticleManagementChangeValidFromDateRecursively = 31,
    RightNotesManagementCreateEditDeleteNote = 32,
    RightNotesManagementReadGroupNote = 33,
    RightNotesManagementCreateGroupNote = 34,
    RightNotesManagementEditGroupNote = 35,
    RightNotesManagementDeleteGroupNote = 36,
    RightNotesManagementReadOrganizationNote = 37,
    RightNotesManagementCreateOrganizationNote = 38,
    RightNotesManagementEditOrganizationNote = 39,
    RightNotesManagementDeleteOrganizationNote = 40,
    RightSettingsManagementReadSettingsUserGroup = 41,
    RightSettingsManagementCreateSettingsUserGroup = 42,
    RightSettingsManagementEditSettingsUserGroup = 43,
    RightSettingsManagementDeleteSettingsUserGroup = 44,
    RightUserManagementReadUser = 45,
    RightUserManagementCreateUser = 46,
    RightUserManagementEditUser = 47,
    RightUserManagementDeleteUser = 48,
    RightGroupManagementReadUserGroup = 49,
    RightGroupManagementCreateUserGroup = 50,
    RightGroupManagementEditUserGroup = 51,
    RightGroupManagementDeleteUserGroup = 52,
    RightUserManagementImportUsers = 53,
    RightAccessCategoryTypeManagement = 54,
    RightAccessCategoryManagement = 55,
    RightAccessRoleManagement = 56,
    RightAccessUserManagement = 57,
    RightSettingsManagementReadSettingsGlobal = 58,
    RightSettingsManagementEditSettingsGlobal = 59,
    RightSettingsManagementDeleteSettingsGlobal = 60,
    RightAccessGlobalSettingsManagement = 61,
    RightArticleManagementDeleteVersion = 62,
    RightArticleManagementReadLastModifiedBy = 63,
    RightGroupManagementManageUserGroupNotesFor = 64,
    RightGroupManagementManageGlobalNotesFor = 65
}
