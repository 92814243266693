import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsQuery,
  usePostApiUsersActivationEmailMutation,
  usePostApiUsersByIdResetPasswordMutation,
  User,
} from '../../../redux/store/api/api';
import {
  addSelectedUser,
  removeSelectedUser,
  selectSelectedUsers,
  selectMoveUserGroupStarted,
  selectMoveUserStarted,
  setContextUser,
  setDeleteUserDialogOpened,
  setEditUserDialogOpened,
  setMoveUserGroupStarted,
  setMoveUserStarted,
  clearSelectedUsers,
  setToggleLockoutUserDialogOpened,
} from '../../../redux/store/user-management/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import ContextMenu from '../../dropdown-menus/ContextMenu';
import { ContextAction } from '../../dropdown-menus/types';
import { IUserTreeItem } from '../types';
import '../UserManagement.scss';
import { RightKey } from '../../../shared/enums';
import DescriptiveIcon from '../../descriptive-icons/DescriptiveIcon';

interface IUserTreeElementProps {
  userTreeElement: IUserTreeItem;
  permittedActions: RightKey[];
}

function UserTreeElement({
  userTreeElement,
  permittedActions,
}: IUserTreeElementProps): JSX.Element {
  const { t: translation } = useTranslation();
  const moveUserStarted = useAppSelector(selectMoveUserStarted);
  const moveUserGroupStarted = useAppSelector(selectMoveUserGroupStarted);
  const currentSelectedUsers = useAppSelector(selectSelectedUsers);
  const dispatch = useAppDispatch();
  const [
    resetPassword,
    { isError: isResetPasswordError, error: resetPasswordError },
  ] = usePostApiUsersByIdResetPasswordMutation();
  const [
    sendActivationEmail,
    { isError: isSendActivationEmailError, error: sendActivationEmailError },
  ] = usePostApiUsersActivationEmailMutation();

  // permissions
  const userCanEditUser = permittedActions.includes(
    RightKey.RightUserManagementEditUser,
  );
  const userCanDeleteUser = permittedActions.includes(
    RightKey.RightUserManagementDeleteUser,
  );

  const { refetch } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });

  useEffect(() => {
    if (isResetPasswordError) {
      dispatch(
        addMessage({
          id: 'ResetPasswordError',
          variant: 'danger',
          messageKeyBody:
            resetPasswordError && 'data' in resetPasswordError
              ? resetPasswordError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (isSendActivationEmailError) {
      dispatch(
        addMessage({
          id: 'SendActivationMailError',
          variant: 'danger',
          messageKeyBody:
            sendActivationEmailError && 'data' in sendActivationEmailError
              ? sendActivationEmailError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [isResetPasswordError, isSendActivationEmailError]);

  const user: User = {
    id: userTreeElement.id,
    firstname: userTreeElement.firstname,
    lastname: userTreeElement.lastname,
    userGroupId: userTreeElement.parent,
    email: userTreeElement.email,
    emailConfirmed: userTreeElement.emailConfirmed,
    emailSentDate: userTreeElement.emailSentDate,
    disabled: userTreeElement.disabled,
    loggedIn: userTreeElement.loggedIn,
    timedOut: userTreeElement.timedOut,
    isSuperAdmin: userTreeElement.isSuperAdmin,
    isLockedOut: userTreeElement.isLockedOut,
  };

  const emailSendButNotConfirmed =
    !userTreeElement.emailConfirmed && userTreeElement.emailSentDate;

  const emailNotSend =
    !userTreeElement.emailConfirmed && !userTreeElement.emailSentDate;

  const { isLockedOut } = userTreeElement;

  const handleCheckUser = (isChecked: boolean) => {
    if (isChecked) {
      dispatch(addSelectedUser(user));
    } else {
      dispatch(removeSelectedUser(user));
    }
  };

  const getSelectedUsersForActivationEmail = (users: User[]): string[] =>
    users.filter((u) => !u.emailConfirmed).map((u) => u.id || '');

  const getContextActions = (): ContextAction[] => {
    const contextActions: ContextAction[] = [];
    const black = 'text-body';
    const red = 'text-danger';

    if (
      currentSelectedUsers.length > 0 &&
      !moveUserStarted &&
      !moveUserGroupStarted
    ) {
      if (userCanDeleteUser) {
        contextActions.push({
          iconClass: 'icon-trash',
          iconColorClass: red,
          name: translation('deleteSelectedUsers'),
          onClick: () => {
            dispatch(setDeleteUserDialogOpened(true));
          },
        });
      }

      if (
        userCanEditUser &&
        currentSelectedUsers.filter((u) => !u.emailConfirmed).length > 0
      ) {
        contextActions.push({
          helpId: 'help_6_11',
          iconClass: 'icon-reg_sent',
          iconColorClass: black,
          name: translation('sendActivationEmailToSelectedUsers'),
          onClick: () => {
            const userIds =
              getSelectedUsersForActivationEmail(currentSelectedUsers);
            dispatch(clearSelectedUsers());
            sendActivationEmail({ body: userIds })
              .unwrap()
              .then((result) => {
                if (result.messageKey && result.messageKey !== '') {
                  dispatch(
                    addMessage({
                      id: 'SendActivationMailSuccess',
                      variant: 'success',
                      messageKeyBody: result.messageKey,
                    }),
                  );
                }
                refetch();
              });
          },
        });
      }

      contextActions.push({
        iconClass: 'icon-move',
        iconColorClass: black,
        name: translation('startMoveSelectedUsers'),
        onClick: () => {
          dispatch(setMoveUserStarted(true));
        },
      });

      return contextActions;
    }

    if (userCanEditUser) {
      if (moveUserStarted) {
        contextActions.push({
          iconClass: 'icon-deaktivieren',
          iconColorClass: red,
          name: translation('stopMoveUser'),
          onClick: () => {
            dispatch(clearSelectedUsers());
            dispatch(setMoveUserStarted(false));
          },
        });

        return contextActions;
      }

      if (moveUserGroupStarted) {
        contextActions.push({
          iconClass: 'icon-deaktivieren',
          iconColorClass: red,
          name: translation('stopMoveUserGroup'),
          onClick: () => {
            setContextUser(user);
            dispatch(setMoveUserGroupStarted(false));
          },
        });

        return contextActions;
      }

      contextActions.push({
        helpId: 'help_6_4',
        iconClass: 'icon-edit',
        iconColorClass: black,
        name: translation('editUser'),
        onClick: () => {
          dispatch(setContextUser(user));
          dispatch(setEditUserDialogOpened(true));
        },
      });

      contextActions.push({
        helpId: 'help_6_5',
        iconClass: 'icon-move',
        iconColorClass: black,
        name: translation('startMoveUser'),
        onClick: () => {
          dispatch(clearSelectedUsers());
          dispatch(addSelectedUser(user));
          dispatch(setMoveUserStarted(true));
        },
      });
    }

    if (userCanDeleteUser) {
      contextActions.push({
        helpId: 'help_6_6',
        iconClass: 'icon-trash',
        iconColorClass: red,
        name: translation('deleteUser'),
        onClick: () => {
          dispatch(clearSelectedUsers());
          dispatch(addSelectedUser(user));
          dispatch(setDeleteUserDialogOpened(true));
        },
        addDividerAfterItem: userCanEditUser,
      });
    }

    if (userCanEditUser) {
      contextActions.push({
        helpId: 'help_6_12',
        iconClass: 'icon-password_reset',
        iconColorClass: black,
        name: translation('resetPassword'),
        onClick: () => {
          dispatch(setContextUser(user));
          resetPassword({ id: user.id || '' })
            .unwrap()
            .then((result) => {
              if (result.messageKey && result.messageKey !== '') {
                dispatch(
                  addMessage({
                    id: 'SendResetPasswordMailSuccess',
                    variant: 'success',
                    messageKeyBody: result.messageKey,
                  }),
                );
              }
            });
        },
      });

      if (!userTreeElement.emailConfirmed) {
        contextActions.push({
          helpId: 'help_6_11',
          iconClass: 'icon-reg_sent',
          iconColorClass: black,
          name: translation('sendActivationEmail'),
          onClick: () => {
            dispatch(setContextUser(user));
            sendActivationEmail({ body: [user.id || ''] })
              .unwrap()
              .then((result) => {
                if (result.messageKey && result.messageKey !== '') {
                  dispatch(
                    addMessage({
                      id: 'SendActivationMailSuccess',
                      variant: 'success',
                      messageKeyBody: result.messageKey,
                    }),
                  );
                }
                refetch();
              });
          },
        });
      }

      contextActions.push({
        helpId: 'help_6_7',
        iconClass: isLockedOut ? 'icon-entsperren' : 'icon-sperren',
        iconColorClass: black,
        name: isLockedOut
          ? translation('lockUserDisable')
          : translation('lockUserEnable'),
        onClick: () => {
          dispatch(setContextUser(user));
          dispatch(setToggleLockoutUserDialogOpened(true));
        },
      });
    }

    return contextActions;
  };

  return (
    <div className='user-data'>
      <div className='user-inner d-flex flex-row justify-content-start'>
        <span>
          <Form.Check
            type='checkbox'
            id={user.id || ''}
            label={
              <>
                {isLockedOut && (
                  <DescriptiveIcon
                    iconClass='icon-sperren'
                    colorClass='text-danger'
                    description={translation('lockedOut')}
                  />
                )}
                {`${userTreeElement.lastname}, ${userTreeElement.firstname}`}
              </>
            }
            checked={currentSelectedUsers.some((u) => u.id === user.id)}
            onChange={(e) => {
              handleCheckUser((e.target as HTMLInputElement).checked);
            }}
            onKeyDown={(e) => {
              if (e.code === 'Space') {
                handleCheckUser(!(e.target as HTMLInputElement).checked);
              }
            }}
          />
        </span>
      </div>
      <div className='user-inner'>
        {userTreeElement.emailConfirmed && (
          <span>
            <DescriptiveIcon
              iconClass='icon-reg_active align-middle'
              description={translation('emailConfirmed')}
            />
            {userTreeElement.email}
          </span>
        )}
        {emailSendButNotConfirmed && (
          <span>
            <DescriptiveIcon
              iconClass='icon-reg_sent align-middle'
              colorClass='text-warning'
              description={translation('emailSendButNotConfirmed')}
            />
            {userTreeElement.email}
          </span>
        )}
        {emailNotSend && (
          <span>
            <DescriptiveIcon
              iconClass='icon-reg align-middle'
              colorClass='text-danger'
              description={translation('emailNotSend')}
            />
            {userTreeElement.email}
          </span>
        )}
      </div>
      <ContextMenu contextActions={getContextActions()} />
    </div>
  );
}

export default UserTreeElement;
