import parse from 'html-react-parser';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Cards.scss';
import {
  CSSProperties,
  PropsWithChildren,
  SyntheticEvent,
  useState,
} from 'react';
import IconButton from '../buttons/IconButton';
import OnlineHelpButton from '../buttons/OnlineHelpButton';

interface IAction {
  name: string;
  iconClassName: string;
  underlineColorClass?: string;
  onClick: (e: SyntheticEvent) => void;
}

interface ICustomCardProps {
  cardId?: string;
  title?: string;
  subTitleContent?: React.ReactNode;
  iconClass?: string;
  iconColorClass?: string;
  iconColorHexCode?: string;
  actions?: IAction[];
  customCardStyle?: CSSProperties;
  expandable?: boolean;
  focusableHeadline?: boolean;
  headlineAsH1?: boolean;
  helpId?: string;
}

function CustomCard({
  cardId,
  children,
  title,
  subTitleContent,
  iconClass,
  iconColorClass,
  iconColorHexCode,
  actions,
  customCardStyle,
  expandable,
  focusableHeadline,
  headlineAsH1,
  helpId,
}: PropsWithChildren<ICustomCardProps>): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { t: translation } = useTranslation();

  const getCardTitleContent = () => (
    <>
      {iconClass && (
        <i
          aria-hidden
          className={`me-2 ${iconClass} ${iconColorClass || ''}`}
          style={iconColorHexCode ? { color: iconColorHexCode } : undefined}
        />
      )}
      <span className='mt-1'>{parse(title || '')}</span>
    </>
  );

  return (
    <Card style={customCardStyle} id={cardId}>
      {title && (
        <Card.Title>
          {headlineAsH1 ? (
            <h1
              className='d-flex'
              tabIndex={focusableHeadline ? -1 : undefined}>
              {getCardTitleContent()}
            </h1>
          ) : (
            <h2
              className='d-flex'
              tabIndex={focusableHeadline ? -1 : undefined}>
              {getCardTitleContent()}
            </h2>
          )}
          {subTitleContent}
          <div className='card-icons position-absolute'>
            {helpId && <OnlineHelpButton helpId={helpId} />}
            {actions?.map((action) => (
              <IconButton
                textColorClass='text-dark'
                key={action.iconClassName}
                title={action.name}
                iconClassName={`${action.iconClassName} ${
                  action.underlineColorClass || ''
                }`}
                ariaLabel={action.name}
                onClick={action.onClick}
              />
            ))}
            {expandable && (
              <IconButton
                textColorClass='text-dark'
                aria-expanded={expanded}
                title={
                  expanded ? translation('snapShut') : translation('expand')
                }
                iconClassName={`fs-4 ${
                  expanded ? 'icon-carretup' : 'icon-carretdown'
                }`}
                ariaLabel={
                  expanded ? translation('snapShut') : translation('expand')
                }
                onClick={
                  (expandable &&
                    (() => {
                      setExpanded(!expanded);
                    })) ||
                  undefined
                }
              />
            )}
          </div>
        </Card.Title>
      )}

      <Card.Body className={(!expanded && 'd-none') || undefined}>
        {children}
      </Card.Body>
    </Card>
  );
}

CustomCard.defaultProps = {
  cardId: null,
  customCardStyle: {},
  title: null,
  subTitleContent: null,
  iconClass: null,
  iconColorClass: null,
  iconColorHexCode: null,
  expandable: false,
  actions: [],
  focusableHeadline: false,
  headlineAsH1: false,
  helpId: null,
};

export default CustomCard;
