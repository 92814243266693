import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import {
  ArticleVersion,
  useGetApiCategoryTreeQuery,
  usePostApiArticlesVersionMutation,
  usePostApiArticlesVersionUploadMutation,
  Version,
} from '../../redux/store/api/api';
import { addMessage } from '../../redux/store/layout/slice';
import { getArticleUrl, getEditDraftUrl } from '../../shared/urlBuilder';
import { formatDateString } from '../../shared/utils';
import Loader from '../loader/Loader';
import VersionForm from '../version/VersionForm';
import CustomCard from '../cards/CustomCard';
import { IAddArticleVersionFileUpload } from './types';
import { ArticleType } from '../../shared/enums';
import { categoryIdUrlParam, pdfArticleUrlParam } from '../../shared/constants';
import MaxNumberOfVersionsDialog from '../version/dialogs/MaxNumberOfVersionsDialog';

function CreateArticleVersionForm(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const currentDate = new Date();
  const initialVersionTitle = `Neuer Artikel (${currentDate.toLocaleDateString(
    'de-DE',
  )} ${currentDate.toLocaleTimeString('de-DE')})`;
  const [versionContent, setVersionContent] = useState('');
  const [versionTitle, setVersionTitle] = useState(initialVersionTitle);
  const [validityDate, setValidityDate] = useState(
    formatDateString(currentDate.toString()),
  );
  const [publicationDateStart, setPublicationDateStart] = useState('');
  const [publicationDateEnd, setPublicationDateEnd] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [isValidTitle, setIsValidTitle] = useState<boolean>(true);
  const [isValidValidityDate, setIsValidValidityDate] = useState(true);
  const [isValidFile, setIsValidFile] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const categoryId: string = searchParams.get(categoryIdUrlParam) || '';
  const pdfArticle: boolean = searchParams.get(pdfArticleUrlParam) === 'true';
  const [
    addArticle,
    {
      isError: addArticleIsError,
      isLoading: addArticleIsLoading,
      error: addArticleError,
    },
  ] = usePostApiArticlesVersionMutation();
  const { refetch } = useGetApiCategoryTreeQuery();
  const [
    uploadArticle,
    {
      isError: uploadArticleIsError,
      isLoading: uploadArticleIsLoading,
      error: uploadArticleError,
    },
  ] = usePostApiArticlesVersionUploadMutation();
  const navigate = useNavigate();

  const resetInputs = () => {
    setVersionContent('');
    setVersionTitle(initialVersionTitle);
    setValidityDate(formatDateString(currentDate.toString()));
    setPublicationDateStart('');
    setPublicationDateEnd('');
    setFile(null);
    setIsValidFile(true);
    setIsValidTitle(true);
    setIsValidValidityDate(true);
  };

  const validateInputs = () => {
    let valid = true;

    if (versionTitle.trim() === '') {
      setIsValidTitle(false);
      valid = false;
    }
    if (pdfArticle && (!file || !isValidFile)) {
      setIsValidFile(false);
      valid = false;
    }
    if (validityDate.trim() === '') {
      setIsValidValidityDate(false);
      valid = false;
    }
    return valid;
  };

  useEffect(() => {
    if (categoryId) {
      resetInputs();
    }
  }, [categoryId]);

  useEffect(() => {
    if (uploadArticleIsError) {
      dispatch(
        addMessage({
          id: 'UploadError',
          messageKeyBody:
            uploadArticleError && 'data' in uploadArticleError
              ? uploadArticleError.data?.messageKey
              : 'unknownError',
          variant: 'danger',
        }),
      );
    }

    if (addArticleIsError) {
      dispatch(
        addMessage({
          id: 'AddArticleIsError',
          messageKeyBody:
            addArticleError && 'data' in addArticleError
              ? addArticleError.data?.messageKey
              : 'unknownError',
          variant: 'danger',
        }),
      );
    }
  }, [addArticleIsError, uploadArticleIsError]);

  const handleResponse = (
    articleVersion: ArticleVersion,
    draftVersion: boolean,
  ) => {
    refetch();
    if (draftVersion) {
      navigate(
        getEditDraftUrl(
          articleVersion.article?.id || '',
          articleVersion.version?.id || '',
        ),
        {
          replace: true,
        },
      );
    } else {
      navigate(
        getArticleUrl(
          articleVersion.article?.id || '',
          articleVersion.version?.id || undefined,
        ),
        {
          replace: true,
        },
      );
    }
  };

  const handleAddArticleAndVersion = (draftVersion: boolean) => {
    if (pdfArticle) {
      const formData = new FormData();
      formData.append('ArticlePdf', file as Blob);
      formData.append('CategoryId', categoryId);
      formData.append(
        'Type',
        pdfArticle ? ArticleType.Pdf.toString() : ArticleType.Html.toString(),
      );
      formData.append(
        'Version.Type',
        pdfArticle ? ArticleType.Pdf.toString() : ArticleType.Html.toString(),
      );
      formData.append('Version.Disabled', 'false');
      formData.append('Version.Draft', draftVersion.toString());
      formData.append('Version.Title', versionTitle.trim());
      formData.append('Version.ValidFrom', validityDate);
      formData.append('Version.PublishedFrom', publicationDateStart);
      formData.append('Version.PublishedUntil', publicationDateEnd);

      uploadArticle({
        body: formData as IAddArticleVersionFileUpload,
      })
        .unwrap()
        .then((articleVersion) =>
          handleResponse(articleVersion.resultObject || {}, draftVersion),
        );
    } else {
      const version: Version = {
        type: pdfArticle ? ArticleType.Pdf : ArticleType.Html,
        disabled: false,
        draft: draftVersion,
        title: versionTitle.trim(),
        validFrom: validityDate,
        publishedFrom:
          publicationDateStart === '' ? undefined : publicationDateStart,
        publishedUntil:
          publicationDateEnd === '' ? undefined : publicationDateEnd,
        htmlContent: versionContent,
      };

      addArticle({
        categoryId,
        version,
        type: pdfArticle ? ArticleType.Pdf : ArticleType.Html,
      })
        .unwrap()
        .then((articleVersion) =>
          handleResponse(articleVersion.resultObject || {}, draftVersion),
        );
    }
  };

  return (
    <>
      <CustomCard
        helpId='help_3_4'
        title={translation('createArticle')}
        headlineAsH1
        focusableHeadline>
        <div aria-busy={addArticleIsLoading || uploadArticleIsLoading}>
          {(addArticleIsLoading || uploadArticleIsLoading) && <Loader />}
          {!addArticleIsLoading && !uploadArticleIsLoading && (
            <>
              <VersionForm
                versionContent={versionContent}
                setVersionContent={setVersionContent}
                versionTitle={versionTitle}
                setVersionTitle={setVersionTitle}
                validityDate={validityDate}
                setValidityDate={setValidityDate}
                publicationStart={publicationDateStart}
                setPublicationStart={setPublicationDateStart}
                publicationEnd={publicationDateEnd}
                setPublicationEnd={setPublicationDateEnd}
                pdfArticle={pdfArticle}
                setFile={setFile}
                file={file || undefined}
                setIsValidFile={setIsValidFile}
                isValidFile={isValidFile}
                isValidTitle={isValidTitle}
                setIsValidTitle={setIsValidTitle}
                isValidValidityDate={isValidValidityDate}
                setIsValidValidityDate={setIsValidValidityDate}
              />
              <p>{translation('fieldsAreRequiredLegend')}</p>
              <div className='text-end'>
                <Link className='btn btn-outline-danger me-1 mt-1' to='/'>
                  {translation('discard')}
                </Link>
                {!pdfArticle && (
                  <Button
                    variant='outline-dark'
                    className='me-1 mt-1'
                    onClick={() => {
                      if (validateInputs()) {
                        handleAddArticleAndVersion(true);
                      }
                    }}>
                    {translation('saveAsDraft')}
                  </Button>
                )}
                <Button
                  variant='outline-success'
                  className='me-1 mt-1'
                  onClick={() => {
                    if (validateInputs()) {
                      handleAddArticleAndVersion(false);
                    }
                  }}>
                  {translation('saveVersion')}
                </Button>
              </div>
            </>
          )}
        </div>
      </CustomCard>
      <MaxNumberOfVersionsDialog />
    </>
  );
}

export default CreateArticleVersionForm;
