/*
 * Custom customer code to compare versions
 * Is based on adapted code from diff_match_patch version 1.0.3 (https://www.npmjs.com/package/diff-match-patch/v/1.0.3)
 * Adapted lib code is in public/lib and is called in index.html
 */

//
// GLOBALE VARIABLEN - NOTWENDIG
//

// maskierung für die ins und del tags
var ins_open = String.fromCharCode(9820);
var ins_close = String.fromCharCode(9821);
var del_open = String.fromCharCode(9822);
var del_close = String.fromCharCode(9823);
// markierungen für inlinetags
var inlt_br = String.fromCharCode(9824);
// spezialbehandlung einzelner buchstaben
var specialChars = [',', '.', '?', '!'];
var specialCharsOffset = 9826;
// spezialbehandlung einzelner elemente
var specialElementsRegEx = ['[0-9]+[a-z]?.[0-9]+']; // RZ + Zahl + optionaler Buchstabe + Punkt + Leerzeichen + Zahl  RZ 52a. 12    , '[0-9]+ €'
// letzter zustand
var ins_start = false;
var del_start = false;
// unicode offset für die maskierung der tags
var charOffset = 9830; // Supplementary Private Use Area-A ab code point U+F0000 = 983040 - benutzung von 98340 funktioniert aber nicht richtig; deswegen test mit 9830

//
//  HILFSFUNKTIONEN - NOTWENDIG
//

Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};

function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

//
//  COMPARE FUNKTION - NOTWENDIG
//

function changeNodesRecursive(x) {
  for (var i = 0; i < x.length; i++) {
    var y = x[i].childNodes;
    if (y.length > 1) {
      changeNodesRecursive(y);
    } else if (y.length > 0) {
      var s = x[i].innerHTML;
      var change = false;

      if (ins_start) {
        s = ins_open + s;
        change = true;
        ins_start = false;
      }
      if (del_start) {
        s = del_open + s;
        change = true;
        del_start = false;
      }

      var p_ins_open = s.indexOf(ins_open);
      var p_ins_close = s.indexOf(ins_close);
      var p_del_open = s.indexOf(del_open);
      var p_del_close = s.indexOf(del_close);

      if (p_ins_close > -1 && (p_ins_open == -1 || p_ins_open > p_ins_close)) {
        s = ins_open + s;
        change = true;
      }
      if (p_del_close > -1 && (p_del_open == -1 || p_del_open > p_del_close)) {
        s = del_open + s;
        change = true;
      }

      var p_ins_open = s.lastIndexOf(ins_open);
      var p_ins_close = s.lastIndexOf(ins_close);
      var p_del_open = s.lastIndexOf(del_open);
      var p_del_close = s.lastIndexOf(del_close);

      if (p_ins_open > -1 && (p_ins_close == -1 || p_ins_open > p_ins_close)) {
        s = s + ins_close;
        change = true;
        ins_start = true;
      }
      if (p_del_open > -1 && (p_del_close == -1 || p_del_open > p_del_close)) {
        s = s + del_close;
        change = true;
        del_start = true;
      }

      if (change == true) {
        x[i].innerHTML = s;
      }
    }
  }
}

export function compareTexts(text1, text2) {
  // reset
  ins_start = false;
  del_start = false;

  // nbsp entfernen
  text1 = text1.replace(/ /g, ' ');
  text2 = text2.replace(/ /g, ' ');

  // ids entfernen
  text1 = text1.replace(/ id="[a-zA-Z0-9_-]+"/g, '');
  text2 = text2.replace(/ id="[a-zA-Z0-9_-]+"/g, '');

  // tags separieren
  text1 = text1.replace(/>/g, '> ');
  text1 = text1.replace(/</g, ' <');
  text2 = text2.replace(/>/g, '> ');
  text2 = text2.replace(/</g, ' <');

  // zusammenhängende tags verschmelzen
  text1 = ' ' + text1.replace(/> +</g, '><') + ' ';
  text2 = ' ' + text2.replace(/> +</g, '><') + ' ';

  // inline tags ausnehmen
  text1 = text1.replace(/<br ?\/?>/g, inlt_br);
  text2 = text2.replace(/<br ?\/?>/g, inlt_br);

  // verbleibende tags aus beiden texten auslesen
  var tags = [];
  var tags1 = text1.match(/<[^>]+>/g);
  var tags2 = text2.match(/<[^>]+>/g);
  if (Array.isArray(tags1) && tags1.length > 0)
    var tags = tags.concat(tags1).unique();
  if (Array.isArray(tags2) && tags2.length > 0)
    var tags = tags.concat(tags2).unique();

  // original tags aus beiden texten durch eindeutige zeichen ersetzen
  var charOffsetTags = charOffset;
  for (var i = 0; i < tags.length; i++) {
    var c = String.fromCharCode(charOffsetTags + i);
    var regex = new RegExp(escapeRegExp(tags[i]), 'g');
    text1 = text1.replace(regex, c);
    text2 = text2.replace(regex, c);
  }

  // special elements ersetzen
  var specialElements = [];
  for (var i = 0; i < specialElementsRegEx.length; i++) {
    var regex = new RegExp(specialElementsRegEx[i], 'g');
    var specialElements1 = text1.match(regex);
    var specialElements2 = text2.match(regex);
    if (Array.isArray(specialElements1) && specialElements1.length > 0)
      var specialElements = specialElements.concat(specialElements1).unique();
    if (Array.isArray(specialElements2) && specialElements2.length > 0)
      var specialElements = specialElements.concat(specialElements2).unique();
  }
  var charOffsetSpecialElements = charOffsetTags + tags.length + 1;
  for (var i = 0; i < specialElements.length; i++) {
    var c = String.fromCharCode(charOffsetSpecialElements + i);
    var regex = new RegExp(escapeRegExp(specialElements[i]), 'g');
    text1 = text1.replace(regex, c);
    text2 = text2.replace(regex, c);
  }

  // special chars ersetzen
  for (var i = 0; i < specialChars.length; i++) {
    var c = String.fromCharCode(specialCharsOffset + i);
    var regex = new RegExp(
      '([a-zA-Z0-9])' + escapeRegExp(specialChars[i]),
      'g',
    );
    text1 = text1.replace(regex, '$1 ' + c + ' ');
    text2 = text2.replace(regex, '$1 ' + c + ' ');
  }

  // wörter und andere einheiten aus den beiden texten durch eindeutige zeichen ersetzen
  var charOffsetElements =
    charOffsetSpecialElements + specialElements.length + 1;
  var elements1 = text1.match(/[^ ]+/g);
  var elements2 = text2.match(/[^ ]+/g);
  var elements = [];
  if (Array.isArray(elements1) && elements1.length > 0)
    var elements = elements.concat(elements1).unique();
  if (Array.isArray(elements2) && elements2.length > 0)
    var elements = elements.concat(elements2).unique();

  for (var i = 0; i < elements.length; i++) {
    var regex = new RegExp(' ' + escapeRegExp(elements[i]) + ' ', 'g');
    var c = ' ' + String.fromCharCode(charOffsetElements + i) + ' ';
    text1 = text1.replace(regex, c);
    text2 = text2.replace(regex, c);
  }

  text1 = text1.replace(/ /g, '');
  text2 = text2.replace(/ /g, '');

  // diff erzeugen
  var dmp = new diff_match_patch();

  var diffs = dmp.diff_main(text1, text2, false);

  var s = dmp.diff_prettyHtml(diffs);

  // original elemente wiederherstellen
  for (var i = 0; i < elements.length; i++) {
    var c = String.fromCharCode(charOffsetElements + i);
    s = s.replace(RegExp(c, 'g'), ' ' + elements[i] + ' ');
  }

  // special elements wiederherstellen
  for (var i = 0; i < specialElements.length; i++) {
    var c = String.fromCharCode(charOffsetSpecialElements + i);
    s = s.replace(RegExp(c, 'g'), specialElements[i]);
  }

  // original tags wiederherstellen
  for (var i = 0; i < tags.length; i++) {
    var c = String.fromCharCode(charOffsetTags + i);
    s = s.replace(RegExp(c, 'g'), tags[i]);
  }

  // ins und del tags kodieren damit sie nicht im dom berücksichtigt werden
  s = s.replace(/<ins>/g, ins_open);
  s = s.replace(/<\/ins>/g, ins_close);
  s = s.replace(/<del>/g, del_open);
  s = s.replace(/<\/del>/g, del_close);

  // überflüssige spaces entfernen
  s = s.replace(/ *</g, '<');
  s = s.replace(/> */g, '>');
  s = s.replace(/  +/g, ' ');

  // special chars wiederherstellen
  for (var i = 0; i < specialChars.length; i++) {
    var c = String.fromCharCode(specialCharsOffset + i);
    s = s.replace(RegExp(' ' + c, 'g'), specialChars[i]);
  }

  // position vertauschen: <del><p>  -> <p><del> etc
  s = s.replace(RegExp(del_open + '(<[^>/]+>)', 'g'), '$1' + del_open);
  s = s.replace(RegExp(ins_open + '(<[^>/]+>)', 'g'), '$1' + ins_open);
  s = s.replace(RegExp('(</[^>]+>)' + del_close, 'g'), del_close + '$1');
  s = s.replace(RegExp('(</[^>]+>)' + ins_close, 'g'), ins_close + '$1');

  // als dom einlesen
  var dom = new DOMParser().parseFromString(s, 'text/html');

  // durch dom gehen und jedes einzelne element korrigieren
  var x = dom.documentElement.childNodes;
  changeNodesRecursive(x);

  // ins und del tags wieder herstellen + leere ins/del tags löschen
  s = dom.body.innerHTML;
  s = s.replace(RegExp(ins_open + ins_close, 'g'), '');
  s = s.replace(RegExp(del_open + del_close, 'g'), '');
  s = s.replace(RegExp(ins_open, 'g'), '<ins>');
  s = s.replace(RegExp(ins_close, 'g'), '</ins>');
  s = s.replace(RegExp(del_open, 'g'), '<del>');
  s = s.replace(RegExp(del_close, 'g'), '</del>');

  // schönheitskorrekturen für special chars
  for (var i = 0; i < specialChars.length; i++) {
    var escapedChar = escapeRegExp(specialChars[i]);
    s = s.replace(RegExp(' (</?ins>' + escapedChar + '[ <])', 'g'), '$1');
    s = s.replace(RegExp(' (</?del>' + escapedChar + '[ <])', 'g'), '$1');
    s = s.replace(RegExp('([a-zA-Z0-9]' + escapedChar + ') <', 'g'), '$1<');
  }

  // schönheitskorrekturen für ins/del
  s = s.replace(RegExp(' </del><ins> ', 'g'), '</del> <ins>');
  s = s.replace(RegExp('<ins> ', 'g'), ' <ins>');
  s = s.replace(RegExp('<del> ', 'g'), ' <del>');
  s = s.replace(RegExp(' </del> ', 'g'), '</del> ');
  s = s.replace(RegExp(' </ins> ', 'g'), '</ins> ');
  s = s.replace(RegExp('><ins> ', 'g'), '><ins>');
  s = s.replace(RegExp('><del> ', 'g'), '><del>');
  s = s.replace(RegExp(' </del><', 'g'), '</del><');
  s = s.replace(RegExp(' </ins><', 'g'), '</ins><');
  s = s.replace(RegExp(' +', 'g'), ' ');

  // schönheitskorrekturen für inline tags
  s = s.replace(RegExp('([a-zA-Z0-9äöüÄÖÜ])<span ', 'g'), '$1 <span ');
  var inlTags = ['strong', 'em', 'b', 'i', 'u', 'span'];
  for (var i = 0; i < inlTags.length; i++) {
    var t = inlTags[i];
    s = s.replace(
      RegExp('([a-zA-Z0-9äöüÄÖÜ])<' + t + '>', 'g'),
      '$1 <' + t + '>',
    );
    s = s.replace(
      RegExp('</' + t + '>([a-zA-Z0-9äöüÄÖÜ])', 'g'),
      '</' + t + '> $1',
    );
    s = s.replace(RegExp('><' + t + '> ', 'g'), '> <' + t + '>');
    s = s.replace(RegExp(' </' + t + '>', 'g'), '</' + t + '> ');
  }
  s = s.replace(RegExp(' +', 'g'), ' ');

  // inline tags wiederherstellen
  s = s.replace(RegExp(inlt_br, 'g'), '<br />');

  // ausgabe
  return s;
}
