import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  SettingContainer,
  SettingContainerType,
  useGetApiSettingsContainersUserGroupsQuery,
  usePostApiSettingsContainersUserGroupsMutation,
  useDeleteApiSettingsContainersUserGroupsByIdMutation,
  usePutApiSettingsContainersUserGroupsMutation,
  useGetApiSettingsUserQuery,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  clearSelectedUsers,
  selectContextUserGroup,
  selectUserGroupSettingsDialogOpened,
  setUserGroupSettingsDialogOpened,
} from '../../../redux/store/user-management/slice';
import { RightKey } from '../../../shared/enums';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import SettingContainerItem from '../user-group-settings/SettingContainerItem';
import CustomAccordion from '../../accordion/CustomAccordion';

function UserGroupSettingsDialog() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectUserGroupSettingsDialogOpened);
  const [selectedSettingContainerIndex, setSelectedSettingContainerIndex] =
    useState<number>(0);
  const [activeKey, setActiveKey] = useState('');
  const userGroup = useAppSelector(selectContextUserGroup);
  const {
    data: SettingContainerData,
    isFetching,
    isError,
    error,
    refetch: refetchUserGroupSettingsContainer,
  } = useGetApiSettingsContainersUserGroupsQuery(
    dialogShow && userGroup.id
      ? {
          userGroupId: userGroup.id,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );
  const { refetch: refetchUserSettings } = useGetApiSettingsUserQuery();

  const [
    addSettingsContainerToUserGroup,
    { isError: addSettingsIsError, error: addSettingsError },
  ] = usePostApiSettingsContainersUserGroupsMutation();

  const [
    deleteSettingsContainerToUserGroup,
    { isError: deleteSettingsIsError, error: deleteSettingsError },
  ] = useDeleteApiSettingsContainersUserGroupsByIdMutation();

  const [
    activateSettingsContainerToUserGroup,
    { isError: activateSettingsIsError, error: activateSettingsError },
  ] = usePutApiSettingsContainersUserGroupsMutation();

  const thereAreAllocatedContainers =
    SettingContainerData?.resultObject?.allocatedContainers &&
    SettingContainerData.resultObject.allocatedContainers.length > 0;

  // permission
  const userCanAddSetting =
    userGroup.permittedActions?.includes(
      RightKey.RightSettingsManagementCreateSettingsUserGroup,
    ) || false;
  const userCanDeleteSetting =
    userGroup.permittedActions?.includes(
      RightKey.RightSettingsManagementDeleteSettingsUserGroup,
    ) || false;
  const userCanEditSetting =
    userGroup.permittedActions?.includes(
      RightKey.RightSettingsManagementEditSettingsUserGroup,
    ) || false;

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          id: 'GetUserGroupSettingsError',
          variant: 'danger',
          messageKeyBody:
            error && 'data' in error ? error.data?.messageKey : 'unknownError',
        }),
      );
    }
    if (addSettingsIsError) {
      dispatch(
        addMessage({
          id: 'CreateSettingsError',
          variant: 'danger',
          messageKeyBody:
            addSettingsError && 'data' in addSettingsError
              ? addSettingsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (deleteSettingsIsError) {
      dispatch(
        addMessage({
          id: 'DeleteSettingsError',
          variant: 'danger',
          messageKeyBody:
            deleteSettingsError && 'data' in deleteSettingsError
              ? deleteSettingsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (activateSettingsIsError) {
      dispatch(
        addMessage({
          id: 'ActivateSettingsError',
          variant: 'danger',
          messageKeyBody:
            activateSettingsError && 'data' in activateSettingsError
              ? activateSettingsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [
    isError,
    addSettingsIsError,
    deleteSettingsIsError,
    activateSettingsIsError,
  ]);

  const handleAddSettingContainerToUserGroup = (
    settingContainerType: SettingContainerType,
  ) => {
    addSettingsContainerToUserGroup({
      userGroupId: userGroup.id || '',
      type: settingContainerType,
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UAddSEttingConmtainerSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        setActiveKey(result.resultObject?.id || '');
        refetchUserGroupSettingsContainer();
        setSelectedSettingContainerIndex(0);
      });
  };

  const handleDeleteContainerSetting = (settingContainer: SettingContainer) => {
    deleteSettingsContainerToUserGroup({ id: settingContainer.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'DeleteSettingContainerSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchUserGroupSettingsContainer();
        refetchUserSettings();
      });
  };

  const handleActivateContainerSetting = (
    settingContainer: SettingContainer,
  ) => {
    activateSettingsContainerToUserGroup({
      settingContainerId: settingContainer.id || '',
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'ActivateSettingContainerSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetchUserGroupSettingsContainer();
        refetchUserSettings();
      });
  };

  return (
    <CustomDialog
      wideDialog
      titleId='UserGroupSettingsDialog'
      dialogTitle={translation('userGroupSettings')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(clearSelectedUsers());
        dispatch(setUserGroupSettingsDialogOpened(false));
        setSelectedSettingContainerIndex(0);
      }}
      closeTitle={translation('close')}>
      <div aria-busy={isFetching}>
        {isFetching && <Loader />}
        {!isFetching && SettingContainerData?.resultObject && (
          <>
            {SettingContainerData.resultObject?.nonAllocatedContainers &&
              SettingContainerData.resultObject.nonAllocatedContainers.length >
                0 &&
              userCanAddSetting && (
                <Form.Group
                  className='mb-5'
                  controlId='SettingContainerForUserGroup'>
                  <Form.Label>
                    {translation('selectSettingContainer')}
                  </Form.Label>
                  <InputGroup className='mb-3'>
                    <Form.Select
                      required
                      value={selectedSettingContainerIndex}
                      onChange={(e) => {
                        setSelectedSettingContainerIndex(
                          Number(e.target.value),
                        );
                      }}>
                      {SettingContainerData.resultObject.nonAllocatedContainers.map(
                        (settingContainer, index) => (
                          <option key={settingContainer.id} value={index}>
                            {settingContainer.name}
                          </option>
                        ),
                      )}
                    </Form.Select>
                    <Button
                      variant='outline-success'
                      onClick={() => {
                        if (
                          SettingContainerData?.resultObject
                            ?.nonAllocatedContainers
                        ) {
                          handleAddSettingContainerToUserGroup(
                            SettingContainerData.resultObject
                              .nonAllocatedContainers[
                              selectedSettingContainerIndex
                            ].settingContainerType as SettingContainerType,
                          );
                        }
                      }}>
                      {translation('add')}
                    </Button>
                  </InputGroup>
                </Form.Group>
              )}
            {!thereAreAllocatedContainers && (
              <p>{translation('noSettingsForThisUserGroup')}</p>
            )}
            {thereAreAllocatedContainers && (
              <CustomAccordion activeKey={activeKey}>
                {SettingContainerData?.resultObject?.allocatedContainers?.map(
                  (settingContainer) => (
                    <SettingContainerItem
                      activeKey={activeKey}
                      setActiveKey={setActiveKey}
                      settingContainer={settingContainer}
                      activateFunction={handleActivateContainerSetting}
                      deleteFunction={handleDeleteContainerSetting}
                      refetchFunction={refetchUserGroupSettingsContainer}
                      key={settingContainer.id}
                      userCanDeleteSettings={userCanDeleteSetting}
                      userCanEditSettings={userCanEditSetting}
                    />
                  ),
                )}
              </CustomAccordion>
            )}
          </>
        )}
      </div>
    </CustomDialog>
  );
}

export default UserGroupSettingsDialog;
