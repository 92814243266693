import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  useGetApiUserGroupsQuery,
  usePutApiUserGroupsByIdMutation,
} from '../../../redux/store/api/api';
import { addMessage } from '../../../redux/store/layout/slice';
import {
  clearSelectedUsers,
  selectContextUserGroup,
  selectEditUserGroupDialogOpened,
  setEditUserGroupDialogOpened,
} from '../../../redux/store/user-management/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';
import UserGroupForm from '../UserGroupForm';

function EditUserGroupDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const userGroup = useAppSelector(selectContextUserGroup);
  const dispatch = useAppDispatch();
  const dialogShow = useAppSelector(selectEditUserGroupDialogOpened);
  const [userGroupName, setUserGroupName] = useState<string>(
    userGroup.name || '',
  );
  const [userGroupNameIsValid, setUserGroupNameIsValid] = useState(true);
  const [maxUsersInGroup, setMaxUsersInGroup] = useState<number>(
    userGroup.userGroupMemberMaximum || -1,
  );
  const [maxUsersInTreeOfGroup, setMaxUsersInTreeOfGroup] = useState<number>(
    userGroup.userGroupTreeMemberMaximum || -1,
  );
  const initialUnlimitedUsersInGroup = userGroup.userGroupMemberMaximum === -1;
  const [unlimitedUsersInGroup, setUnlimitedUsersInGroup] = useState<boolean>(
    initialUnlimitedUsersInGroup,
  );
  const initialUnlimitedUsersInTreeOfGroup =
    userGroup.userGroupTreeMemberMaximum === -1;
  const [unlimitedUsersInTreeOfGroup, setUnlimitedUsersInTreeOfGroup] =
    useState<boolean>(initialUnlimitedUsersInTreeOfGroup);
  const [
    updateUserGroup,
    { isError: putUserGroupIsError, error: putUserGroupError, isLoading },
  ] = usePutApiUserGroupsByIdMutation();
  const {
    refetch,
    isError: getUserGroupsIsError,
    error: getUserGroupsError,
  } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });

  useEffect(() => {
    if (dialogShow) {
      setUserGroupName(userGroup.name || '');
      setMaxUsersInGroup(userGroup.userGroupMemberMaximum || -1);
      setMaxUsersInTreeOfGroup(userGroup.userGroupTreeMemberMaximum || -1);
      setUnlimitedUsersInGroup(initialUnlimitedUsersInGroup);
      setUnlimitedUsersInTreeOfGroup(initialUnlimitedUsersInTreeOfGroup);
      setUserGroupNameIsValid(true);
    }
  }, [dialogShow]);

  useEffect(() => {
    if (getUserGroupsIsError) {
      dispatch(
        addMessage({
          id: 'GetUserGroupsError',
          variant: 'danger',
          messageKeyBody:
            getUserGroupsError && 'data' in getUserGroupsError
              ? getUserGroupsError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
    if (putUserGroupIsError) {
      dispatch(
        addMessage({
          id: 'UpdateUserGroupError',
          variant: 'danger',
          messageKeyBody:
            putUserGroupError && 'data' in putUserGroupError
              ? putUserGroupError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [getUserGroupsIsError, putUserGroupIsError]);

  const handleUpdateUserGroup = (): void => {
    if (userGroupName.trim().length === 0) {
      setUserGroupNameIsValid(false);
      return;
    }

    updateUserGroup({
      id: userGroup.id || '',
      userGroup: {
        id: userGroup.id,
        name: userGroupName.trim(),
        userGroupMemberMaximum: unlimitedUsersInGroup ? -1 : maxUsersInGroup,
        userGroupTreeMemberMaximum: unlimitedUsersInTreeOfGroup
          ? -1
          : maxUsersInTreeOfGroup,
        userGroupMemberCount: userGroup.userGroupMemberCount,
        userGroupTreeMemberCount: userGroup.userGroupTreeMemberCount,
        parentId: userGroup.parentId,
        userGroupIds: userGroup.userGroupIds,
        userIds: userGroup.userIds,
      },
    })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'UpdateUserGroupSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setEditUserGroupDialogOpened(false));
        dispatch(clearSelectedUsers());
      });
  };

  const userGroupMemberCheckboxMaximumNotChanged =
    unlimitedUsersInGroup === initialUnlimitedUsersInGroup;
  const userGroupTreeMemberCheckboxMaximumNotChanged =
    unlimitedUsersInTreeOfGroup === initialUnlimitedUsersInTreeOfGroup;
  const userGroupMemberInputNotChanged =
    maxUsersInGroup === userGroup.userGroupMemberMaximum;
  const userGroupTreeMemberInputNotChanged =
    maxUsersInTreeOfGroup === userGroup.userGroupTreeMemberMaximum;
  const userGroupNameNotChanged = userGroupName.trim() === userGroup.name;

  const inputsAreEmpty =
    (!unlimitedUsersInGroup && maxUsersInGroup < 1) ||
    (!unlimitedUsersInTreeOfGroup && maxUsersInTreeOfGroup < 1);

  const valuesNotChanged =
    userGroupNameNotChanged &&
    userGroupMemberInputNotChanged &&
    userGroupMemberCheckboxMaximumNotChanged &&
    userGroupTreeMemberInputNotChanged &&
    userGroupTreeMemberCheckboxMaximumNotChanged;

  return (
    <CustomDialog
      titleId='EditUserGroupDialog'
      actionButtonDisabled={inputsAreEmpty || valuesNotChanged || isLoading}
      dialogTitle={translation('editUserGroup')}
      show={dialogShow}
      closeFunction={() => {
        dispatch(setEditUserGroupDialogOpened(false));
      }}
      actionFunction={handleUpdateUserGroup}
      closeTitle={translation('cancel')}
      actionTitle={translation('save')}>
      {isLoading && <Loader />}
      {!isLoading && (
        <UserGroupForm
          userGroupName={userGroupName}
          maxUsersInGroup={maxUsersInGroup}
          maxUsersInTreeOfGroup={maxUsersInTreeOfGroup}
          unlimitedUsersInGroup={unlimitedUsersInGroup}
          unlimitedUsersInTreeOfGroup={unlimitedUsersInTreeOfGroup}
          setUserGroupName={setUserGroupName}
          setMaxUsersInGroup={setMaxUsersInGroup}
          setMaxUsersInTreeOfGroup={setMaxUsersInTreeOfGroup}
          setUnlimitedUsersInGroup={setUnlimitedUsersInGroup}
          setUnlimitedUsersInTreeOfGroup={setUnlimitedUsersInTreeOfGroup}
          userGroupNameIsValid={userGroupNameIsValid}
          setUserGroupNameIsValid={setUserGroupNameIsValid}
        />
      )}
    </CustomDialog>
  );
}

export default EditUserGroupDialog;
