import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectActiveVersion } from '../../redux/store/content/slice';
import { useFilesVersionByIdMutation } from '../../redux/store/api/fileApi';
import { addMessage } from '../../redux/store/layout/slice';
import Loader from '../loader/Loader';

function ArticlePdfView(): JSX.Element {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const version = useAppSelector(selectActiveVersion);
  const [versionPdfObjectUrl, setVersionPdfObjectUrl] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(0);
  const [getVersionFile, { isLoading: getVersionFileIsLoading }] =
    useFilesVersionByIdMutation();

  useEffect(() => {
    if (version.pdfFileId) {
      getVersionFile({ id: version.id || '' })
        .unwrap()
        .then((url) => {
          setVersionPdfObjectUrl(url);
        })
        .catch(() => {
          dispatch(
            addMessage({
              id: 'GetVersionError',
              messageKeyBody: 'unknownError',
              variant: 'danger',
            }),
          );
        });
    }
  }, [version]);

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setPageCount(pdf.numPages);
    const minWidthForPdf = 600;
    const maxWidthForPdf = 900;
    let containerWidth = document.getElementById('PdfArticleVersionContent')
      ?.clientWidth;
    if (!containerWidth || containerWidth > maxWidthForPdf) {
      containerWidth = maxWidthForPdf;
    }
    if (!containerWidth || containerWidth < minWidthForPdf) {
      containerWidth = minWidthForPdf;
    }
    setWidth(containerWidth);
  };

  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < pageCount) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  return (
    <>
      {getVersionFileIsLoading && versionPdfObjectUrl === '' && <Loader />}
      {!getVersionFileIsLoading && versionPdfObjectUrl !== '' && (
        <div
          id='PdfArticleVersionContent'
          className='pdf-article-content bg-light'>
          <div className='d-flex mb-2 mt-1 justify-content-center gap-3'>
            <Button
              aria-label={translation('previousPage')}
              disabled={pageNumber === 1}
              className='rounded-circle m-0'
              variant='outline-dark'
              onClick={goToPrevPage}>
              <ChevronLeft aria-hidden />
            </Button>
            <span className='mt-2 text-center'>
              {translation('pageOfPageCount', {
                pageNumber,
                pageCount,
              })}
            </span>
            <Button
              aria-label={translation('nextPage')}
              disabled={pageNumber === pageCount}
              className='rounded-circle m-0'
              variant='outline-dark'
              onClick={goToNextPage}>
              <ChevronRight aria-hidden />
            </Button>
          </div>
          <Document
            aria-hidden
            file={{ url: versionPdfObjectUrl }}
            onLoadSuccess={onDocumentLoadSuccess}
            error={translation('pdfError')}
            noData={translation('pdfError')}
            loading={<Loader />}>
            <Page
              renderMode='canvas'
              pageNumber={pageNumber}
              width={width}
              noData={translation('pdfError')}
              error={translation('pdfError')}
              loading={<Loader />}
            />
          </Document>
        </div>
      )}
    </>
  );
}

export default ArticlePdfView;
