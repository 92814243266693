import { useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectActiveVersion,
  selectNoteData,
  setExpandedNoteVisibilityGroup,
} from '../../redux/store/content/slice';
import { MARK_VISIBILITY_GROUP_KEY } from '../../shared/constants';
import { RightKey, NoteVisibilityType } from '../../shared/enums';
import { VisibilityGroupString } from '../notes/types';
import './Marks.scss';
import useGetCategoryByArticleId from '../../hooks/useGetCategoryByArticleId';

function MarksButtonGroup() {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeMarkVisibilityGroup, setActiveMarkVisibilityGroup] =
    useSessionStorage<VisibilityGroupString | null>(
      MARK_VISIBILITY_GROUP_KEY,
      null,
    );
  const noteData = useAppSelector(selectNoteData);
  const userMarkCount = noteData?.userNotes?.filter((n) => n.mark).length || 0;
  const userGroupMarkCount =
    noteData?.userGroupNotes?.filter((n) => n.mark).length || 0;
  const generalMarkCount =
    noteData?.generalNotes?.filter((n) => n.mark).length || 0;

  const version = useAppSelector(selectActiveVersion);
  const category = useGetCategoryByArticleId(version.articleId);
  // permissions
  const userHasAccessToUserNotes =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementCreateEditDeleteNote,
    ) || false;
  const userCanReadUserGroupNotes =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementReadGroupNote,
    ) || false;
  const userCanReadOrganizationNote =
    category?.permittedActions?.includes(
      RightKey.RightNotesManagementReadOrganizationNote,
    ) || false;

  const handleVisibilityGroupChange = (group: VisibilityGroupString): void => {
    if (group === activeMarkVisibilityGroup) {
      setActiveMarkVisibilityGroup(null);
      return;
    }
    setActiveMarkVisibilityGroup(group);
  };

  useEffect(() => {
    if (
      activeMarkVisibilityGroup === NoteVisibilityType[NoteVisibilityType.User]
    ) {
      dispatch(
        setExpandedNoteVisibilityGroup(NoteVisibilityType.User.toString()),
      );
    }
    if (
      activeMarkVisibilityGroup ===
      NoteVisibilityType[NoteVisibilityType.UserGroup]
    ) {
      dispatch(
        setExpandedNoteVisibilityGroup(NoteVisibilityType.UserGroup.toString()),
      );
    }
    if (
      activeMarkVisibilityGroup ===
      NoteVisibilityType[NoteVisibilityType.General]
    ) {
      dispatch(
        setExpandedNoteVisibilityGroup(NoteVisibilityType.General.toString()),
      );
    }
    setActiveMarkVisibilityGroup(activeMarkVisibilityGroup);
  }, [activeMarkVisibilityGroup]);

  return (
    <Card.Title className='justify-content-between align-items-center marks-button-group mb-4'>
      <h3>
        <i className='icon-pencil fs-5 me-2' aria-hidden />
        {translation('marks')}
      </h3>
      <Form>
        <Form.Group>
          {userHasAccessToUserNotes && (
            <Form.Check
              type='switch'
              inline
              checked={
                activeMarkVisibilityGroup ===
                (NoteVisibilityType[
                  NoteVisibilityType.User
                ] as VisibilityGroupString)
              }
              onChange={() =>
                handleVisibilityGroupChange(
                  NoteVisibilityType[
                    NoteVisibilityType.User
                  ] as VisibilityGroupString,
                )
              }
              label={
                <span className='user-mark-label'>{`${translation(
                  'visibilityGroupUser',
                )} (${userMarkCount})`}</span>
              }
              id='User'
              name='visibility'
            />
          )}
          {userCanReadUserGroupNotes && (
            <Form.Check
              type='switch'
              inline
              checked={
                activeMarkVisibilityGroup ===
                (NoteVisibilityType[
                  NoteVisibilityType.UserGroup
                ] as VisibilityGroupString)
              }
              onChange={() =>
                handleVisibilityGroupChange(
                  NoteVisibilityType[
                    NoteVisibilityType.UserGroup
                  ] as VisibilityGroupString,
                )
              }
              label={
                <span className='group-mark-label'>
                  {`${translation(
                    'visibilityGroupUserGroup',
                  )} (${userGroupMarkCount})`}
                </span>
              }
              id='Group'
              name='visibility'
            />
          )}
          {userCanReadOrganizationNote && (
            <Form.Check
              type='switch'
              inline
              checked={
                activeMarkVisibilityGroup ===
                (NoteVisibilityType[
                  NoteVisibilityType.General
                ] as VisibilityGroupString)
              }
              onChange={() =>
                handleVisibilityGroupChange(
                  NoteVisibilityType[
                    NoteVisibilityType.General
                  ] as VisibilityGroupString,
                )
              }
              label={
                <span className='global-mark-label'>
                  {`${translation(
                    'visibilityGroupGeneral',
                  )} (${generalMarkCount})`}
                </span>
              }
              id='General'
              name='visibility'
            />
          )}
        </Form.Group>
      </Form>
    </Card.Title>
  );
}

export default MarksButtonGroup;
