import { decode } from 'html-entities';
import parse from 'html-react-parser';
import { ChangeEvent, useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  SearchArticleResult as SearchArticleResultType,
  SearchArticleResultItem,
  useGetApiCategoryTreeQuery,
} from '../../redux/store/api/api';
import CustomListGroup from '../lists/CustomListGroup';
import { SearchResultSort } from '../../shared/enums';
import { getArticleUrl } from '../../shared/urlBuilder';

interface ISearchResultProps {
  searchResult: SearchArticleResultType;
  sortOption: SearchResultSort;
  onSortChange: (sort: SearchResultSort) => void;
}

function SearchArticleResult({
  searchResult,
  sortOption,
  onSortChange,
}: ISearchResultProps): JSX.Element {
  const { t: translation } = useTranslation();
  const handleOnSortChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) =>
      onSortChange(+e.target.value as SearchResultSort),
    [onSortChange],
  );
  const { data: contentTreeData } = useGetApiCategoryTreeQuery();

  function getCategory(articleId: string | undefined | null) {
    if (articleId) {
      return (
        contentTreeData?.resultObject?.categories?.find(
          (c) => c.articleIds && c.articleIds.includes(articleId),
        ) || undefined
      );
    }
    return undefined;
  }

  const getResultItem = (resultItem: SearchArticleResultItem): JSX.Element => (
    <>
      <div className='result-item-header'>
        <p className='text-break fw-semibold mb-0 d-flex'>
          <i
            className={`me-2 ${
              getCategory(resultItem.articleId)?.categoryTypeIconClass || ''
            }`}
            style={
              getCategory(resultItem.articleId)?.categoryTypeColorHexCode
                ? {
                    color:
                      getCategory(resultItem.articleId)
                        ?.categoryTypeColorHexCode || '',
                  }
                : undefined
            }
            aria-hidden
          />
          <span>{parse(decode(resultItem.versionTitle))}</span>
        </p>
        <span className='result-version text-muted'>
          {resultItem.versionName}
          <i aria-hidden className='ms-1 me-2 icon-artikel' />
          {resultItem.creationDate
            ? new Date(resultItem.creationDate).toLocaleDateString('de-DE')
            : ''}
        </span>
      </div>
      <div className='text-muted mb-2 fs-6 fst-italic'>
        ({decode(getCategory(resultItem.articleId)?.permittedPath || '')})
      </div>
      {resultItem.foundInAttachment && (
        <p>
          <i className='icon-attachments me-1' aria-hidden />
          {`${translation('searchKeywordIsInAttachment')} ${
            resultItem.attachmentName
          }`}
        </p>
      )}
      <p className='text-break result-extract'>
        {parse(decode(resultItem.extract))}
      </p>
    </>
  );

  return (
    <>
      <Form.Group as={Row} className='mb-3 search-result-sorting'>
        <Col sm='5'>
          <Form.Label>{translation('sortBy')}</Form.Label>
        </Col>
        <Col>
          <Form.Select value={sortOption} onChange={handleOnSortChange}>
            <option value={SearchResultSort.ByRelevance}>
              {translation('relevance')}
            </option>
            <option value={SearchResultSort.ByDate}>
              {translation('date')}
            </option>
          </Form.Select>
        </Col>
      </Form.Group>

      {searchResult.items && (
        <CustomListGroup
          listItems={searchResult.items.map((i, index) => ({
            id: index.toString(),
            href: getArticleUrl(
              i.articleId || '',
              i.versionId || undefined,
              undefined,
              undefined,
              searchResult.foundTerms ? searchResult.foundTerms : undefined,
            ),
            content: getResultItem(i),
          }))}
        />
      )}
    </>
  );
}

export default SearchArticleResult;
