import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  usePutApiUsersLockoutToggleMutation,
  useGetApiUserGroupsQuery,
} from '../../../redux/store/api/api';
import {
  selectContextUser,
  setToggleLockoutUserDialogOpened,
  selectToggleLockoutUserDialogOpened,
} from '../../../redux/store/user-management/slice';
import { addMessage } from '../../../redux/store/layout/slice';
import CustomDialog from '../../dialogs/CustomDialog';
import Loader from '../../loader/Loader';

function ToggleLockoutUserDialog(): JSX.Element {
  const { t: translation } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectContextUser);
  const dialogShow = useAppSelector(selectToggleLockoutUserDialogOpened);
  const [
    toggleLockout,
    {
      isError: toggleLockoutUsersIsError,
      error: toggleLockoutUsersError,
      isLoading,
    },
  ] = usePutApiUsersLockoutToggleMutation();
  const { refetch } = useGetApiUserGroupsQuery({
    includeUsers: true,
    includePermittedActions: true,
  });

  const { isLockedOut } = user;

  useEffect(() => {
    if (toggleLockoutUsersIsError) {
      dispatch(
        addMessage({
          id: 'ToggleLockoutUsersError',
          variant: 'danger',
          messageKeyBody:
            toggleLockoutUsersError && 'data' in toggleLockoutUsersError
              ? toggleLockoutUsersError.data?.messageKey
              : 'unknownError',
        }),
      );
    }
  }, [toggleLockoutUsersIsError]);

  const handleToggleLockoutUser = (): void => {
    toggleLockout({ userId: user.id || '' })
      .unwrap()
      .then((result) => {
        if (result.messageKey && result.messageKey !== '') {
          dispatch(
            addMessage({
              id: 'ToggleLockoutSuccess',
              variant: 'success',
              messageKeyBody: result.messageKey,
            }),
          );
        }
        refetch();
        dispatch(setToggleLockoutUserDialogOpened(false));
      });
  };

  return (
    <CustomDialog
      titleId='ToggleLockoutUserDialog'
      dialogTitle={
        isLockedOut
          ? translation('lockUserDisable')
          : translation('lockUserEnable')
      }
      show={dialogShow}
      closeFunction={() => {
        dispatch(setToggleLockoutUserDialogOpened(false));
      }}
      actionFunction={handleToggleLockoutUser}
      closeTitle={translation('cancel')}
      actionTitle={isLockedOut ? translation('unlock') : translation('lockOut')}
      actionButtonVariant={isLockedOut ? undefined : 'outline-danger'}
      actionButtonDisabled={isLoading}>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <p>
            {isLockedOut
              ? translation('shouldBeEnabledUser')
              : translation('shouldBeLockedUser')}
          </p>
          <p className='fw-bold'>{`${user.lastname}, ${user.firstname}`}</p>
        </>
      )}
    </CustomDialog>
  );
}

export default ToggleLockoutUserDialog;
